import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './_css/cookieBanner.module.css';
import clsx from 'clsx';
import { UiButton } from 'common/ui/mui/buttons/UiButton';

const LOCAL_STORAGE_KEY = 'cookieBanner.closed';

function hasBeenClosed() {
    try {
        if (localStorage.getItem(LOCAL_STORAGE_KEY) === 'true') {
            return true;
        }
    } catch (err) {
    }
    return false;
}

export function CookieBanner() {
    const { t } = useTranslation();
    const [shown, setShown] = useState(!hasBeenClosed());
    if (!shown) return null;

    return (
        <div className={clsx(styles.container, 'flex_row_center flexWrap_wrap')}>
            <div className="flex-1">
                {t('cookieWarning')}
            </div>
            <UiButton
                className='ml_20 mr_70'
                onClick={() => {
                    setShown(false);
                    try {
                        localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
                    } catch (err) {
                    }
                }}
                variant='contained'
                color='primary'
            >
                {t('words.close')}
            </UiButton>
        </div>
    );
}
