import React, { useState } from "react";
import { Input } from "common/ui/forms/Input";
import { userStore } from "users/_stores/userStore";
import clsx from "clsx";
import styles from "./_css/searchUserScenarios.module.css";
import { scenariosStore } from "scenarios/_stores/scenariosStore";
import { observer } from "mobx-react";

export const SearchUserScenarios = observer(() => {
    const [openResult, setOpenResult] = useState(false);
    return (
        <div className={"position_relative mr_50"}>
            <Input
                onChange={(e) => {
                    userStore.setSearch(e.target.value);
                    setOpenResult(e.target.value.length > 0);
                    if (e.target.value.length === 0) {
                        scenariosStore.setUserToFilter("");
                    }
                }}
                className={styles.input}
                placeholder={"Nom ou prénom"}
            />
            {userStore.searchResult.length > 0 && openResult && (
                <div className={clsx("position_absolute", styles.results)}>
                    {userStore.searchResult.map((user, i) => {
                        return (
                            <div
                                onClick={() => {
                                    userStore.resetSearch();
                                    scenariosStore.setUserToFilter(user._id);
                                }}
                                className={clsx("p_5", styles.result)}
                                key={user._id + i}
                            >
                                {user.firstName + " " + user.lastName + " / " + user.email}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
});
