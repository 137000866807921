import { TEventMdl, TEventUserIdMdl } from "scenarios/_models/EventMdl";
import { Dayjs } from "dayjs";
import { guid } from "common/_utils/coreUtils";

export type TBasePeriodMdl = {
    _id: string;
    title: string;
    startDate?: Dayjs;
    endDate?: Dayjs;
    hiddenBookmarks: string[];
    about?: string;
    mark?: number;
    sumUp?: string;
};

export type TPeriodUserIdMdl = TBasePeriodMdl & {
    events: TEventUserIdMdl[];
};

export type TPeriodMdl = TBasePeriodMdl & {
    events: TEventMdl[];
};

export const defaultPeriod = () => {
    return {
        _id: guid(),
        title: "Nouveau chapitre",
        events: [],
        sumUp: "",
        hiddenBookmarks: [],
        mark: 0,
    };
};
