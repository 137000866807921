import { fetchUtils } from "common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { tokenStore } from "users/_stores/tokenStore";
import { action, observable } from "mobx";
import cron from "cron";
import { s4 } from "common/_utils/coreUtils";
import { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import { userStore } from "users/_stores/userStore";

class AuthStore {
    @observable statusInvitationSignUp: boolean;

    constructor() {
        this.statusInvitationSignUp = false;
    }

    signIn(email: string, password: string) {
        return fetchUtils
            .post<{ token: string }>(sharedConfig.apiUrl + "/auth/signIn", { email, password })
            .then(({ data }) => tokenStore.setToken(data.token));
    }

    signUp(email: string, password: string, firstName: string, lastName: string, isGuest?: string) {
        return fetchUtils
            .post<{ token: string }>(sharedConfig.apiUrl + "/auth/signUp", {
                email,
                password,
                firstName,
                lastName,
                isGuest,
            })
            .then(({ data }) => tokenStore.setToken(data.token));
    }

    changePassword(password: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/" + userStore.user + "/password", {
            password,
        });
    }
    askResetPassword(email: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/askResetPassword", { email });
    }

    resetPassword(token: string, password: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/resetPassword", { token, password });
    }

    signOut() {
        tokenStore.setToken(undefined);
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }

    signUpGuest() {
        this.cron.start();
        return this.signUp(`guest@${s4()}.com`, "guestGuest", "guest", "guest");
    }

    @action
    setStatusInvitationSignUp(bool: boolean) {
        this.statusInvitationSignUp = bool;
    }

    private cron = new cron.CronJob({
        cronTime: "*/2 * * * *",
        onTick: () => {
            this.setStatusInvitationSignUp(true);
        },
    });

    stopCron() {
        this.cron.stop();
    }

    googleAuth(user: Partial<GoogleLoginResponse> | Partial<GoogleLoginResponseOffline>) {
        if ("profileObj" in user) {
            const userData = {
                email: user.profileObj?.email,
                firstName: user.profileObj?.givenName,
                lastName: user.profileObj?.familyName,
            };
            return fetchUtils
                .post<{ token: string }>(sharedConfig.apiUrl + "/auth/googleAuth", userData)
                .then(({ data }) => tokenStore.setToken(data.token));
        }
        return undefined;
    }
}

const authStore = new AuthStore();
export { authStore };
