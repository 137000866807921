import { action, computed, IObservableArray, observable } from "mobx";
import { LoadingStateMdl } from "common/loaders/_models/LoadingStateMdl";
import { fetchUtils } from "common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { userStore } from "users/_stores/userStore";
import { defaultScenario, TScenarioMdl } from "scenarios/_models/ScenarioMdl";
import dayjs from "dayjs";
import _ from "lodash";
import { createFilesData } from "common/_utils/fileUtils";
import { TCollaboratorMdl } from "scenarios/_models/CollaboratorMdl";
import { socketStore } from "scenarios/socket/_stores/socketStore";
import { TEventMdl } from "scenarios/_models/EventMdl";
import { TFunction } from "i18next";

const API_URL = sharedConfig.apiUrl + "/scenarios";

export class ScenariosStore {
    @observable sort: { order: "asc" | "desc"; by: "name" | "createdAt" } = {
        by: "createdAt",
        order: "desc",
    };

    @observable scenarios: IObservableArray<TScenarioMdl> = observable.array([]);
    @observable userToFilter = "";

    searchValue = "";
    private readonly _fetchLoadingState = new LoadingStateMdl<TScenarioMdl[]>();
    private readonly _fetchScenariosStates: { [scenarioId: string]: LoadingStateMdl<TScenarioMdl> } = {};

    public static getScenarioName(scenario: TScenarioMdl) {
        return scenario?.title ?? `${scenario.firstName} ${scenario?.lastName}`;
    }

    private static _reformatScenario(scenarioJson: TScenarioMdl): TScenarioMdl {
        return {
            ...scenarioJson,
            birthday: dayjs.utc(scenarioJson.birthday),
            periods: scenarioJson.periods?.map((period) => ({
                ...period,
                events: period.events.map((event) => ({
                    ...event,
                    date: event.date ? dayjs.utc(event.date) : undefined,
                })),
                startDate: dayjs.utc(period.startDate),
                endDate: dayjs.utc(period.endDate),
            })),
            updatedAt: dayjs(scenarioJson.updatedAt),
            createdAt: dayjs(scenarioJson.createdAt),
        };
    }

    private static _reformatBeforeSave(scenario: Partial<TScenarioMdl>) {
        const reformattedScenario = JSON.parse(JSON.stringify(scenario));
        for (const period of reformattedScenario.periods) {
            for (const event of period.events) {
                event.user = event.user?._id;
            }
        }
        return reformattedScenario;
    }

    fetchScenarios() {
        if (
            this._fetchLoadingState.status !== "LOADING" &&
            !this._fetchLoadingState.error &&
            this._fetchLoadingState.status !== "SUCCEEDED"
        ) {
            this._fetchLoadingState.startLoading();
            fetchUtils
                .get<TScenarioMdl[]>(sharedConfig.apiUrl + "/users/" + userStore.user?._id + "/scenarios")
                .then(({ data }) => {
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    const scenarios = data.map(ScenariosStore._reformatScenario);
                    this.scenarios.replace(scenarios);
                    this._fetchLoadingState.setSuccess(this.scenarios);
                }, this._fetchLoadingState.setError);
        }
        return this._fetchLoadingState;
    }

    fetchScenario(scenarioId: string) {
        if (!this._fetchScenariosStates[scenarioId]) {
            this._fetchScenariosStates[scenarioId] = new LoadingStateMdl<TScenarioMdl>();
        }
        const loadingState = this._fetchScenariosStates[scenarioId];
        if (loadingState.status !== "LOADING") {
            loadingState.startLoading();
            fetchUtils.get<TScenarioMdl>(API_URL + "/" + scenarioId).then(({ data }) => {
                if (Object.keys(data).length > 0) {
                    loadingState.setSuccess(ScenariosStore._reformatScenario(data));
                    socketStore.openScenario(ScenariosStore._reformatScenario(data)._id);
                } else loadingState.setError({ key: "scenario.does.not.exist" });
            }, loadingState.setError);
        }

        return loadingState;
    }

    fetchScenarioWithToken(scenarioToken: string) {
        if (!this._fetchScenariosStates[scenarioToken]) {
            this._fetchScenariosStates[scenarioToken] = new LoadingStateMdl<TScenarioMdl>();
        }
        const loadingState = this._fetchScenariosStates[scenarioToken];
        if (loadingState.status === "IDLE") {
            loadingState.startLoading();
            fetchUtils.get<TScenarioMdl>(API_URL + "/view/" + scenarioToken).then(({ data }) => {
                const scenario = ScenariosStore._reformatScenario(data);
                loadingState.setSuccess(scenario);
                return scenario;
            }, loadingState.setError);
        }
        return loadingState;
    }

    fetchScenarioUpdates(scenarioId: string) {
        return fetchUtils.get<TScenarioMdl | undefined>(API_URL + "/" + scenarioId).then(({ data }) => {
            if (data && !_.isEmpty(data)) {
                const updatedScenario = ScenariosStore._reformatScenario(data);
                this.scenarios.splice(
                    this.scenarios.findIndex((scenario) => scenario._id === updatedScenario._id),
                    1,
                    ScenariosStore._reformatScenario(data),
                );
                return updatedScenario;
            }
        });
    }

    create(t: TFunction, scenario?: Partial<TScenarioMdl>) {
        if (!userStore.isLogged) {
            return new Promise<TScenarioMdl>((_resolve, reject) => {
                reject();
            });
        }
        if (!userStore.user) {
            return new Promise<TScenarioMdl>((_resolve, reject) => {
                reject();
            });
        }
        return fetchUtils
            .post<TScenarioMdl>(API_URL, scenario ?? defaultScenario(userStore.user, t))
            .then(({ data }) => {
                const scenario = ScenariosStore._reformatScenario(data);
                this._handleScenarioUpdated(scenario);
                return scenario;
            });
    }

    async save(scenario: Partial<TScenarioMdl>, event?: TEventMdl, replace = true) {
        if (scenario.periods) scenario = ScenariosStore._reformatBeforeSave(scenario);
        const files = await createFilesData(scenario.coverImg, "coverImg");
        const body = files
            ? fetchUtils.createBodyWithFiles(event ? { scenario: scenario, event: event } : scenario, files)
            : event
            ? { scenario: scenario, event: event }
            : scenario;
        return fetchUtils.patch<TScenarioMdl>(API_URL + "/" + scenario._id, body, !!files).then(({ data }) => {
            const scenario = ScenariosStore._reformatScenario(data);
            this._handleScenarioUpdated(scenario, replace);
            return scenario;
        });
    }

    async delete(scenario: Partial<TScenarioMdl>) {
        return fetchUtils
            .patch<TScenarioMdl>(API_URL + "/" + scenario._id + "/delete", {
                ...scenario,
                isDeleted: true,
            })
            .then(
                action(({ data }) => {
                    const scenario = ScenariosStore._reformatScenario(data);
                    const scenarioIndex = this.scenarios.findIndex((_scenario) => _scenario._id === scenario._id);
                    this.scenarios.splice(scenarioIndex, 1);
                    return true;
                }),
            );
    }

    getAllCollaborators() {
        const collaborators: TCollaboratorMdl[] = [];
        this.scenarios.map((scenario) => {
            scenario.collaborators.map((collaborator) => {
                collaborators.push(collaborator);
            });
        });
        return collaborators;
    }

    getCollaboratorFromAllOwnCollaborators(emails: string) {
        const allCollaborators = this.getAllCollaborators();

        const tmp: TCollaboratorMdl[] = [];
        allCollaborators.map((collaborator) => {
            if (
                collaborator.email.includes(emails.split(",")[emails.split(",").length - 1]) &&
                tmp.filter((collab) => collab.email.toLowerCase() === collaborator.email.toLowerCase()).length === 0
            ) {
                tmp.push(collaborator);
            }
        });

        return tmp;
    }

    editCollaborator(scenario: Partial<TScenarioMdl>, collaborator: TCollaboratorMdl) {
        if (!scenario.collaborators) return null;
        const indexOfCollaborator = scenario.collaborators.findIndex(
            (_collaborator) => _collaborator.email.toLowerCase() === collaborator.email.toLowerCase(),
        );
        scenario.collaborators.splice(indexOfCollaborator, 1, collaborator);
        this.save({ _id: scenario._id, collaborators: scenario.collaborators });
    }

    @action deleteCollaborator(scenario: Partial<TScenarioMdl>, email: string) {
        const scenarioCopy = JSON.parse(JSON.stringify(scenario));
        if (!scenario.collaborators) return null;
        const indexOfCollaborator = scenarioCopy.collaborators.findIndex(
            (_collaborator: TCollaboratorMdl) => _collaborator.email.toLowerCase() === email.toLowerCase(),
        );
        scenarioCopy.collaborators.splice(indexOfCollaborator, 1);
        this.save({ _id: scenarioCopy._id, collaborators: scenarioCopy.collaborators }, undefined);
    }

    addCollaborators(scenario: Partial<TScenarioMdl>, collaboratorEmails: TCollaboratorMdl[], invitationText: string) {
        if (!scenario.collaborators) scenario.collaborators = [];
        collaboratorEmails.map((collaborator) => {
            if (
                !scenario?.collaborators?.find(
                    (currentCollaborator) =>
                        currentCollaborator.email.toLowerCase() === collaborator.email.toLowerCase(),
                )
            ) {
                scenario?.collaborators?.push({ ...collaborator, lastEdit: dayjs() });
            }
        });
        const body = {
            collaborators: scenario.collaborators,
            text: invitationText,
        };

        return fetchUtils
            .patch<TScenarioMdl>(API_URL + "/addCollaborators/" + scenario._id, body)
            .then(() => {})
            .catch((e) => console.error(e));
    }

    changeOwner(scenario: Partial<TScenarioMdl>, email: string) {
        return fetchUtils
            .patch<TScenarioMdl>(API_URL + "/changeOwner/" + scenario._id, {
                email,
            })
            .then(
                action(({ data }) => {
                    const scenario = ScenariosStore._reformatScenario(data);
                    const scenarioIndex = this.scenarios.findIndex((_scenario) => _scenario._id === scenario._id);
                    this.scenarios.splice(scenarioIndex, 1);
                    return true;
                }),
            )
            .catch((e) => e);
    }

    @action
    private _handleScenarioUpdated(scenario: TScenarioMdl, replace?: boolean) {
        const scenarioIndex = this.scenarios.findIndex((_scenario) => _scenario._id === scenario._id);
        if (scenarioIndex === -1) {
            this.scenarios.unshift(scenario);
        } else {
            replace ? this.scenarios.splice(scenarioIndex, 1, scenario) : this.scenarios.splice(scenarioIndex, 1);
        }
    }

    @action
    setUserToFilter(userId: string) {
        this.userToFilter = userId;
    }

    @action
    setSort(payload: { by?: "name" | "createdAt"; order?: "asc" | "desc" }) {
        this.sort = { by: payload.by ?? this.sort.by, order: payload.order ?? this.sort.order };
    }

    @computed get sortedScenarios() {
        return this.scenarios
            .filter((scenario) => (this.userToFilter ? scenario.user === this.userToFilter : true))
            .sort((a, b) => {
                if (this.sort.by === "createdAt") {
                    if (this.sort.order === "asc") {
                        return dayjs(a.updatedAt).isAfter(b.updatedAt) ? 1 : -1;
                    } else {
                        return dayjs(b.updatedAt).isAfter(a.updatedAt) ? 1 : -1;
                    }
                } else {
                    if (this.sort.order === "asc") {
                        return (a.title ?? a.firstName).localeCompare(b.title ?? b.firstName);
                    } else {
                        return (b.title ?? b.firstName).localeCompare(a.title ?? a.firstName);
                    }
                }
            });
    }
}

const scenariosStore = new ScenariosStore();
export { scenariosStore };
