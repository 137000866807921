import { action, computed, observable } from "mobx";
import { fetchUtils } from "common/_utils/fetchUtils";
import { LoadingStateMdl } from "common/loaders/_models/LoadingStateMdl";
import sharedConfig from "_configs/sharedConfig";
import { IFolderMdl } from "folders/_models/FolderMdl";
import { IImageMdl } from "images/_models/ImageMdl";

class FoldersStore {
    @observable selectedFolderId: string | undefined;
    @observable folders: IFolderMdl[] = observable.array([]);
    @observable foldersState = new LoadingStateMdl("IDLE");
    @observable draggedImage: IImageMdl | undefined;
    private apiPath = `${sharedConfig.apiUrl}/folders`;

    @computed get currentFolders() {
        return this.folders
            .filter((folder) => folder.parentFolderId === this.selectedFolderId)
            .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    }

    @computed get selectedFolder() {
        return this.folders.find((folder) => folder._id === this.selectedFolderId);
    }

    fetchFoldersByScenarioId(scenarioId: string) {
        this.foldersState.startLoading();
        fetchUtils.get<IFolderMdl[]>(`${this.apiPath}/scenario/${scenarioId}`).then(({ data }) => {
            this.folders = data;
            this.folders.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
            this.foldersState.setSuccess();
        });
        return this.foldersState;
    }

    async createFolder(newFolder: Partial<IFolderMdl>) {
        return fetchUtils.post<IFolderMdl>(this.apiPath, newFolder).then(({ data }) => {
            this.folders.push(data);
            return data;
        });
    }

    @action setSelectedFolder(folderId?: string) {
        this.selectedFolderId = folderId;
    }

    @action deleteFolder(folderId: string) {
        const nestedFolders = this.findNestedFolders(folderId);
        if (!nestedFolders) return this._deleteFolder(folderId);
        return Promise.all([
            ...nestedFolders.map((folder) => this._deleteFolder(folder._id)),
            this._deleteFolder(folderId),
        ]);
    }

    @action updateFolder(folderId: string, itemPatch: Partial<IFolderMdl>) {
        return fetchUtils.patch<IFolderMdl>(this.apiPath + "/" + folderId, itemPatch).then(({ data }) => {
            const folderIdx = this.folders.findIndex((folder) => folder._id === folderId);
            const folderPatch = { ...this.folders[folderIdx], ...itemPatch };
            this.folders.splice(folderIdx, 1, folderPatch);
            return data;
        });
    }

    @action setDraggedImage(image?: IImageMdl) {
        this.draggedImage = image;
    }

    getNameParentFolder() {
        const _folder = this.selectedFolder;
        const parentFolder = this.folders.find((folder) => folder._id === _folder?.parentFolderId);
        if (parentFolder && parentFolder.name) return parentFolder.name;
        return "Galerie";
    }

    findNestedFolders(folderId: string): IFolderMdl[] | undefined {
        const nestedFolders = this._findNestedFolders(folderId);
        if (nestedFolders) {
            return nestedFolders.reduce((acc, folder) => {
                const nestedFolders = this.findNestedFolders(folder._id);
                if (nestedFolders) {
                    return [...acc, folder, ...nestedFolders];
                }
                return [...acc, folder];
            }, [] as IFolderMdl[]);
        }
    }

    private _findNestedFolders(folderId: string): IFolderMdl[] | undefined {
        const folder = this.folders.find((folder) => folder._id === folderId);
        if (folder) {
            return this.folders.filter((folder) => folder.parentFolderId === folderId);
        }
    }

    private _deleteFolder(folderId: string) {
        return fetchUtils.delete<IFolderMdl>(this.apiPath + "/delete/" + folderId).then(({ data }) => {
            const folderIdx = this.folders.findIndex((folder) => folder._id === folderId);
            this.folders.splice(folderIdx, 1);
            return data;
        });
    }
}

export const foldersStore = new FoldersStore();
