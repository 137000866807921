import React, { PropsWithChildren } from "react";
import { TPropsValues } from "scenarios/_models/EventMdl";
import { SECTION_LIBRARY, sectionStore } from "scenarios/sections/_stores/sectionStore";
import { WrapperEditorComponent } from "scenarios/events/WrapperEditorComponent";
import { observer } from "mobx-react";

type Props = PropsWithChildren<TPropsValues>;

export const WrapperComponent = observer((props: Props) => {
    const section = props.component;
    const event = props.event;
    const Component = SECTION_LIBRARY[section.id].component;
    const EditorComponent = SECTION_LIBRARY[section.id].editor;

    return (
        <div className={"mt_15 avoidBreak"} key={section.props?.id}>
            {props.isEditable ? (
                <WrapperEditorComponent eventId={event.id} {...section.props}>
                    {sectionStore.editingSection?.props?.id === section.props.id ? (
                        <EditorComponent isEditable={props.isEditable} componentId={section.id} {...section.props} />
                    ) : (
                        <Component
                            onEdit={() => {
                                sectionStore.setEditingSection(section);
                            }}
                            componentId={section.id}
                            isEditable={props.isEditable}
                            {...section.props}
                        />
                    )}
                </WrapperEditorComponent>
            ) : (
                <Component {...section.props} />
            )}
        </div>
    );
});
