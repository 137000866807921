import sharedConfig from "_configs/sharedConfig";

export const bookmarkIcons = [
    "calendar",
    "dream",
    "family",
    "friends",
    "happy",
    "health",
    "home",
    "highlight",
    "love",
    "medals",
    "milestone",
    "question",
    "regrets",
    "religion",
    "sad",
    "sport",
    "text",
    "travel",
    "work",

    "i5955378",
    "i5955394",
    "i5955410",
    "i5955426",
    "i5955442",
    "i5955379",
    "i5955395",
    "i5955411",
    "i5955427",
    "i5955443",
    "i5955380",
    "i5955396",
    "i5955412",
    "i5955428",
    "i5955444",
    "i5955381",
    "i5955397",
    "i5955413",
    "i5955429",
    "i5955445",
    "i5955382",
    "i5955398",
    "i5955414",
    "i5955430",
    "i5955446",
    "i5955383",
    "i5955399",
    "i5955415",
    "i5955431",
    "i5955447",
    "i5955384",
    "i5955400",
    "i5955416",
    "i5955432",
    "i5955448",
    "i5955385",
    "i5955401",
    "i5955417",
    "i5955433",
    "i5955449",
    "i5955386",
    "i5955402",
    "i5955418",
    "i5955434",
    "i5955450",
    "i5955387",
    "i5955403",
    "i5955419",
    "i5955435",
    "i5955451",
    "i5955388",
    "i5955404",
    "i5955420",
    "i5955436",
    "i5955452",
    "i5955389",
    "i5955405",
    "i5955421",
    "i5955437",
    "i5955453",
    "i5955390",
    "i5955406",
    "i5955422",
    "i5955438",
    "i5955454",
    "i5955391",
    "i5955407",
    "i5955423",
    "i5955439",
    "i5955455",
    "i5955392",
    "i5955408",
    "i5955424",
    "i5955440",
    "i5955456",
    "i5955393",
    "i5955409",
    "i5955425",
    "i5955441",

    "i4213868",
    "i4213872",
    "i4213876",
    "i4213880",
    "i4213869",
    "i4213873",
    "i4213877",
    "i4213881",
    "i4213870",
    "i4213874",
    "i4213878",
    "i4213882",
    "i4213871",
    "i4213875",
    "i4213879",
    "i4213883",

    "3405104",
    "3405108",
    "3405112",
    "3405116",
    "3405120",
    "3405105",
    "3405109",
    "3405113",
    "3405117",
    "3405121",
    "3405106",
    "3405110",
    "3405114",
    "3405118",
    "3405122",
    "3405107",
    "3405111",
    "3405115",
    "3405119",
    "3405123",

    "3672862",
    "3672869",
    "3672876",
    "3672883",
    "3672890",
    "3672897",
    "3672863",
    "3672870",
    "3672877",
    "3672884",
    "3672891",
    "3672898",
    "3672864",
    "3672871",
    "3672878",
    "3672885",
    "3672892",
    "3672899",
    "3672865",
    "3672872",
    "3672879",
    "3672886",
    "3672893",
    "3672900",
    "3672866",
    "3672873",
    "3672880",
    "3672887",
    "3672894",
    "3672901",
    "3672867",
    "3672874",
    "3672881",
    "3672888",
    "3672895",
    "3672868",
    "3672875",
    "3672882",
    "3672889",
    "3672896",
];

export const BOOKMARK_ICON_BASE_URL = sharedConfig.uploadsBaseUrl + "/assets/img/icons/";
