import React from "react";
import { MenuItem, NoSsr } from "@material-ui/core";
import { PageContainer } from "common/ui/pages/PageContainer";
import styles from "./_css/scenariosPage.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { LoadableFromLoading } from "common/loaders/LoadableFromLoading";
import { scenariosStore } from "scenarios/_stores/scenariosStore";
import { ScenarioList } from "scenarios/sections/ScenarioList";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";
import { toast } from "react-toastify";
import { SignUpInvitationDialog } from "users/auth/dialog/SignUpInvitationDialog";
import { userStore } from "users/_stores/userStore";
import { SearchUserScenarios } from "scenarios/SearchUserScenarios";
import { UiSelect } from "common/ui/mui/forms/UiSelect";

export function ScenariosPage() {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <PageContainer>
            <NoSsr>
                <div className={styles.container}>
                    <div className={clsx(styles.content, "flex_row_center alignItems_flexEnd")}>
                        <div className={"flex-1"}>
                            <h2>{t("scenariosPage.title")}</h2>
                        </div>
                        {userStore.isAdmin() && (
                            <div>
                                <SearchUserScenarios />
                            </div>
                        )}
                        <div>
                            <UiButton
                                variant={"contained"}
                                color={"primary"}
                                onClick={() => {
                                    scenariosStore
                                        .create(t)
                                        .then((scenario) => history.push(URLS.scenarios.scenario(scenario._id)))
                                        .catch(() =>
                                            toast.error(t("scenarioPage.error", { position: "bottom-center" })),
                                        );
                                }}
                            >
                                {t("scenariosPage.startNewStory")}
                            </UiButton>
                        </div>
                    </div>
                    <div className={"flex_row_center mt_20 gap_5"}>
                        <div>{t("words.sortBy")}</div>
                        <UiSelect
                            onChange={(e) => {
                                scenariosStore.setSort({ by: e.target.value as "createdAt" | "name" });
                            }}
                            defaultValue={scenariosStore.sort.by}
                        >
                            <MenuItem style={{ fontSize: "20px" }} value={"createdAt"}>
                                Date
                            </MenuItem>
                            <MenuItem style={{ fontSize: "20px" }} value={"name"}>
                                Name
                            </MenuItem>
                        </UiSelect>
                        <UiSelect
                            onChange={(e) => {
                                scenariosStore.setSort({ order: e.target.value as "asc" | "desc" });
                            }}
                            defaultValue={scenariosStore.sort.order}
                        >
                            <MenuItem style={{ fontSize: "20px" }} value={"asc"}>
                                Asc
                            </MenuItem>
                            <MenuItem style={{ fontSize: "20px" }} value={"desc"}>
                                Desc
                            </MenuItem>
                        </UiSelect>
                    </div>
                    <div className={clsx("flex_row flexWrap_wrap mt_50", styles.cardsContainer)}>
                        <LoadableFromLoading
                            loading={scenariosStore.fetchScenarios()}
                            renderer={() => <ScenarioList />}
                        />
                    </div>
                </div>
                {userStore.isGuest && <SignUpInvitationDialog />}
            </NoSsr>
        </PageContainer>
    );
}
