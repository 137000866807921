import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { VIDEO_TYPE } from "scenarios/sections/VideoSection";
import vimeoGif from "../../../assets/img/videoShareVimeo.gif";
import youtubeGif from "../../../assets/img/videoShareYoutube.gif";
import { useTranslation } from "react-i18next";

type Props = {
    open: boolean;
    onClose: () => void;
    typeVideoSelected: VIDEO_TYPE | undefined;
};

export function DialogHelpVideo(props: Props) {
    const { t } = useTranslation();
    if (!props.typeVideoSelected) return null;
    return (
        <Dialog onClose={props.onClose} open={props.open} maxWidth={"xl"}>
            <DialogTitle>{t("words.help")}</DialogTitle>
            <DialogContent>
                {props.typeVideoSelected === VIDEO_TYPE.VIMEO ? (
                    <img src={vimeoGif} alt="vimeoGif" />
                ) : (
                    <img src={youtubeGif} alt="youtubeGif" />
                )}
            </DialogContent>
        </Dialog>
    );
}
