import React from "react";
import { TEventMdl } from "scenarios/_models/EventMdl";
import styles from "./_css/eventCard.module.css";
import clsx from "clsx";
import { SECTION_TYPE } from "scenarios/sections/_stores/sectionStore";
import imagesIcon from "../../../shared/assets/icons/imagesIcon.svg";
import dayjs from "dayjs";
import { eventsStore } from "scenarios/_stores/eventsStore";
import { userStore } from "users/_stores/userStore";
import { observer } from "mobx-react";
import { LockOutlined, OndemandVideoOutlined } from "@material-ui/icons";
import { TEXT_SIZES } from "scenarios/_models/ScenarioMdl";

type Props = {
    event: TEventMdl;
};

export const EventCard = observer((props: Props) => {
    const sectionsWithImages =
        props.event.sections?.filter(
            (section) => section.id !== SECTION_TYPE.TEXT && section.id !== SECTION_TYPE.VIDEO,
        ) ?? [];

    const sectionsWithVideos = props.event.sections?.filter((section) => section.id === SECTION_TYPE.VIDEO) ?? [];
    let imagesBn = 0;
    let disable = false;
    sectionsWithImages.forEach((section) => {
        switch (section.id) {
            case SECTION_TYPE.GALLERY:
                imagesBn += section.props?.images.length ?? 0;
                break;
            case SECTION_TYPE.IMAGE:
            case SECTION_TYPE.TEXT_IMAGE:
                imagesBn++;
                break;
        }
    });

    if (
        eventsStore.editingEvents &&
        eventsStore.editingEvents.find((item) => item.eventId === props.event.id) &&
        eventsStore.editingEvents.find((item) => item.eventId === props.event.id)?.userId != userStore.user?._id
    ) {
        disable = true;
    }
    return (
        <div
            className={clsx(styles.container, "p_20 flex-1", {
                [styles.containerNotReview]: !props.event.published,
                [styles.containerPrivate]: props.event.private,
                [styles.disable]: disable,
            })}
        >
            {props.event.private && <LockOutlined className={styles.lockIcon} />}
            <div className={clsx("flex_row_center", styles.sectionsContentCounts)}>
                {!!sectionsWithImages.length && (
                    <div className={clsx("flex_row_center mb_5", styles.sectionsWithImages)}>
                        <span className={"mr_3"}>{imagesBn}</span>
                        <img
                            width={16}
                            alt={"photos icon"}
                            src={imagesIcon}
                            className={clsx(styles.imagesIcon, "mb_2")}
                        />
                    </div>
                )}
                {!!sectionsWithVideos.length && (
                    <div className={clsx("flex_row_center mb_5", styles.sectionsWithVideos)}>
                        <span className={"mr_3"}>{sectionsWithVideos.length}</span>
                        <OndemandVideoOutlined className={clsx("mb_2", styles.videosIcon)} />{" "}
                    </div>
                )}
            </div>
            {props.event.date && (
                <div className={clsx(styles.date, "flex_row_center mt_5")}>
                    <div className={clsx(styles.dot, "mr_5")} />
                    {dayjs(props.event.date).format("DD/MM/YYYY")}
                </div>
            )}

            <div>
                {props.event.title && <div className={clsx(styles.title, "mt_5")}>{props.event.title}</div>}
                {props.event.text && (
                    <div
                        className={clsx("mt_5", {
                            scenario_text_small: props.event.textSize === TEXT_SIZES.SMALL,
                            scenario_text_medium: props.event.textSize === TEXT_SIZES.MEDIUM || !props.event.textSize,
                            scenario_text_large: props.event.textSize === TEXT_SIZES.LARGE,
                        })}
                    >
                        {props.event.text}
                    </div>
                )}
            </div>
        </div>
    );
});
