import { Dayjs } from "dayjs";
import { guid } from "common/_utils/coreUtils";
import { SECTION_TYPE } from "scenarios/sections/_stores/sectionStore";
import { userStore } from "users/_stores/userStore";
import { TUserMdl } from "users/_models/UserMdl";
import { TEXT_SIZES } from "scenarios/_models/ScenarioMdl";

export type TPropsValues = { [key: string]: any | undefined; id?: string };

export type TComponent = {
    id: SECTION_TYPE;
    props?: TPropsValues;
};

export type TComponentTemporaryDeleted = TComponent & {
    index: number;
};

type TBaseEventMdl = {
    id: string;
    bookmarks: string[];
    text: string;
    textSize?: TEXT_SIZES;
    title: string;
    date?: Dayjs;
    sections: TComponent[];
    published: boolean;
    private: boolean;
    __version: number;
};

export type TEventUserIdMdl = TBaseEventMdl & {
    user: string;
};

export type TEventMdl = TBaseEventMdl & {
    user: Partial<TUserMdl>;
};

export const defaultEvent = () => {
    return {
        id: guid(),
        bookmarks: [],
        title: "",
        text: "",
        textSize: TEXT_SIZES.MEDIUM,
        sections: [],
        user: userStore.user,
        published: true,
        private: false,
        __version: 0,
    };
};
