import React, { useState } from "react";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { UiPaper } from "common/ui/mui/surfaces/UiPaper";
import { useTranslation } from "react-i18next";
import { UserForm } from "users/UserForm";
import { Container } from "@material-ui/core";
import styles from "./_css/userPage.module.css";
import { DeleteAccountDialog } from "users/auth/dialog/DeleteAccountDialog";
import { authStore } from "users/auth/_stores/authStore";
import { URLS } from "_configs/URLS";
import clsx from "clsx";
import { useHistory } from "react-router";

export function UserPage() {
    const [isEditing, setIsEditing] = useState(false);
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const history = useHistory();
    return (
        <Container className={styles.container}>
            <div className="mt_25">
                <UiPaper className={clsx("p_25", styles.paper)} color={"white"} elevation={0}>
                    <div className={styles.content}>
                        <div className="flex_row_center mb_20">
                            <h3 className={clsx("flex-1", styles.title)}>{t("profil.info")}</h3>
                            <div className={styles.contentBtn}>
                                <UiButton
                                    variant={"outlined"}
                                    color={"primary"}
                                    className={clsx("mr_20", styles.logout)}
                                    onClick={() => {
                                        authStore.signOut();
                                        history.push(URLS.home());
                                    }}
                                    size={"medium"}
                                >
                                    {t("header.logout")}
                                </UiButton>
                                <UiButton
                                    style={isEditing ? { visibility: "hidden" } : undefined}
                                    onClick={() => !isEditing && setIsEditing(!isEditing)}
                                    variant={"outlined"}
                                    color={"primary"}
                                >
                                    {t("words.edit")}
                                </UiButton>
                            </div>
                        </div>
                        <UserForm isEditing={isEditing} onStopEditing={() => setIsEditing(false)} />
                        <div className={"flex_row justifyContent_flexEnd"}>
                            <UiButton onClick={() => setOpen(true)} variant={"contained"} className={styles.btnDelete}>
                                Supprimer votre compte
                            </UiButton>
                        </div>
                        <DeleteAccountDialog open={open} setOpen={setOpen} />
                    </div>
                </UiPaper>
            </div>
        </Container>
    );
}
