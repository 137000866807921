import React, { useState } from "react";
import { TScenarioMdl } from "scenarios/_models/ScenarioMdl";
import styles from "./_css/scenarionCard.module.css";
import clsx from "clsx";
import dayjs from "dayjs";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";
import { InviteCollaboratorDialog } from "scenarios/collaborators/InviteCollaboratorDialog";
import { Dialog } from "common/ui/dialogs/Dialog";
import { ScenarioContext } from "scenarios/ScenarioContext";
import { ScenarioStore } from "scenarios/_stores/ScenarioStore";
import { scenariosStore } from "scenarios/_stores/scenariosStore";
import { toast } from "react-toastify";
import { ConfirmationDialog } from "common/ui/dialogs/ConfirmationDialog";
import { ScenarioCardAdmin } from "scenarios/ScenarioCardAdmin";
import { LegacyOptionDialog } from "scenarios/legacy/LegacyOptionDialog";

type Props = {
    scenario: TScenarioMdl;
};

export function ScenarioCard(props: Props) {
    const [openLegacy, setOpenLegacy] = useState(false);
    const [openInvitation, setOpenInvitation] = useState(false);
    const [openValidation, setOpenValidation] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const isOwner = props.scenario.user === userStore.user?._id;

    return (
        <ScenarioContext scenarioStore={new ScenarioStore(props.scenario)}>
            <div className={clsx("p_20", styles.container)}>
                {userStore.isAdmin() && <ScenarioCardAdmin scenario={props.scenario} />}
                <div className={clsx(styles.content, "flex_column")}>
                    <h4 className={styles.title}>
                        {props.scenario.title ?? props.scenario.firstName + " " + props.scenario.lastName}
                    </h4>
                    <div className={clsx("mt_10 text_normal color_secondary", styles.updatedAt)}>
                        {t("scenarioCard.lastEdited")} {dayjs(props.scenario.updatedAt).format("DD/MM/YYYY")}
                    </div>
                    <div className={"flex-1"} />
                    <div className={clsx(styles.buttons, "flex_row_center mt_30")}>
                        <UiButton
                            onClick={() => history.push(URLS.scenarios.draft(props.scenario._id))}
                            className={clsx("flex-1 mr_10", styles.previewBtn)}
                            variant={"outlined"}
                            color={"primary"}
                        >
                            {t("scenarioCard.preview")}
                        </UiButton>
                        <UiButton
                            onClick={() => history.push(URLS.scenarios.scenario(props.scenario._id))}
                            className="flex-1"
                            variant={"contained"}
                            color={"primary"}
                        >
                            {t("scenarioCard.edit")}
                        </UiButton>
                    </div>
                    {isOwner && !userStore.isGuest() && (
                        <div>
                            <div className={styles.separator} />
                            <div className={"flex_center_center mt_20"}>
                                <UiButton onClick={() => setOpenInvitation(true)} variant={"text"} color={"primary"}>
                                    {t("scenarioCard.inviteCollaborator")}
                                </UiButton>
                            </div>
                            <div className={"flex_center_center "}>
                                <UiButton onClick={() => setOpenLegacy(true)} variant={"text"} color={"primary"}>
                                    {t("scenarioCard.legacyOption")}
                                </UiButton>
                            </div>
                            <div className={"flex_center_center "}>
                                <UiButton
                                    onClick={() => setOpenValidation(true)}
                                    variant={"text"}
                                    className={styles.btnDelete}
                                >
                                    {t("scenarioCard.deleteHistory")}
                                </UiButton>
                            </div>
                            <Dialog maxWidth={"sm"} fullWidth open={openLegacy} onClose={() => setOpenLegacy(false)}>
                                <LegacyOptionDialog onClose={() => setOpenLegacy(false)} />
                            </Dialog>
                            <Dialog
                                maxWidth={"sm"}
                                fullWidth
                                open={openInvitation}
                                onClose={() => setOpenInvitation(false)}
                            >
                                <InviteCollaboratorDialog
                                    collaborators={props.scenario.collaborators}
                                    onClose={() => setOpenInvitation(false)}
                                />
                            </Dialog>
                            <ConfirmationDialog
                                open={openValidation}
                                title={t("deleteHistory.title")}
                                message={t("deleteHistory.text")}
                                agreeLabel={t("deleteHistory.confirm")}
                                onAgree={() => {
                                    scenariosStore
                                        .delete(props.scenario)
                                        .then(() =>
                                            toast.info(t("scenarioCard.deleteHistoryDone"), {
                                                position: "bottom-center",
                                            }),
                                        )
                                        .catch(() =>
                                            toast.error(t("scenarioPage.error"), { position: "bottom-center" }),
                                        );
                                }}
                                disagreeLabel={t("words.cancel")}
                                onDisagree={() => setOpenValidation(false)}
                                isDeleteButton={true}
                            />
                        </div>
                    )}
                    {!isOwner && (
                        <div className={"flex_center_center mt_20"}>
                            <UiButton
                                onClick={() => setOpenValidation(true)}
                                variant={"text"}
                                className={styles.btnDelete}
                            >
                                {t("scenarioCard.leaveCollaboration")}
                            </UiButton>
                            <ConfirmationDialog
                                open={openValidation}
                                title={t("leaveHistory.title")}
                                message={t("leaveHistory.text")}
                                agreeLabel={t("leaveHistory.confirm")}
                                onAgree={() => {
                                    if (userStore.user?.email) {
                                        scenariosStore.deleteCollaborator(props.scenario, userStore.user?.email);
                                        setOpenValidation(false);
                                    }
                                }}
                                disagreeLabel={t("words.cancel")}
                                onDisagree={() => setOpenValidation(false)}
                                isDeleteButton={true}
                            />
                        </div>
                    )}
                </div>
            </div>
        </ScenarioContext>
    );
}
