import React, { PropsWithChildren, useEffect, useState } from "react";
import { TPropsValues } from "scenarios/_models/EventMdl";
import styles from "./_css/wrapperEditorComponent.module.css";
import clsx from "clsx";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { sectionStore } from "scenarios/sections/_stores/sectionStore";
import { ConfirmationDialog } from "common/ui/dialogs/ConfirmationDialog";
import { useTranslation } from "react-i18next";

type Props = PropsWithChildren<TPropsValues>;

export function WrapperEditorComponent(props: Props) {
    const [openActions, setOpenActions] = useState(false);
    const scenarioStore = useScenarioStore();
    const currentSection = props;
    const [openConfirm, setOpenConfirm] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setOpenActions(sectionStore.editingSection?.props?.id === currentSection.id);
    }, [sectionStore.editingSection]);

    if (!currentSection.id) return null;

    const { event, section } = scenarioStore.getSection(currentSection.id);
    if (!section || !event) return null;

    const currentSectionPosition = event.sections.indexOf(section);
    return (
        <div
            onMouseEnter={() => setOpenActions(true)}
            onMouseLeave={() => {
                if (sectionStore.editingSection?.props?.id !== currentSection.id) setOpenActions(false);
            }}
            className={clsx("position_relative", {
                [styles.containerHover]: openActions,
            })}
        >
            {openActions && (
                <div className={clsx("position_absolute flex_row_center p_5", styles.actions)}>
                    {currentSectionPosition > 0 && (
                        <IconButton
                            onClick={() =>
                                section.props?.id ? scenarioStore.moveSection(section.props.id, "up") : null
                            }
                        >
                            <ArrowUpwardIcon htmlColor={"white"} />
                        </IconButton>
                    )}
                    {currentSectionPosition < event.sections.length - 1 && (
                        <IconButton
                            onClick={() =>
                                section.props?.id ? scenarioStore.moveSection(section.props.id, "down") : null
                            }
                        >
                            <ArrowDownwardIcon htmlColor={"white"} />
                        </IconButton>
                    )}
                    {/*<IconButton className={styles.settings} onClick={() => scenarioStore.setSidebar(true)}>*/}
                    {/*    <SettingsIcon htmlColor={"white"} />*/}
                    {/*</IconButton>*/}
                    <IconButton onClick={() => setOpenConfirm(true)}>
                        <DeleteIcon htmlColor={"white"} />
                    </IconButton>
                </div>
            )}
            {props.children}

            <ConfirmationDialog
                title={t("deleteBloc.title")}
                open={openConfirm}
                message={t("deleteBloc.text")}
                agreeLabel={t("deleteBloc.confirm")}
                onAgree={() => (section.props?.id ? scenarioStore.deleteSection(section.props.id) : null)}
                disagreeLabel={t("deleteBloc.cancel")}
                onDisagree={() => setOpenConfirm(false)}
            />
        </div>
    );
}
