import React from "react";
import { SECTION_TYPES_ARRAY, sectionStore } from "scenarios/sections/_stores/sectionStore";
import { useScenarioStore } from "scenarios/ScenarioContext";
import styles from "./_css/sectionPicker.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { guid } from "common/_utils/coreUtils";

type Props = {
    eventId: string;
    onClose: () => void;
};

export function SectionPicker(props: Props) {
    const scenarioStore = useScenarioStore();
    const { t } = useTranslation();
    return (
        <div className={clsx("flex-1 flex_center_center flexWrap_wrap", styles.container)}>
            {SECTION_TYPES_ARRAY.map((sectionType) => {
                return (
                    <div
                        onClick={() => {
                            scenarioStore.addSection(
                                sectionStore.create(sectionType.type, { id: guid() }),
                                props.eventId,
                            );
                            props.onClose();
                        }}
                        className={clsx(styles.icon, "flex_center_center p_20")}
                        key={sectionType.type}
                    >
                        <div className={"flex_column flex_center_center"}>
                            <img
                                style={{ width: "80px" }}
                                src={sectionType.icon}
                                alt={t(sectionType.labelKey)}
                                title={t(sectionType.labelKey)}
                            />
                            <div className={"mt_20 textAlign_center"}>{t(sectionType.labelKey)}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
