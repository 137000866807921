import React from "react";
import { FieldError } from "react-hook-form";
import styles from "./_css/inputFront.module.css";
import clsx from "clsx";

type Props<T = string> = React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
> & {
    error?: FieldError;
    onValueChange?: (value: T, event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    resizeable?: boolean;
};

export const TextAreaFront = React.forwardRef<HTMLTextAreaElement, Props>(
    ({ error, className, onValueChange, onChange, ...props }, ref) => {
        return (
            <>
                <textarea
                    {...props}
                    className={clsx(styles.input, { [styles.textAreaNoResize]: !props.resizeable }, className)}
                    ref={ref}
                    onChange={(event) => {
                        if (onChange) onChange(event);
                        if (onValueChange) onValueChange(event.target.value, event);
                    }}
                />
                {error && <div className={styles.error}>{error.message}</div>}
            </>
        );
    },
);
