import React from "react";
import { userStore } from "users/_stores/userStore";
import { LoadableFromLoading } from "common/loaders/LoadableFromLoading";
import { useLoadingFromPromise } from "common/loaders/useLoadingFromPromise";
import { TUserMdl } from "users/_models/UserMdl";
import { TScenarioMdl } from "scenarios/_models/ScenarioMdl";

type Props = {
    scenario: TScenarioMdl;
};

export function ScenarioCardAdmin(props: Props) {
    const fetch = userStore.get(props.scenario.user);
    const { loading } = useLoadingFromPromise<{ data: TUserMdl; response: Response }>(fetch);

    if (!loading) return null;

    return (
        <LoadableFromLoading
            loading={loading}
            renderer={(_status, _error, value) => {
                if (!value || !value.data) return null;
                return (
                    <div className={"mb_5 textAlign_center"}>
                        {" "}
                        {value?.data?.firstName + " " + value?.data?.lastName}
                    </div>
                );
            }}
        />
    );
}
