import React, { useCallback, useEffect, useState } from "react";
import styles from "./_css/eventPage.module.css";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import clsx from "clsx";
import { useHideDomElement } from "common/_utils/uiUtils";
import { InputFrontBlock } from "common/ui/forms/InputFrontBlock";
import { InputFront } from "common/ui/forms/InputFront";
import { TextAreaFront } from "common/ui/forms/TextAreaFront";
import { DateInput } from "scenarios/inputs/DateInput";
import { SectionsRenderer } from "scenarios/sections/SectionsRender";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { observer } from "mobx-react";
import { Dialog } from "common/ui/dialogs/Dialog";
import { SectionPicker } from "scenarios/sections/SectionPicker";
import { sectionStore } from "scenarios/sections/_stores/sectionStore";
import { EventPageSideBar } from "scenarios/events/EventPageSideBar";
import { TEventMdl } from "scenarios/_models/EventMdl";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import { Toggle } from "common/ui/forms/Toggle";
import closeSidebar from "../../assets/icons/closeSidebar.svg";
import { toast } from "react-toastify";
import { ConfirmationDialog } from "common/ui/dialogs/ConfirmationDialog";
import { socketStore } from "scenarios/socket/_stores/socketStore";
import { TUserMdl } from "users/_models/UserMdl";
import { eventsStore } from "scenarios/_stores/eventsStore";
import { MenuItem } from "@material-ui/core";
import { TEXT_SIZES } from "scenarios/_models/ScenarioMdl";
import { UiSelect } from "common/ui/mui/forms/UiSelect";
import { bookmarksStore } from "bookmarks/_stores/bookmarksStore";
import { isObject } from "lodash";

type Props = {
    periodId: string;
    eventId: string;
    onClose: () => void;
};

export const EventPage = observer((props: Props) => {
    useHideDomElement("#crisp-chatbox >*");
    const scenarioStore = useScenarioStore();
    const { event, period } = scenarioStore.getEvent(props.eventId);
    const { t } = useTranslation();
    const [sectionPicker, setSectionPicker] = useState(false);
    const [currentEvent, setCurrentEvent] = useState<TEventMdl | undefined>(event);
    const isCollaborator = scenarioStore.isCollaborator();
    const [isPublished, setIsPublished] = useState(event?.published ?? false);
    const [disabled, setDisabled] = useState(false);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

    const handlePrivateToggle = useCallback(
        (value: boolean) => {
            if (!currentEvent) return;
            const privateBookmark = bookmarksStore.bookmarks.find(
                (b) => isObject(b.title) && b.title?.en === "Private",
            );
            if (value && privateBookmark && !currentEvent?.bookmarks.find((b) => b === privateBookmark._id)) {
                setCurrentEvent({
                    ...currentEvent,
                    bookmarks: [...currentEvent.bookmarks, privateBookmark._id],
                    private: value,
                });
                return;
            } else if (!value && privateBookmark && currentEvent?.bookmarks.find((b) => b === privateBookmark._id)) {
                setCurrentEvent({
                    ...currentEvent,
                    bookmarks: currentEvent.bookmarks.filter((b) => b !== privateBookmark._id),
                    private: value,
                });
                return;
            }
            setCurrentEvent({ ...currentEvent, private: value });
        },
        [currentEvent],
    );
    useEffect(() => {
        scenarioStore.setEventId(props.eventId);
        socketStore.eventOnEditStart(scenarioStore.scenario._id, props.eventId, (userStore.user as TUserMdl)._id);
    }, []);

    useEffect(() => {
        if (isCollaborator && isCollaborator?.canEdit) {
            setIsPublished(false);
        }
        if (isPublished !== event?.published && currentEvent) {
            setCurrentEvent({ ...currentEvent, published: isPublished });
        }
    }, [isPublished]);

    useEffect(() => {
        if (event != currentEvent) {
            setCurrentEvent(event);
        }
    }, [event]);

    useEffect(() => {
        if (
            eventsStore.editingEvents.find((line) => line.eventId === event?.id && line.userId != userStore.user?._id)
        ) {
            setDisabled(true);
            toast.error(t("event.EVENT_NOT_SYNC"), { position: "bottom-center", autoClose: false });
        }
    }, [eventsStore.editingEvents]);

    if (!event || !currentEvent || !period || !userStore.user) return null;

    return (
        <div>
            <div className={clsx("flex_row", styles.container)}>
                <div
                    onClick={() => sectionStore.setEditingSection(undefined)}
                    className={clsx("flex-1 p_50", {
                        [styles.sideBarOpen]: scenarioStore.sidebarOpened,
                        [styles.sideBarClose]: !scenarioStore.sidebarOpened,
                    })}
                >
                    <div className={styles.btnCloseOrOpenSideBar}>
                        <div
                            className={clsx(styles.closeBtn, "position_absolute cursor_pointer", {
                                [styles.opened]: scenarioStore.sidebarOpened,
                            })}
                            onClick={() => scenarioStore.toggleSidebar()}
                        >
                            <img height={35} src={closeSidebar} alt={"close icon"} />
                        </div>
                    </div>
                    <div className={clsx(styles.header, "mb_25")}>
                        <h5 className={clsx(styles.title)}>{period.title}</h5>
                        <div className={"flex_row"}>
                            {!event.published && userStore.user._id === scenarioStore.scenario.user && (
                                <div className={"flex_row"}>
                                    <h5>{t("editor.event.approve")} </h5>
                                    <Toggle value={isPublished} onChange={setIsPublished} hasNotText={true} />
                                </div>
                            )}
                            {userStore.user._id === scenarioStore.scenario.user && (
                                <div className={"flex_row"}>
                                    <h5>{t("editor.event.private")} </h5>
                                    <Toggle value={currentEvent.private} onChange={handlePrivateToggle} hasNotText />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={clsx("flex_row_center", styles.contentInput)}>
                        <div className={clsx("flex-3 mr_20 mt_10", styles.inputs)}>
                            <InputFrontBlock
                                className={styles.input}
                                theme={"noBorder"}
                                label={t("editor.event.title")}
                            >
                                <InputFront
                                    autoFocus
                                    placeholder={t("editor.event.titlePlaceholder")}
                                    onChange={(e) => setCurrentEvent({ ...currentEvent, title: e.target.value })}
                                    value={currentEvent.title}
                                    disabled={scenarioStore.isCollaboratorAndNotEventAuthor(event)}
                                />
                            </InputFrontBlock>
                        </div>
                        <div className={clsx("flex-1 mt_10", styles.inputs)}>
                            <DateInput
                                disabled={scenarioStore.isCollaboratorAndNotEventAuthor(event)}
                                isUndefinedAllowed
                                className={styles.input}
                                date={currentEvent.date}
                                label={t("editor.event.date")}
                                onSave={(date) => setCurrentEvent({ ...currentEvent, date: date })}
                            />
                        </div>
                    </div>
                    <div className={clsx("mt_20", styles.inputs)}>
                        <InputFrontBlock
                            className={styles.input}
                            theme={"noBorder"}
                            label={t("editor.event.description")}
                        >
                            <TextAreaFront
                                value={currentEvent.text}
                                resizeable={true}
                                onValueChange={(text) => setCurrentEvent({ ...currentEvent, text })}
                                rows={4}
                                disabled={scenarioStore.isCollaboratorAndNotEventAuthor(event)}
                                className={clsx({
                                    scenario_text_small: currentEvent.textSize === TEXT_SIZES.SMALL,
                                    scenario_text_medium:
                                        currentEvent.textSize === TEXT_SIZES.MEDIUM || !currentEvent.textSize,
                                    scenario_text_large: currentEvent.textSize === TEXT_SIZES.LARGE,
                                })}
                            />
                            <UiSelect
                                value={currentEvent.textSize ?? TEXT_SIZES.MEDIUM}
                                displayEmpty
                                className={"mt_5"}
                                onChange={(e) => {
                                    setCurrentEvent({ ...currentEvent, textSize: e.target.value as TEXT_SIZES });
                                }}
                            >
                                {Object.values(TEXT_SIZES).map((size) => (
                                    <MenuItem key={size} value={size}>
                                        {t(`editor.textSize.${size}`)}
                                    </MenuItem>
                                ))}
                            </UiSelect>
                        </InputFrontBlock>
                    </div>
                    <div className={clsx("mt_20", styles.contentSections)}>
                        <SectionsRenderer
                            isEditable={scenarioStore.isCollaboratorEventAuthorOrScenarioOwner(event)}
                            eventId={props.eventId}
                        />
                    </div>
                    {scenarioStore.isCollaboratorEventAuthorOrScenarioOwner(event) && (
                        <div className={"flex_center_center"}>
                            <UiButton
                                onClick={() => {
                                    setSectionPicker(true);
                                }}
                                className={clsx("mt_20", styles.btnAddBlock)}
                                color={"primary"}
                                variant={"outlined"}
                            >
                                {t("editor.event.addBlock")}
                            </UiButton>
                        </div>
                    )}
                </div>
                <EventPageSideBar
                    onTemporaryEventSave={(event: TEventMdl) => setCurrentEvent(event)}
                    event={currentEvent}
                />
            </div>
            <div className={styles.footerPlaceholder} />
            <div className={clsx(styles.footer, "justifyContent_spaceBetween flex_row_center ph_30")}>
                <div>
                    <UiButton
                        className={clsx("mr_20", styles.button)}
                        onClick={() => {
                            if (!scenarioStore.notPersistentYetSectionIds.length) {
                                event.sections = event?.sections.filter(
                                    (section) =>
                                        section.props?.id &&
                                        !scenarioStore.notPersistentYetSectionIds.includes(section.props.id),
                                );
                            }

                            if (!scenarioStore.notPersistentYetDeletedSections.length) {
                                scenarioStore.restoreDeletedSection(event?.id);
                                scenarioStore.resetDeletedSection();
                            }

                            if (
                                !event?.title &&
                                !event?.text &&
                                event?.bookmarks.length === 0 &&
                                event?.sections.length === 0
                            ) {
                                scenarioStore.deleteEvent(currentEvent?.id, true);
                                socketStore.deleteEvent(scenarioStore.scenario._id, currentEvent);
                            }
                            sectionStore.setEditingSection(undefined);
                            socketStore.eventOnEditEnd(scenarioStore.scenario._id, event);
                            props.onClose();
                        }}
                        color={"primary"}
                        variant={"outlined"}
                        bg={"white"}
                    >
                        {t("words.cancel")}
                    </UiButton>
                    {scenarioStore.isCollaboratorEventAuthorOrScenarioOwner(event) && (
                        <UiButton
                            className={styles.button}
                            onClick={() => {
                                setOpenDeleteConfirmation(true);
                            }}
                            color={"primary"}
                            variant={"contained"}
                        >
                            {t("words.delete")}
                        </UiButton>
                    )}
                </div>
                {scenarioStore.isCollaboratorEventAuthorOrScenarioOwner(event) && (
                    <UiButton
                        className={styles.button}
                        disabled={disabled}
                        onClick={() => {
                            if (!disabled) {
                                setDisabled(true);
                                setCurrentEvent({ ...currentEvent, published: isPublished });
                                sectionStore.setEditingSection(undefined);
                                scenarioStore
                                    .editEvent(currentEvent, true)
                                    .then(() => {
                                        setDisabled(false);
                                        socketStore.eventOnEditEnd(scenarioStore.scenario._id, currentEvent);
                                        scenarioStore.resetDeletedSection();
                                        if (!scenarioStore.savingState.error) {
                                            props.onClose();
                                        } else {
                                            toast.error(t(`${scenarioStore.savingState.error.key}`));
                                        }
                                    })
                                    .catch((err) => {
                                        toast(err, { position: "bottom-center" });
                                        socketStore.eventOnEditEnd(scenarioStore.scenario._id, event);
                                    });
                            }
                        }}
                        color={"primary"}
                        variant={"contained"}
                    >
                        {t("words.save")}
                    </UiButton>
                )}
            </div>
            <Dialog open={sectionPicker} onClose={() => setSectionPicker(false)}>
                <SectionPicker eventId={event.id} onClose={() => setSectionPicker(false)} />
            </Dialog>
            <ConfirmationDialog
                title={t("editor.event.delete.title")}
                open={openDeleteConfirmation}
                message={t("editor.event.delete.message")}
                agreeLabel={t("editor.event.delete.yes")}
                onAgree={() => {
                    scenarioStore.deleteEvent(currentEvent?.id, true);
                    sectionStore.editingSection = undefined;
                    socketStore.eventOnEditEnd(scenarioStore.scenario._id, currentEvent);
                    socketStore.deleteEvent(scenarioStore.scenario._id, currentEvent);
                    scenarioStore.resetDeletedSection();
                    props.onClose();
                }}
                disagreeLabel={t("editor.event.delete.no")}
                onDisagree={() => setOpenDeleteConfirmation(false)}
            />
        </div>
    );
});
