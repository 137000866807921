import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

type Props = {
    children: ReactNode;
    url?: string;
    className?: string;
    activeClassName?: string;
    onClick?: () => void;
    noRouting?: boolean;
};

export function ExternalOrNavLink(props: Props) {
    const url = props.url ?? "/";
    if (props.noRouting) {
        return <div onClick={props.onClick}>{props.children}</div>;
    }
    return url.startsWith("/") ? (
        <NavLink
            className={props.className}
            activeClassName={props.activeClassName}
            to={url}
            exact
            onClick={props.onClick}
        >
            {props.children}
        </NavLink>
    ) : (
        <a className={props.className} href={url} target="_blank" rel="noopener noreferrer" onClick={props.onClick}>
            {props.children}
        </a>
    );
}
