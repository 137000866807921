import "mobx-react-lite/batchingForReactDom";
import React from "react";
import { hydrate } from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import IntlProvider from "../shared/common/i18n/IntlProvider";
import { App } from "App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "common/theme/theme";
import sharedConfig from "_configs/sharedConfig";
import { ScrollTop } from "common/ui/utils/ScrollTop";
import "../shared/common/_utils/analyticsService";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

if (!sharedConfig.isDev) {
    Sentry.init({
        dsn: "https://21a36ef40a8d4bc6ae5c796c517db89d@o1121840.ingest.sentry.io/6535457",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

const Main = () => {
    React.useEffect(() => {
        const jssStyles = document.querySelector("#jss-server-side");
        jssStyles?.parentElement?.removeChild(jssStyles);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <IntlProvider>
                    <HelmetProvider>
                        <ScrollTop />
                        <App />
                    </HelmetProvider>
                </IntlProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};

hydrate(<Main />, document.getElementById("app"));

if (process.env.NODE_ENV === "development") {
    if (module.hot) {
        module.hot.accept();
    }
}
