import React, { useEffect, useState } from "react";
import styles from "./_css/inviteCollaborator.module.css";
import { InputFrontBlock } from "common/ui/forms/InputFrontBlock";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { InputFront } from "common/ui/forms/InputFront";
import { TextAreaFront } from "common/ui/forms/TextAreaFront";
import { useTranslation } from "react-i18next";
import { isEmail } from "common/_utils/formUtils";
import { Toggle } from "common/ui/forms/Toggle";
import info from "../../assets/icons/info.svg";
import clsx from "clsx";
import { Dialog } from "common/ui/dialogs/Dialog";
import { AcceptDialog } from "common/ui/dialogs/AcceptDialog";
import { useScenarioStore } from "scenarios/ScenarioContext";
import dayjs from "dayjs";
import { userStore } from "users/_stores/userStore";
import { scenariosStore } from "scenarios/_stores/scenariosStore";
import { TCollaboratorMdl } from "scenarios/_models/CollaboratorMdl";
import collaboratorSetting from "../../assets/icons/collaboratorSetting.svg";
import { CollaboratorSettingDialog } from "scenarios/collaborators/CollaboratorSettingDialog";

type Props = {
    collaborators: TCollaboratorMdl[];
    onClose: () => void;
};

export function InviteCollaboratorDialog(props: Props) {
    const { t } = useTranslation();
    const scenarioStore = useScenarioStore();
    const [emails, setEmails] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [hasBeenSent, setHasBeenSent] = useState(false);
    const [isOverInfoReview, setIsOverInfoReview] = useState(false);
    const [reviewContribution, setReviewContribution] = useState(false);
    const [invitationText, setInvitationText] = useState<string>(
        t("collaborator.textareaPlaceholder", {
            userName: `${userStore.user?.firstName} ${userStore.user?.lastName}`,
        }),
    );
    const [visibilityOption, setVisibilityOption] = useState(false);
    const [proposedCollaborators, setOwnProposedCollaborators] = useState<TCollaboratorMdl[]>([]);
    const [openCollaboratorSetting, setOpenCollaboratorSetting] = useState<TCollaboratorMdl | undefined>(undefined);

    useEffect(() => {
        setIsValid(true);
        emails.split(",").map((email) => {
            if (!isEmail(email)) setIsValid(false);
        });
    }, [emails]);

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <div>{t("collaborator.addCollaboratorsTo")}</div>
                <div className={styles.scenarioName}>{scenarioStore.scenarioName}</div>
            </div>
            <div className={"mt_10"}>{t("collaborator.subTitle")}</div>
            <div>
                <div className={clsx(styles.collaboratorsTitle, "mt_20")}>{t("scenarioCard.collaborators")}</div>
                {props.collaborators &&
                    props.collaborators.map((collaborator) => (
                        <div key={collaborator.email} className={"flex_row_center mv_10"}>
                            <div className={"flex-1"}>
                                <div className={clsx(styles.email, "color_secondary")}>{collaborator.email}</div>
                                <div className={"mt_5 text_normal color_secondary"}>
                                    {t("scenarioCard.lastSeen")}{" "}
                                    {dayjs(collaborator.lastEdit).format(t("dates.formats.dayAndHour"))}
                                </div>
                            </div>
                            <div className={"cursor_pointer"}>
                                <img
                                    onClick={() => setOpenCollaboratorSetting(collaborator)}
                                    src={collaboratorSetting}
                                    title={t("scenarioCard.collaboratorSetting")}
                                    alt={"Collaborator settings icon"}
                                />
                            </div>
                        </div>
                    ))}
            </div>
            <div className={"position_relative"}>
                <InputFrontBlock className={"mt_50"} label={t("collaborator.emailLabel")}>
                    <InputFront
                        value={emails.replace(" ", "")}
                        onChange={(e) => {
                            setEmails(e.target.value);
                            setOwnProposedCollaborators(scenariosStore.getCollaboratorFromAllOwnCollaborators(emails));
                            if (
                                e.target.value != "" &&
                                proposedCollaborators &&
                                proposedCollaborators.length != 0 &&
                                scenariosStore.getCollaboratorFromAllOwnCollaborators(emails).length != 1 &&
                                scenariosStore.getCollaboratorFromAllOwnCollaborators(emails)[0].email !=
                                    `${e.target.value},`
                            ) {
                                setVisibilityOption(true);
                            }
                        }}
                        placeholder={t("collaborator.emailPlaceholder")}
                    />
                    {proposedCollaborators.length != 0 && emails.length != 0 && (
                        <div
                            className={clsx(styles.selectCollaborator, {
                                [styles.hidden]: !visibilityOption,
                            })}
                        >
                            {proposedCollaborators.map((collaborator, index) => {
                                if (emails.split(",").includes(collaborator.email)) return null;
                                return (
                                    <div
                                        className={styles.itemSelect}
                                        onClick={() => {
                                            const _emails = `${collaborator.email}, ${emails}`;
                                            const __emails = _emails.split(",");
                                            setEmails(__emails.slice(0, __emails.length - 1).toString());
                                            setVisibilityOption(false);
                                        }}
                                        key={index}
                                    >
                                        {collaborator.email}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </InputFrontBlock>
                <div className="mt_10 text_italic fontWeight_400">{t("collaborator.tips")}</div>
                <InputFrontBlock className={"mt_50"} label={t("collaborator.textareaLabel")}>
                    <TextAreaFront
                        onChange={(e) => setInvitationText(e.target.value)}
                        rows={6}
                        placeholder={invitationText}
                    />
                </InputFrontBlock>
                <div className="mt_20 flex_row justifyContent_flexEnd">
                    <Toggle
                        hasNotText
                        value={reviewContribution}
                        preLabel={
                            <span className={"flex_row_center"}>
                                <span className={"mr_5"}>{t("collaborator.reviewContributions")}</span>
                                <div className={"position_relative"}>
                                    <img
                                        onMouseEnter={() => setIsOverInfoReview(true)}
                                        onMouseLeave={() => setIsOverInfoReview(false)}
                                        className={"position_relative"}
                                        src={info}
                                        alt="info icon"
                                    />
                                    <div
                                        id={"reviewInfo"}
                                        className={clsx(
                                            "position_absolute display_none",
                                            styles.infoReviewContribution,
                                            {
                                                ["display_flex"]: isOverInfoReview,
                                            },
                                        )}
                                        dangerouslySetInnerHTML={{ __html: t("collaborator.reviewInfo") }}
                                    />
                                </div>
                            </span>
                        }
                        onChange={() => setReviewContribution(!reviewContribution)}
                    />
                </div>
            </div>
            <div className={clsx("flex_row_center mt_50 pb_20", styles.contentBtn)}>
                <div className={"flex-1"} />
                <UiButton
                    variant={"outlined"}
                    onClick={() => props.onClose()}
                    color={"primary"}
                    className={clsx("mr_10", styles.button)}
                >
                    {t("collaborator.cancel")}
                </UiButton>
                <UiButton
                    className={styles.button}
                    onClick={() => {
                        const collaboratorEmails = emails
                            .split(",")
                            .map((email) => email.trim())
                            .filter((email) => email !== "");
                        const collaborators = collaboratorEmails.map((collaboratorEmail) => {
                            return {
                                email: collaboratorEmail,
                                canEdit: reviewContribution,
                                lastEdit: dayjs(),
                            };
                        });
                        scenariosStore.addCollaborators(scenarioStore.scenario, collaborators, invitationText);
                        setHasBeenSent(true);
                    }}
                    disabled={!isValid}
                    variant={"contained"}
                    color={"primary"}
                >
                    {t("collaborator.sendInvite")}
                </UiButton>
            </div>
            <Dialog
                maxWidth={"sm"}
                borderRadius={24}
                fullWidth
                open={hasBeenSent}
                onClose={() => {
                    setHasBeenSent(false);
                    props.onClose();
                }}
            >
                <AcceptDialog
                    title={t("collaborator.hasBeenSent")}
                    body={t("collaborator.invitationInfo")}
                    withLogo
                    btn={
                        <UiButton style={{ minWidth: 250 }} variant={"outlined"} color={"primary"}>
                            {t("collaborator.thanks")}
                        </UiButton>
                    }
                    onClose={() => {
                        setHasBeenSent(false);
                        props.onClose();
                    }}
                />
            </Dialog>
            <Dialog
                fullWidth
                open={!!openCollaboratorSetting}
                onClose={() => setOpenCollaboratorSetting(undefined)}
                padding={30}
            >
                <CollaboratorSettingDialog
                    onClose={() => setOpenCollaboratorSetting(undefined)}
                    collaborator={openCollaboratorSetting}
                />
            </Dialog>
        </div>
    );
}
