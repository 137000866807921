import React from "react";
import styles from "./_css/nav.module.css";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { MENU_ID, TMenu } from "main/header/Header";
import { WrapperDialog } from "main/header/WrapperDialog";
import { authStore } from "users/auth/_stores/authStore";
import { userStore } from "users/_stores/userStore";
import { Link } from "react-router-dom";

type Props = {
    position: string;
    menu: TMenu[];
};

const MenuItem = ({ menu }: { menu: TMenu }) => {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <li
            key={menu.url}
            className={clsx(styles.li, "pv_15", {
                [styles.button]: menu.button,
                [styles.active]: location.pathname === menu.url,
            })}
        >
            {menu.label ? menu.label : menu.cb ? menu.cb(t(menu.labelKey)) : t(menu.labelKey)}
        </li>
    );
};

export function Nav(props: Props) {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <nav>
            <ul className={clsx(styles.ul, "flex_row_center")}>
                {props.menu
                    .filter((menu) => menu.position === props.position)
                    .map((menu, index) => {
                        return (
                            <div key={index}>
                                {menu.id === MENU_ID.REGISTER && !userStore.isGuest() ? null : menu.dialog ? (
                                    <WrapperDialog
                                        cb={menu.dialog}
                                        label={menu.labelKey}
                                        button={menu?.button}
                                    ></WrapperDialog>
                                ) : menu.id === MENU_ID.TRY_NOW ? (
                                    <div
                                        onClick={() => {
                                            authStore.signUpGuest().then(() => history.push(menu.url));
                                        }}
                                        className={clsx(styles.li, "pv_15", {
                                            [styles.button]: menu.button,
                                            [styles.active]: location.pathname === menu.url,
                                        })}
                                    >
                                        {t(menu.labelKey)}
                                    </div>
                                ) : (
                                    <>
                                        {menu.onClick ? (
                                            <a onClick={menu.onClick} key={menu.id}>
                                                <MenuItem menu={menu} />
                                            </a>
                                        ) : (
                                            <Link
                                                to={menu.url}
                                                key={menu.id}
                                                onClick={() => {
                                                    if (menu.id === MENU_ID.OUR_MISSION && __BROWSER__) {
                                                        window.location.href = menu.url;
                                                    }
                                                }}
                                            >
                                                <MenuItem menu={menu} />
                                            </Link>
                                        )}
                                    </>
                                )}
                            </div>
                        );
                    })}
            </ul>
        </nav>
    );
}
