import React from "react";
import { InputFront } from "common/ui/forms/InputFront";
import { imagesStore } from "images/_stores/imagesStore";
import styles from "images/_css/imageGallery.module.css";
import { InputBlock } from "common/ui/forms/InputBlock";
import { useTranslation } from "react-i18next";

export function InputFilterTitleImage() {
    const { t } = useTranslation();
    return (
        <InputBlock label={t("words.imageTitleFilterLabel")}>
            <InputFront
                onValueChange={(textFilter) => {
                    imagesStore.textFilter = textFilter;
                }}
                defaultValue={imagesStore.textFilter}
                className={styles.inputFilterBorder}
            />
        </InputBlock>
    );
}
