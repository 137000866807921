import React from "react";
import { NoSsr } from "@material-ui/core";
import { useParams } from "react-router";
import { scenariosStore } from "scenarios/_stores/scenariosStore";
import { ScenarioContext } from "scenarios/ScenarioContext";
import { LoadableFromLoading } from "common/loaders/LoadableFromLoading";
import { ScenarioStore } from "scenarios/_stores/ScenarioStore";
import { ScenarioSideBar } from "scenarios/ScenarioSideBar";
import { ScenarioHeader } from "scenarios/ScenarioHeader";
import { ScenarioBody } from "scenarios/ScenarioBody";
import styles from "./_css/scenarioPage.module.css";
import clsx from "clsx";
import { SignUpInvitationDialog } from "users/auth/dialog/SignUpInvitationDialog";
import { userStore } from "users/_stores/userStore";

export const PAGE_REF: { current: HTMLDivElement | null } = { current: null };

export function ScenarioPage() {
    const { scenarioId } = useParams();
    if (!scenarioId) return null;

    return (
        <LoadableFromLoading
            loading={scenariosStore.fetchScenario(scenarioId)}
            renderer={(_status, error, scenario) => {
                if (error || !scenario) return null;
                return (
                    <NoSsr>
                        <ScenarioContext scenarioStore={new ScenarioStore(scenario)}>
                            <div className={clsx(styles.container, "flex_row")} ref={PAGE_REF}>
                                <ScenarioSideBar />
                                <div className={clsx(styles.body, "flex-1 position_relative")}>
                                    <ScenarioHeader />
                                    <ScenarioBody />
                                    {userStore.isGuest() && <SignUpInvitationDialog />}
                                </div>
                            </div>
                        </ScenarioContext>
                    </NoSsr>
                );
            }}
        />
    );
}
