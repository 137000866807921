import { action, computed, observable } from "mobx";
import { TErrMdl } from "common/errors/_models/ErrMdl";

export type TLoadingStatus = "IDLE" | "LOADING" | "SUCCEEDED";

export class LoadingStateMdl<TValue = void> {
    @observable status: TLoadingStatus;
    @observable error?: TErrMdl;
    value?: TValue;
    promise?: Promise<TValue>;

    constructor(initialState: "IDLE" | "LOADING" = "IDLE") {
        this.status = initialState;
    }

    setStatus = action((status: TLoadingStatus) => {
        this.status = status;
    });

    startLoading = action(() => {
        this.status = "LOADING";
        this.value = undefined;
        this.error = undefined;
    });

    setError = action((error: TErrMdl) => {
        this.status = "IDLE";
        this.error = error;
    });

    setSuccess = action((value: TValue) => {
        this.value = value;
        this.status = "SUCCEEDED";
    });

    @computed get isLoading() {
        return this.status === "LOADING";
    }

    @computed get isSucceeded() {
        return this.status === "SUCCEEDED";
    }

    ifSucceeded<T = void, U = void>(onSuccess: (value: TValue) => T, onNotSuccess?: () => U) {
        return this.isSucceeded ? onSuccess(this.value as TValue) : onNotSuccess ? onNotSuccess() : undefined;
    }
}
