import React, { HTMLAttributes, memo, useCallback, useRef, useState } from "react";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { PhotoAlbum } from "react-photo-album";
import { toJS } from "mobx";
import { PLACEHOLDER_IMAGE, sectionStore } from "scenarios/sections/_stores/sectionStore";
import { SortablePhoto, SortablePhotoProps } from "scenarios/sections/GallerySection";
import clsx from "clsx";
import styles from "scenarios/sections/_css/photo.module.css";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { imagesStore, TImage } from "images/_stores/imagesStore";
import _ from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SwitchCameraRounded } from "@material-ui/icons";

type Props = {
    photos: SortablePhoto[];
    onEditPhotos: (photos: SortablePhoto[]) => void;
    nbColumns: number;
    id: string;
};

type PhotoFrameProps = SortablePhotoProps & {
    overlay?: boolean;
    active?: boolean;
    insertPosition?: "before" | "after";
    attributes?: Partial<HTMLAttributes<HTMLDivElement>>;
    listeners?: Partial<HTMLAttributes<HTMLDivElement>>;
};

const PhotoFrame = memo(
    React.forwardRef<HTMLDivElement, PhotoFrameProps>(function PhotoFrame(props, ref) {
        const { layoutOptions, imageProps, overlay, active, insertPosition, attributes, listeners } = props;
        const { alt, style, ...restImageProps } = imageProps;

        return (
            <div
                ref={ref}
                style={{
                    width: overlay ? `calc(100% - ${2 * layoutOptions.padding}px)` : style.width,
                    padding: style.padding,
                    marginBottom: style.marginBottom,
                }}
                className={clsx("photo-frame", {
                    overlay: overlay,
                    active: active,
                    insertBefore: insertPosition === "before",
                    insertAfter: insertPosition === "after",
                })}
                {...attributes}
                {...listeners}
            >
                <LazyLoadImage
                    alt={alt}
                    style={{
                        ...style,
                        // width: "100%",
                        height: "auto",
                        padding: 0,
                        marginBottom: 0,
                    }}
                    {...restImageProps}
                />
            </div>
        );
    }),
);

function SortablePhotoFrame(
    props: SortablePhotoProps & {
        activeIndex?: number;
        onDelete: (index: number) => void;
        onEdit: (index: number) => void;
        onSwitch: (index: number) => void;
    },
) {
    const { photo, activeIndex } = props;
    const { attributes, listeners, isDragging, index, over, setNodeRef } = useSortable({ id: photo.id });

    return (
        <div className={"position_relative"}>
            <div className={clsx("position_absolute", styles.btnDelete)}>
                <IconButton onClick={() => props.onDelete(index)} className={styles.iconBtn}>
                    <DeleteIcon color={"primary"} fontSize={"large"} />
                </IconButton>
            </div>
            <div className={clsx("position_absolute", styles.btnSwitch)}>
                <IconButton onClick={() => props.onSwitch(index)} className={styles.iconBtn}>
                    <SwitchCameraRounded color={"primary"} fontSize={"large"} />
                </IconButton>
            </div>

            <div onClick={() => props.onEdit(index)}>
                <PhotoFrame
                    ref={setNodeRef}
                    active={isDragging}
                    insertPosition={
                        activeIndex !== undefined && over?.id === photo.id && !isDragging
                            ? index > activeIndex
                                ? "after"
                                : "before"
                            : undefined
                    }
                    aria-label="sortable image"
                    attributes={attributes}
                    listeners={listeners}
                    {...props}
                />
            </div>
        </div>
    );
}

export function GallerySectionSortableContext(props: Props) {
    const renderedPhotos = useRef<{ [key: string]: SortablePhotoProps }>({});
    const [activeId, setActiveId] = useState<string>();
    const activeIndex = activeId ? props.photos.findIndex((photo) => photo.id === activeId) : undefined;

    const renderPhoto = (sortableProps: SortablePhotoProps) => {
        // capture rendered photos for future use in DragOverlay
        renderedPhotos.current[sortableProps.photo.id] = sortableProps;
        return (
            <SortablePhotoFrame
                onEdit={(index) => {
                    imagesStore.setGalleryState({
                        onSelect: (image) => {
                            if (!Array.isArray(image)) {
                                if (sectionStore.editingSection?.props?.images?.[index]) {
                                    const arrayImage = [...toJS(sectionStore.editingSection.props.images)];
                                    arrayImage[index] = {
                                        imageUrl: image.imageUrl,
                                        height: image.height,
                                        width: image.width,
                                        id: image._id,
                                    };
                                    sectionStore.editingSection.props.images = _.uniqBy(arrayImage, "id");
                                    props.onEditPhotos(
                                        sectionStore.editingSection.props.images.map((image: TImage) => {
                                            return {
                                                src: image.imageUrl,
                                                width: image.width,
                                                height: image.height,
                                                id: image.id,
                                            };
                                        }),
                                    );
                                    // sectionStore.editingSection = undefined;
                                }
                            }
                        },
                    });
                }}
                onDelete={(index) => {
                    if (sectionStore.editingSection?.props?.images) {
                        sectionStore.editingSection.props.images.splice(index, 1);

                        if (sectionStore.editingSection.props.images.length === 0) {
                            props.onEditPhotos([
                                {
                                    src: PLACEHOLDER_IMAGE,
                                    height: 350,
                                    width: 350,
                                    id: "placeholder",
                                },
                            ]);
                            return;
                        }

                        props.onEditPhotos(
                            sectionStore.editingSection.props.images.map((image) => {
                                return {
                                    src: image.imageUrl,
                                    width: image.width,
                                    height: image.height,
                                    id: image.id,
                                };
                            }),
                        );
                        sectionStore.editingSection = undefined;
                    }
                }}
                onSwitch={(index) => {
                    if (sectionStore.editingSection?.props?.images) {
                        const arrayImage = [...toJS(sectionStore.editingSection.props.images)];

                        sectionStore.editingSection.props.images = arrayImage.map((image, idx) => {
                            if (index === idx) {
                                return {
                                    ...image,
                                    width: image.height,
                                    height: image.width,
                                };
                            }
                            return image;
                        });

                        props.onEditPhotos(
                            arrayImage.map((image, idx) => {
                                if (index === idx) {
                                    return {
                                        src: image.imageUrl,
                                        width: image.height,
                                        height: image.width,
                                        id: image.id,
                                    };
                                }
                                return {
                                    src: image.imageUrl,
                                    width: image.width,
                                    height: image.height,
                                    id: image.id,
                                };
                            }),
                        );
                        sectionStore.editingSection = undefined;
                    }
                }}
                activeIndex={activeIndex}
                {...sortableProps}
            />
        );
    };

    const sensors = useSensors(
        useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
        useSensor(TouchSensor, { activationConstraint: { delay: 50, tolerance: 10 } }),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
    );

    const handleDragStart = useCallback(({ active }: DragStartEvent) => setActiveId(active.id), []);

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event;
        const newPhotos = toJS(JSON.parse(JSON.stringify(props.photos)));

        if (over && active.id !== over.id) {
            const oldIndex = props.photos.findIndex((item) => item.id === active.id);
            const newIndex = props.photos.findIndex((item) => item.id === over.id);

            props.onEditPhotos([...arrayMove(newPhotos, oldIndex, newIndex)] as SortablePhoto[]);

            if (sectionStore.editingSection?.props?.id === props.id && sectionStore.editingSection.props) {
                sectionStore.editingSection.props.images = ([
                    ...arrayMove(newPhotos, oldIndex, newIndex),
                ] as SortablePhoto[]).map((image: { src: string; width: number; height: number; id: string }) => {
                    return {
                        imageUrl: image.src,
                        height: image.height,
                        width: image.width,
                        id: image.id,
                    };
                });
            }
        }

        setActiveId(undefined);
    }, []);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
            <SortableContext items={props.photos}>
                <div className={"p_20"}>
                    <PhotoAlbum
                        photos={props.photos}
                        layout="columns"
                        columns={props.nbColumns}
                        spacing={2}
                        padding={2}
                        renderPhoto={renderPhoto}
                    />
                </div>
            </SortableContext>
            <DragOverlay>{activeId && <PhotoFrame overlay {...renderedPhotos.current[activeId]} />}</DragOverlay>
        </DndContext>
    );
}
