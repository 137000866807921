import React from "react";
import { Helmet } from "react-helmet-async";
import Header from "main/header/Header";
import Footer from "main/footer/Footer";
import "./_css/app.css";
import "./_css/fonts/icomoon.css";
import "react-toastify/dist/ReactToastify.css";
import { StickyContainer } from "react-sticky";
import { NoSsr } from "@material-ui/core";
import { CookieBanner } from "main/footer/CookieBanner";
import sharedConfig from "_configs/sharedConfig";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { AppContent } from "AppContent";

export const App: React.FC<any> = () => {
    const { t } = useTranslation();
    return (
        <div>
            <Helmet defaultTitle="ItsUrLife">
                <meta name="robots" content={sharedConfig.isProd ? "INDEX,FOLLOW" : "NOINDEX,NOFOLLOW"} />
                <meta name="title" content={t("name")} />
                <meta name="description" content={t("meta.description")} />
            </Helmet>
            <Header />
            <StickyContainer>
                <AppContent />
                <Footer />
            </StickyContainer>
            <NoSsr>
                <CookieBanner />
                <ToastContainer />
            </NoSsr>
        </div>
    );
};
