import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { FieldError } from "react-hook-form";
import { YearDropdown } from "common/ui/forms/date/YearDropdown";
import { MonthDropdown } from "common/ui/forms/date/MonthDropdown";
import { DayDropdown } from "common/ui/forms/date/DayDropdown";
import { IconButton } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { AgePicker } from "common/ui/forms/date/AgePicker";
import { useTranslation } from "react-i18next";
import styles from "./_css/datePickerDropdown.module.css";

type Props = {
    error?: FieldError;
    value?: Dayjs;
    onChange: (value: Dayjs | undefined) => void;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    canDelete?: boolean;
    disabled?: boolean;
    withAge?: boolean;
    birthDate?: Dayjs;
    ageDisabled?: boolean;
};

export const DatePickerDropdown = (props: Props) => {
    const [tmpValues, setTmpValues] = useState<{ day?: number; month?: number }>({});
    const { t } = useTranslation();

    const year = props.value?.year();
    const minYear = props.minDate?.year();
    const maxYear = props.maxDate?.year();

    const month = props.value?.month() ?? tmpValues.month;
    const minMonth = year && year === minYear ? props.minDate?.month() : undefined;
    const maxMonth = year && year === maxYear ? props.maxDate?.month() : undefined;

    const day = props.value?.date() ?? tmpValues.day;
    const minDay =
        year && year === minYear && month !== undefined && month === minMonth ? props.minDate?.date() : undefined;
    const maxDay =
        year && year === maxYear && month !== undefined && month === maxMonth ? props.maxDate?.date() : undefined;

    useEffect(() => {
        setTmpValues({});
        if (props.value) {
            if (props.maxDate && props.value.isAfter(props.maxDate)) {
                props.onChange(props.maxDate);
            } else if (props.minDate && props.value.isBefore(props.minDate)) {
                props.onChange(props.minDate);
            }
        }
    }, [props.minDate, props.maxDate]);

    return (
        <>
            <div className="flex_row_center position_relative">
                <DayDropdown
                    value={day}
                    onChange={(day) => {
                        setTmpValues({ ...tmpValues, day });
                        if (props.value) {
                            const newDate = props.value.set("date", day).startOf("day");
                            if (props.minDate && newDate.isBefore(props.minDate)) {
                                props.onChange(props.minDate);
                            } else if (props.maxDate && newDate.isAfter(props.maxDate)) {
                                props.onChange(props.maxDate);
                            } else {
                                props.onChange(newDate);
                            }
                        }
                    }}
                    month={month}
                    year={year}
                    minDay={minDay}
                    maxDay={maxDay}
                    disabled={props.disabled}
                />
                <div className="mr_3" />
                <MonthDropdown
                    value={month}
                    onChange={(month) => {
                        setTmpValues({ ...tmpValues, month });
                        if (props.value) {
                            const newDate = props.value.set("month", month);
                            if (props.minDate && newDate.isBefore(props.minDate)) {
                                props.onChange(props.minDate);
                            } else if (props.maxDate && newDate.isAfter(props.maxDate)) {
                                props.onChange(props.maxDate);
                            } else {
                                props.onChange(newDate);
                            }
                        }
                    }}
                    minMonth={minMonth}
                    maxMonth={maxMonth}
                    disabled={props.disabled}
                />
                <div className="mr_3" />
                <YearDropdown
                    value={year}
                    onChange={(year) => {
                        if (!props.value) {
                            let newValue = dayjs.utc(year + "", "YYYY").add(1, "day");
                            if (tmpValues.month !== undefined) {
                                newValue = newValue.set("month", tmpValues.month).startOf("month");
                            }
                            if (tmpValues.day !== undefined) {
                                newValue = newValue.set("date", tmpValues.day).startOf("day");
                            }
                            if (props.minDate && newValue.isBefore(props.minDate)) {
                                props.onChange(props.minDate);
                            } else {
                                props.onChange(newValue);
                            }
                        } else {
                            const newDate = props.value.set("year", year);
                            if (props.minDate && newDate.isBefore(props.minDate)) {
                                props.onChange(props.minDate);
                            } else if (props.maxDate && newDate.isAfter(props.maxDate)) {
                                props.onChange(props.maxDate);
                            } else {
                                props.onChange(newDate);
                            }
                        }
                    }}
                    minYear={minYear}
                    maxYear={maxYear}
                    disabled={props.disabled}
                />
                {props.withAge && props.birthDate && (
                    <>
                        <div className="mr_15" />
                        <div>
                            <div className={styles.age}>{t("words.age")}</div>
                            <AgePicker
                                value={props.value}
                                minDate={props.minDate}
                                maxDate={props.maxDate}
                                birthDate={props.birthDate}
                                disabled={props.disabled || props.ageDisabled}
                                onChange={(date) => {
                                    setTmpValues({});
                                    props.onChange(date);
                                }}
                            />
                        </div>
                    </>
                )}
                {props.canDelete && (props.value || tmpValues.day || tmpValues.month !== undefined) && !props.disabled && (
                    <>
                        <div className="mr_3" />
                        <IconButton
                            onClick={() => {
                                setTmpValues({});
                                if (props.value) props.onChange(undefined);
                            }}
                            size="small"
                        >
                            <Clear fontSize="small" />
                        </IconButton>
                    </>
                )}
            </div>
            {props.error && <div className={styles.error}>{props.error.message}</div>}
        </>
    );
};
