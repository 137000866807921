export const appConfig = {
    token: {
        storageKey: "TOKEN",
    },
    hotjar: {
        id: 1859673,
    },
    authGoogleId: "912706032357-iq2uogttptfe2kbpa92877a1db0ro2b7.apps.googleusercontent.com",
    authGoogleToken: "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",
    dropBox: {
        apiKey: "uokgqw058dhq23b"
    }
};
