import { TThoughtMdl } from "scenarios/_models/ThoughtMdl";

class ThoughtsStore {
    thoughts: TThoughtMdl[] = [];

    private readonly _thoughtsIndex: { [thoughtId: string]: TThoughtMdl } = {};

    constructor() {
        if (__BROWSER__) {
            this.init(window.__PRELOADED_STATE__.thoughts);
        }
    }

    init(thoughts: TThoughtMdl[]) {
        this.thoughts = thoughts;
        for (let i = 0; i < thoughts.length; i++) {
            this._thoughtsIndex[thoughts[i]._id] = thoughts[i];
        }
    }
}

export const thoughtsStore = new ThoughtsStore();
