import React from "react";
import { scenariosStore } from "scenarios/_stores/scenariosStore";
import { useHistory, useParams } from "react-router";
import { ScenarioDraftPage } from "scenarios/draft/ScenarioDraftPage";
import { NoSsr } from "@material-ui/core";
import { ScenarioContext } from "scenarios/ScenarioContext";
import { ScenarioStore } from "scenarios/_stores/ScenarioStore";
import { LoadableFromLoading } from "common/loaders/LoadableFromLoading";
import { URLS } from "_configs/URLS";

export function ScenarioView() {
    const { scenarioToken } = useParams();
    const history = useHistory();
    if (!scenarioToken) return null;
    return (
        <div>
            <LoadableFromLoading
                loading={scenariosStore.fetchScenarioWithToken(scenarioToken)}
                renderer={(_status, error, scenario) => {
                    if (error || !scenario) {
                        history.push(URLS.home() + "?error");
                        return null;
                    }
                    return (
                        <NoSsr>
                            <ScenarioContext scenarioStore={new ScenarioStore(scenario)}>
                                <ScenarioDraftPage isEditable={false} />
                            </ScenarioContext>
                        </NoSsr>
                    );
                }}
            />
        </div>
    );
}
