import React from "react";
import { AuthenticatedRoute } from "common/_utils/AuthenticatedRoute";
import { URLS } from "_configs/URLS";
import { AuthPage } from "users/auth/AuthPage";
import { Route, Switch } from "react-router-dom";
import { ScenarioPage } from "scenarios/ScenarioPage";
import { ScenariosPage } from "scenarios/ScenariosPage";
import { ScenarioDraft } from "scenarios/draft/ScenarioDraft";
import Home from "main/pages/home/Home";
import { Redirect } from "react-router";
import { UserPage } from "users/UserPage";
import { ScenarioView } from "scenarios/sections/ScenarioView";
import Contact from "main/pages/contact/Contact";

export function AppContent() {
    return (
        <>
            <Switch>
                <AuthenticatedRoute
                    exact
                    path={URLS.scenarios.draft(":scenarioId", ":lang?")}
                    component={ScenarioDraft}
                />
                <Route path={URLS.auth.base()} component={AuthPage} reverse={true} />
                <Route exact path={URLS.home(":lang?")} component={Home} />
                <Route exact path={URLS.contact(":lang?")} component={Contact} />
                <Route exact path={URLS.scenarios.view(":scenarioToken")} component={ScenarioView} />
                <AuthenticatedRoute exact path={URLS.scenarios.base(":lang?")} component={ScenariosPage} />
                <AuthenticatedRoute path={URLS.profile(":lang?")} component={UserPage} />
                <AuthenticatedRoute path={URLS.scenarios.scenario(":scenarioId", ":lang?")} component={ScenarioPage} />
                <Redirect to={URLS.auth.signIn(":lang?")} />
            </Switch>
        </>
    );
}
