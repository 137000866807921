import React, { useState } from "react";
import { IconButton, NoSsr } from "@material-ui/core";
import styles from "./_css/mobileMenuBtn.module.css";
import { MobileMenu } from "main/header/MobileMenu";
import { TMenu } from "main/header/Header";
import { Menu } from "@material-ui/icons";
import { Logo } from "main/Logo";
import clsx from "clsx";
import { URLS } from "_configs/URLS";
import { useHistory } from "react-router";

type Props = {
    menu: TMenu[];
};

export function MobileMenuBtn(props: Props) {
    const [opened, setOpened] = useState(false);
    const history = useHistory();
    return (
        <div className={clsx(styles.contentHeader, "flex_center_center position_relative")}>
            <div className={styles.logo}>
                <div className={"cursor_pointer"} onClick={() => history.push(URLS.home() + "/#home")}>
                    <Logo />
                </div>
            </div>
            <div className={styles.containerIcon}>
                <IconButton
                    size={"medium"}
                    onClick={() => {
                        setOpened(!opened);
                    }}
                >
                    <Menu style={{ color: "black", fontSize: "30px" }} />
                </IconButton>
            </div>
            <NoSsr>
                <MobileMenu menu={props.menu} opened={opened} onClose={() => setOpened(false)} />
            </NoSsr>
        </div>
    );
}
