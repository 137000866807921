import React, { useState } from "react";
import { DatePickerDropdown } from "common/ui/forms/date/DatePickerDropdown";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { InputFrontBlock } from "common/ui/forms/InputFrontBlock";
import dayjs, { Dayjs } from "dayjs";
import dateIcon from "../../assets/icons/dateIcon.svg";
import { useTranslation } from "react-i18next";

type Props = {
    date: Dayjs | undefined;
    onSave: (date: Dayjs | undefined) => void;
    label: string;
    className?: string;
    isUndefinedAllowed?: boolean;
    disabled?: boolean;
};

export function DateInput(props: Props) {
    const [isEditingDate, setIsEditingDate] = useState(false);
    const { t } = useTranslation();
    const isEditableDate = !props.disabled && isEditingDate;

    return (
        <InputFrontBlock className={props.className} theme={"noBorder"} label={props.label}>
            <div className={"mt_10"}>
                {isEditableDate ? (
                    <div>
                        <div className={"flex_row_center"}>
                            <DatePickerDropdown
                                onChange={(date) => {
                                    if (date) props.onSave(date);
                                }}
                                value={dayjs(props.date) ?? dayjs()}
                            />
                            <div className={"flex-1"} />
                            <UiButton
                                onClick={() => setIsEditingDate(!isEditingDate)}
                                color={"primary"}
                                variant={"contained"}
                                className={"ml_5"}
                                small
                            >
                                Ok
                            </UiButton>
                        </div>
                        {props.isUndefinedAllowed && (
                            <div className={"mt_5 flex_row justifyContent_flexEnd"}>
                                <UiButton
                                    onClick={() => {
                                        setIsEditingDate(!isEditingDate);
                                        props.onSave(undefined);
                                    }}
                                    color={"secondary"}
                                    variant={"contained"}
                                    small
                                    className={"ml_5"}
                                >
                                    {t("editor.event.noDate")}
                                </UiButton>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={"cursor_pointer flex_row_center"} onClick={() => setIsEditingDate(!isEditingDate)}>
                        {props.date ? dayjs(props.date).format("DD/MM/YYYY") : t("editor.event.noDate")}
                        <div className="flex-1" />
                        <img src={dateIcon} alt={"date icon"} />
                    </div>
                )}
            </div>
        </InputFrontBlock>
    );
}
