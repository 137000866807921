import React, { useState } from "react";
import { SignUpDialog } from "users/auth/dialog/SignUpDialog";

export function SignUpPage() {
    const [open, setOpen] = useState(true);
    return (
        <div>
            <SignUpDialog open={open} setOpen={setOpen} notClose={true} />
        </div>
    );
}
