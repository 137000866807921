import React, { useMemo } from 'react';
import { UiSelect } from 'common/ui/mui/forms/UiSelect';
import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

type Props = {
    value?: number;
    month?: number;
    year?: number;
    onChange: (value: number) => void;
    minDay?: number;
    maxDay?: number;
    disabled?: boolean;
};

const MenuProps = {
    PaperProps: { style: { maxHeight: 250 } },
};

function getOptions(minDay?: number, maxDay?: number, month?: number, year?: number) {
    const options = [];
    minDay = minDay ?? 1;
    maxDay = maxDay ?? 31;
    if (month) {
        let monthDate = year ? dayjs.utc(year + '', 'YYYY') : dayjs.utc();
        maxDay = Math.min(monthDate.set('month', month).endOf('month').date(), maxDay);
    }
    for (let i = minDay; i <= maxDay; i++) {
        options.push(
            <MenuItem key={i} value={i}>
                {(i < 10 ? '0' : '') + i}
            </MenuItem>,
        );
    }
    return options;
}

export function DayDropdown(props: Props) {
    const { t } = useTranslation();
    const options = useMemo(
        () => getOptions(props.minDay, props.maxDay, props.month, props.year),
        [props.minDay, props.maxDay, props.month, props.year],
    );

    return (
        <UiSelect
            value={props.value || ''}
            onChange={event => props.onChange(event.target.value as number)}
            MenuProps={MenuProps}
            displayEmpty
            disabled={props.disabled}
        >
            {!props.value && (
                <MenuItem value="">
                    {t('dates.day')}
                </MenuItem>
            )}
            {options}
        </UiSelect>
    );
}
