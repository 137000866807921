import sharedConfig from "_configs/sharedConfig";
import { universalLanguageDetect } from "@unly/universal-language-detector";

export function detectLanguage(pathname: string, acceptLanguageHeader?: string) {
    let lang = sharedConfig.defaultLang;
    if (pathname.startsWith("/en")) {
        lang = "en";
    } else if (pathname.startsWith("/fr")) {
        lang = "fr";
    } else {
        lang = universalLanguageDetect({
            supportedLanguages: ["en", "fr"],
            fallbackLanguage: "en",
            acceptLanguageHeader,
        }) as "en" | "fr";
    }

    return sharedConfig.languages[lang];
}
