import React from "react";
import clsx from "clsx";
import styles from "./_css/newFolderBlock.module.css";
import CreateNewFolder from "@material-ui/icons/CreateNewFolder";

type Props = {
    onClick?: () => void;
    text: string;
    className?: string;
};

export function NewFolderBlock(props: Props) {
    return (
        <div className={clsx(styles.container, "flex_center_center", props.className)} onClick={props.onClick}>
            <CreateNewFolder style={{ fontSize: "5rem" }} />
            <div className={styles.text}>{props.text}</div>
        </div>
    );
}
