import React, { CSSProperties } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import styles from "./_css/textSection.module.css";

type Props = {
    text: string;
    componentId?: string;
    id?: string;
    styles?: CSSProperties;
    isEditable?: boolean;
    onEdit?: () => void;
    textSize?: "small" | "medium" | "large";
};

export const TextSection = observer(({ onEdit, isEditable, text, ...props }: Props) => {
    return (
        <div
            onClick={(e) => {
                if (isEditable) {
                    e.preventDefault();
                    e.stopPropagation();
                    onEdit ? onEdit() : undefined;
                }
            }}
            {...props}
            className={clsx("avoidBreak", styles.text, {
                [styles.textSmall]: props.textSize === "small",
                [styles.textMedium]: props.textSize === "medium",
                [styles.textLarge]: props.textSize === "large",
                [styles.textSmall]: !props.textSize,
            })}
            dangerouslySetInnerHTML={{ __html: text }}
        />
    );
});
