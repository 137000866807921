import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useHash() {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash === "") {
            window.scrollTo(0, 0);
        } else {
            setTimeout(() => {
                const id = hash.replace("#", "");
                const element = document.getElementById(id);
                if (element) {
                    window.scrollTo({ top: element.offsetTop, behavior: "smooth" });
                }
            }, 300);
        }
    }, [hash]);
}
