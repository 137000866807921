import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Input } from "common/ui/forms/Input";
import { InputBlock } from "common/ui/forms/InputBlock";
import { useLoadingFromPromise } from "common/loaders/useLoadingFromPromise";
import { observer } from "mobx-react";
import { authStore } from "users/auth/_stores/authStore";
import { ErrorBlock } from "common/errors/ErrorBlock";
import { CardContent, CardHeader, IconButton } from "@material-ui/core";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { URLS } from "_configs/URLS";
import { Link, useLocation } from "react-router-dom";
import * as queryString from "querystring";
import { UiPaper } from "common/ui/mui/surfaces/UiPaper";
import { Visibility, VisibilityOff } from "@material-ui/icons";

type TFormData = {
    password: string;
    confirmPassword: string;
};

export const ResetPasswordBlock = observer(() => {
    const { t } = useTranslation();
    const location = useLocation();
    const { handleSubmit, register, errors, watch } = useForm<TFormData>({ mode: "onBlur" });
    const { loading, setPromise } = useLoadingFromPromise();
    const [showPassword, setShowPassword] = useState(true);
    const token = queryString.parse(location.search)["?token"];
    const onSubmit = (values: TFormData) => {
        if (typeof token === "string") {
            const promise = authStore.resetPassword(token, values.password);
            setPromise(promise);
            promise.then(() => setIsUpdated(true));
        }
    };
    const isLoading = loading?.status === "LOADING";
    const [isUpdated, setIsUpdated] = useState(false);

    return (
        <UiPaper style={{ width: 450 }}>
            <CardHeader className="mt_10" style={{ textAlign: "center" }} title={t("auth.resetPassword.title")} />
            <hr />
            <CardContent>
                {isUpdated ? t("auth.forgottenPassword.updated") : t("auth.forgottenPassword.reset")}
            </CardContent>
            {!isUpdated && (
                <>
                    <hr />
                    <CardContent>
                        <ErrorBlock error={loading?.error} />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <InputBlock label={t("words.password")} text={t("auth.signUp.passwordHint")}>
                                <Input
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    ref={register({
                                        required: t("errors.forms.required"),
                                        minLength: { value: 8, message: t("auth.signUp.passwordsLength") },
                                    })}
                                    error={errors.password}
                                    disabled={isLoading}
                                    endIcon={
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                            size="small"
                                        >
                                            {showPassword ? (
                                                <Visibility fontSize="small" />
                                            ) : (
                                                <VisibilityOff fontSize="small" />
                                            )}
                                        </IconButton>
                                    }
                                />
                            </InputBlock>
                            <InputBlock label={t("auth.signUp.passwordConfirmation")}>
                                <Input
                                    name="confirmPassword"
                                    type={showPassword ? "text" : "password"}
                                    ref={register({
                                        required: t("errors.forms.required"),
                                        minLength: { value: 8, message: t("auth.signUp.passwordsLength") },
                                        validate: (value) =>
                                            value !== watch("password")
                                                ? t("auth.signUp.passwordsMismatch")
                                                : undefined,
                                    })}
                                    error={errors.confirmPassword}
                                    disabled={isLoading}
                                    endIcon={
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                            size="small"
                                        >
                                            {showPassword ? (
                                                <Visibility fontSize="small" />
                                            ) : (
                                                <VisibilityOff fontSize="small" />
                                            )}
                                        </IconButton>
                                    }
                                />
                            </InputBlock>
                            <div className="flex_center_center mb_15">
                                <UiButton type="submit" disabled={isLoading} variant="contained" color="primary">
                                    {t(isLoading ? "loaders.message" : "auth.resetPassword.title")}
                                </UiButton>
                            </div>
                        </form>
                    </CardContent>
                </>
            )}
            <hr />
            <CardContent className="flex_center_center flex_column">
                {!isUpdated && <div>{t("auth.signUp.alreadyUser")}</div>}
                <Link to={URLS.auth.signIn()} className="link">
                    {t("auth.signIn.title")}
                </Link>
            </CardContent>
        </UiPaper>
    );
});
