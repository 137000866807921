import React, { useState } from "react";
import { TCollaboratorMdl } from "scenarios/_models/CollaboratorMdl";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import styles from "./_css/collaboratorSettingDialog.module.css";
import { Toggle } from "common/ui/forms/Toggle";
import { useScenarioStore } from "scenarios/ScenarioContext";
import clsx from "clsx";
import { scenariosStore } from "scenarios/_stores/scenariosStore";
import { ConfirmationDialog } from "common/ui/dialogs/ConfirmationDialog";
import { toast } from "react-toastify";

type Props = {
    onClose: () => void;
    collaborator?: TCollaboratorMdl;
};

export function CollaboratorSettingDialog(props: Props) {
    const [canEdit, setCanEdit] = useState(!!props.collaborator?.canEdit);
    const [openConfirm, setOpenConfirm] = useState(false);
    const { t } = useTranslation();
    const scenarioStore = useScenarioStore();
    const scenario = scenarioStore.scenario;
    if (!props.collaborator) return null;
    return (
        <div className={styles.container}>
            <div className={clsx(styles.collaboratorTitle, styles.row)}>{props.collaborator?.email}</div>
            <div className={clsx("mt_20 flex_row_center justifyContent_spaceBetween", styles.column)}>
                <Toggle
                    hasNotText
                    label={t("collaborator.reviewContributions")}
                    value={canEdit}
                    onChange={() => setCanEdit(!canEdit)}
                />
                <UiButton
                    onClick={() => {
                        scenariosStore.deleteCollaborator(scenario, (props.collaborator as TCollaboratorMdl).email);
                        props.onClose();
                    }}
                    variant={"contained"}
                    color={"primary"}
                    className={styles.btnDelete}
                >
                    {t("words.delete")}
                </UiButton>
            </div>
            <div className={clsx("mt_20 flex_row_center justifyContent_spaceBetween", styles.column)}>
                {t("changeOwner.change")}
                <UiButton
                    onClick={() => setOpenConfirm(true)}
                    variant={"contained"}
                    color={"primary"}
                    className={styles.btnDelete}
                >
                    {t("changeOwner.label")}
                </UiButton>
            </div>
            <div className={clsx("flex_row_center mt_50", styles.row)}>
                <div className={"flex-1"} />
                <UiButton variant={"outlined"} onClick={() => props.onClose()} color={"primary"} className={"mr_10"}>
                    {t("collaborator.cancel")}
                </UiButton>
                <UiButton
                    disabled={canEdit === props?.collaborator?.canEdit}
                    onClick={() => {
                        scenariosStore.editCollaborator(scenario, {
                            ...(props.collaborator as TCollaboratorMdl),
                            canEdit,
                        });
                        props.onClose();
                    }}
                    variant={"contained"}
                    color={"primary"}
                >
                    {t("collaborator.save")}
                </UiButton>
            </div>
            <ConfirmationDialog
                title={""}
                open={openConfirm}
                message={t("changeOwner.text")}
                agreeLabel={t("changeOwner.confirm")}
                onAgree={() => {
                    scenariosStore
                        .changeOwner(scenario, (props.collaborator as TCollaboratorMdl).email)
                        .then(() => {
                            toast(t("scenarioCard.operationSuccess"));
                            setOpenConfirm(false);
                            props.onClose();
                        })
                        .catch(() => toast(t("scenarioCard.operationFailed")));
                }}
                disagreeLabel={t("changeOwner.cancel")}
                onDisagree={() => {
                    setOpenConfirm(false);
                }}
            />
        </div>
    );
}
