import { Button, ButtonProps } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import styles from "./_css/uiButton.module.css";

type Props = ButtonProps & {
    isDeleteButton?: boolean;
    small?: boolean;
    noLeftBorder?: boolean;
    bg?: "white";
};

export const UiButton = ({ isDeleteButton, variant, color, disabled, small, noLeftBorder, bg, ...props }: Props) => {
    return (
        <Button
            {...props}
            variant={variant}
            className={clsx(
                {
                    [styles.containerForDelete]: isDeleteButton,
                    [styles.container]: variant === "contained",
                    [styles.outlined]: variant === "outlined",
                    [styles.text]: variant === "text",
                    [styles.primary]: color === "primary",
                    [styles.secondary]: color === "secondary",
                    [styles.small]: small,
                    [styles.noLeftBorder]: noLeftBorder,
                    [styles.bgWhite]: bg === "white",
                    [styles.disabled]: disabled,
                },
                props.className,
            )}
            classes={{
                root: styles.root,
                label: styles.label,
                sizeSmall: styles.sizeSmall,
                contained: styles.contained,
                text: styles.text,
                outlined: styles.outlined,
                containedPrimary: styles.containedPrimary,
                disabled: styles.disabled,
            }}
        />
    );
};
