import React from "react";
import { Dialog } from "common/ui/dialogs/Dialog";
import { DialogContent } from "@material-ui/core";
import clsx from "clsx";
import styles from "../_css/deleteAccountDialog.module.css";
import logoWithoutBrand from "../../../assets/img/logoWithoutBrand.svg";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { userStore } from "users/_stores/userStore";
import { useTranslation } from "react-i18next";

type Props = {
    open: boolean;
    setOpen: (bool: boolean) => void;
};

export function DeleteAccountDialog(props: Props) {
    const { t } = useTranslation();
    return (
        <div>
            <Dialog open={props.open} onClose={() => props.setOpen(false)}>
                <DialogContent>
                    <div className={"mb_50 flex_column justifyContent_center"}>
                        <img className={clsx("mb_30", styles.image)} src={logoWithoutBrand} alt={"logo It's ur life"} />
                        <h5 className={clsx("mt_10", styles.text)}>{t("deleteAccount.text")}</h5>

                        <UiButton
                            variant={"contained"}
                            color={"primary"}
                            className={"mt_10"}
                            onClick={() => props.setOpen(false)}
                        >
                            {t("deleteAccount.cancel")}
                        </UiButton>
                        <UiButton
                            variant={"outlined"}
                            className={clsx(styles.btnDelete, "mt_10")}
                            onClick={() => {
                                userStore.deleteUser();
                                props.setOpen(false);
                            }}
                        >
                            {t("deleteAccount.confirm")}
                        </UiButton>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
