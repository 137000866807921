import React from "react";
import clsx from "clsx";
import { TextSectionEditor } from "scenarios/sections/editor/TextSectionEditor";
import { ImageSectionEditor } from "scenarios/sections/editor/ImageSectionEditor";
import { useScenarioStore } from "scenarios/ScenarioContext";

type Props = {
    imageUrl: string;
    text: string;
    ratio: "half" | "smallBig" | "bigSmall" | "onlyOneColumn";
    reverse: boolean;
    id: string;
    isEditable?: boolean;
    onEdit?: () => void;
    textSize?: "small" | "medium" | "large";
};

export function TextImageSectionEditor(props: Props) {
    const scenarioStore = useScenarioStore();
    return (
        <div
            onClick={(e) => {
                if (props.isEditable) {
                    e.preventDefault();
                    e.stopPropagation();
                    props.onEdit ? props.onEdit() : undefined;
                    scenarioStore.toggleSidebar();
                }
            }}
            className={"flex_row"}
        >
            <div
                className={clsx({
                    ["flex-2  mr_20"]: props.ratio === "bigSmall",
                    ["flex-1  mr_20"]:
                        props.ratio === "smallBig" || props.ratio === "half" || props.ratio === "onlyOneColumn",
                })}
            >
                {props.reverse ? (
                    <ImageSectionEditor imageUrl={props.imageUrl} />
                ) : (
                    <TextSectionEditor styles={{ height: "100%" }} {...props} />
                )}
            </div>
            <div
                className={clsx({
                    ["flex-2"]: props.ratio === "smallBig",
                    ["flex-1"]: props.ratio === "bigSmall" || props.ratio === "half",
                    ["display_none"]: props.ratio === "onlyOneColumn",
                })}
            >
                {props.reverse ? (
                    <TextSectionEditor styles={{ height: "100%" }} {...props} />
                ) : (
                    <ImageSectionEditor imageUrl={props.imageUrl} />
                )}
            </div>
        </div>
    );
}
