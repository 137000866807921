import React from "react";
import styles from "./_css/mobileMenu.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { MENU_ID, TMenu } from "main/header/Header";
import { Logo } from "main/Logo";
import { userStore } from "users/_stores/userStore";
import { WrapperDialog } from "main/header/WrapperDialog";
import { authStore } from "users/auth/_stores/authStore";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";

type Props = {
    opened: boolean;
    onClose: () => void;
    menu: TMenu[];
};

export function MobileMenu(props: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <div
            className={clsx(styles.container, {
                [styles.closed]: !props.opened,
                [styles.isHomePage]: window.location.pathname === "/fr/" || window.location.pathname === "/en/",
            })}
        >
            <div className={clsx(styles.content, "position_relative")}>
                <div className={styles.logo}>
                    <div className={"cursor_pointer"} onClick={() => history.push(URLS.home() + "/#home")}>
                        <Logo />
                    </div>
                </div>
                <IconButton className={styles.closeIcon} onClick={props.onClose}>
                    <CloseIcon style={{ color: "white" }} fontSize="large" />
                </IconButton>
                {props.menu.map((menu_item, index) => (
                    <li className={"ph_20 pv_10"} key={index} onClick={props.onClose}>
                        {menu_item.id === MENU_ID.REGISTER && !userStore.isGuest() ? null : menu_item.dialog ? (
                            <WrapperDialog
                                cb={menu_item.dialog}
                                label={menu_item.labelKey}
                                button={menu_item?.button}
                                isMobile={true}
                            ></WrapperDialog>
                        ) : menu_item.id === MENU_ID.TRY_NOW ? (
                            <div
                                onClick={() => {
                                    authStore.signUpGuest().then((res) => history.push(menu_item.url));
                                }}
                                className={clsx(styles.li, "pv_15", {
                                    [styles.button]: menu_item.button,
                                    [styles.active]: location.pathname === menu_item.url,
                                })}
                            >
                                {t(menu_item.labelKey)}
                            </div>
                        ) : (
                            <a
                                onClick={menu_item.onClick ? menu_item.onClick : undefined}
                                href={menu_item.onClick ? undefined : menu_item.url}
                                key={menu_item.id}
                            >
                                <div key={menu_item.url} className={clsx(styles.li, "pv_15")}>
                                    {menu_item.label
                                        ? menu_item.label
                                        : menu_item.cb
                                        ? menu_item.cb(t(menu_item.labelKey))
                                        : t(menu_item.labelKey)}
                                </div>
                            </a>
                        )}
                    </li>
                ))}
            </div>
        </div>
    );
}
