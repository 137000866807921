import styles from './_css/errorBlock.module.css';
import React from 'react';
import clsx from 'clsx';
import { TErrMdl } from 'common/errors/_models/ErrMdl';
import { useTranslation } from 'react-i18next';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    error?: TErrMdl;
};

export const ErrorBlock = React.forwardRef<HTMLDivElement, Props>(({ error, className, ...props }: Props, ref) => {
    const { t } = useTranslation();

    if (!error) return null;
    return (
        <div className={clsx(styles.container, className)} {...props} ref={ref}>
            {t([error?.key, 'errors.global.unknownLight'])}
        </div>
    );
});
