import React, { useMemo } from 'react';
import { UiSelect } from 'common/ui/mui/forms/UiSelect';
import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type Props = {
    value?: number;
    onChange: (value: number) => void;
    minMonth?: number;
    maxMonth?: number;
    disabled?: boolean;
};

const MenuProps = {
    PaperProps: { style: { maxHeight: 250 } },
};

function getOptions(minMonth?: number, maxMonth?: number) {
    const options = [];
    minMonth = minMonth ?? 0;
    maxMonth = maxMonth ?? 11;
    for (let i = minMonth; i <= maxMonth; i++) {
        options.push(
            <MenuItem key={i} value={i + 1}>
                {(i < 9 ? '0' : '') + (i + 1)}
            </MenuItem>,
        );
    }
    return options;
}

export function MonthDropdown(props: Props) {
    const { t } = useTranslation();
    const options = useMemo(() => getOptions(props.minMonth, props.maxMonth), [props.minMonth, props.maxMonth]);

    return (
        <UiSelect
            value={props.value !== undefined ? props.value + 1 : ''}
            onChange={event => props.onChange(event.target.value as number - 1)}
            MenuProps={MenuProps}
            displayEmpty
            disabled={props.disabled}
        >
            {!props.value && (
                <MenuItem value="">
                    {t('dates.month')}
                </MenuItem>
            )}
            {options}
        </UiSelect>
    );
}
