import React, { CSSProperties } from "react";
import styles from "./_css/imagePreview.module.css";
import clsx from "clsx";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { imagesStore } from "images/_stores/imagesStore";
import { observer } from "mobx-react";

type Props = {
    style?: CSSProperties;
    imageUrl: string;
    onClick?: () => void;
    onDelete?: () => void;
    onDragStart?: () => void;
    onDragEnd?: () => void;
};

export const ImagePreview = observer((props: Props) => {
    return (
        <div
            onDragStart={
                props.onDragStart
                    ? (_e) => {
                          props.onDragStart?.();
                      }
                    : undefined
            }
            onDragEnd={
                props.onDragEnd
                    ? (_e) => {
                          props.onDragEnd?.();
                      }
                    : undefined
            }
            className={clsx(styles.container, "flex_center_center", {
                [styles.clickable]: props.onClick,
                [styles.selected]: imagesStore.selectedImagesForGallerySection.find(
                    (img) => img.imageUrl === props.imageUrl,
                ),
            })}
            onClick={props.onClick}
        >
            <LazyLoadImage style={props.style} className={styles.img} src={props.imageUrl} alt="" />
            {props.onDelete && (
                <div className={styles.delete}>
                    <IconButton
                        style={{ backgroundColor: "#000" }}
                        onClick={(event) => {
                            event.stopPropagation();
                            props.onDelete?.();
                        }}
                        size="small"
                    >
                        <DeleteIcon style={{ color: "#FFF" }} />
                    </IconButton>
                </div>
            )}
            {imagesStore.selectedImagesForGallerySection.find((img) => img.imageUrl === props.imageUrl) && (
                <div className={styles.check}>
                    <IconButton style={{ backgroundColor: "green" }} size="small">
                        <CheckIcon style={{ color: "#FFF" }} />
                    </IconButton>
                </div>
            )}
        </div>
    );
});
