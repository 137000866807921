import React, { useState } from "react";
import { observer } from "mobx-react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { ImageGallery } from "images/ImageGallery";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { imagesStore } from "images/_stores/imagesStore";
import { Toggle } from "common/ui/forms/Toggle";
import { useTranslation } from "react-i18next";
import { InputFilterTitleImage } from "images/InputFilterTitleImage";

export const ImageGalleryModal = observer(() => {
    const [onlyUser, setOnlyUser] = useState(false);
    const { t } = useTranslation();

    function close() {
        imagesStore.setGalleryState(undefined);
    }

    return (
        <Dialog open={!!imagesStore.galleryState} onClose={close} maxWidth="xl" fullWidth fullScreen>
            <DialogTitle>
                <div className="flex_row_center">
                    <div className={"flex-2 mr_30"}>
                        <InputFilterTitleImage />
                    </div>
                    <div className={"flex-1"} />
                    <div className={"flex-2"}>
                        <Toggle
                            hasNotText
                            label={t("words.onlyPictureUser")}
                            value={onlyUser}
                            onChange={() => setOnlyUser(!onlyUser)}
                        />
                    </div>
                    <IconButton aria-label="close" onClick={close}>
                        <CloseIcon fontSize={"large"} />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <hr className={"mb_30"} />
                <ImageGallery
                    onlyUser={onlyUser}
                    onSelect={(image) => {
                        if (
                            !imagesStore.galleryState?.multiple &&
                            imagesStore.galleryState?.onSelect({
                                imageUrl: image.url,
                                height: image.height,
                                width: image.width,
                                _id: image._id,
                            }) !== -1
                        ) {
                            close();
                        }
                    }}
                    close={close}
                />
            </DialogContent>
        </Dialog>
    );
});
