import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "scenarios/collaborators/_css/inviteCollaborator.module.css";
import { Dialog } from "common/ui/dialogs/Dialog";
import { InputFront } from "common/ui/forms/InputFront";
import { InputFrontBlock } from "common/ui/forms/InputFrontBlock";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import clsx from "clsx";
import { IImageMdl } from "images/_models/ImageMdl";

type Props = {
    open: boolean;
    onClose: () => void;
    onSuccess: (updatedImage: IImageMdl) => void;
    image?: IImageMdl;
};

export function InputTitleDialog(props: Props) {
    const { t } = useTranslation();
    const [image, setImage] = useState<IImageMdl | undefined>(props.image);

    useEffect(() => {
        setImage(props.image);
    }, [props.image]);

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth="md">
            <div style={{ width: 750, maxWidth: "100%" }}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        {t("words.rename")} {props.image?.title}
                    </div>
                    <InputFrontBlock className={"mt_50"} label={t("words.edit")}>
                        <InputFront
                            value={
                                image?.title ??
                                image?.url.split("/")[image?.url.split("/").length - 1 ?? 0].split(".")[0] ??
                                ""
                            }
                            onValueChange={(imageTitle) =>
                                image ? setImage({ ...image, title: imageTitle }) : undefined
                            }
                        />
                    </InputFrontBlock>
                    <div className={clsx("flex_row_center mt_50 pb_20", styles.contentBtn)}>
                        <div className={"flex-1"} />
                        <UiButton
                            variant={"outlined"}
                            onClick={() => props.onClose()}
                            color={"primary"}
                            className={clsx("mr_10", styles.button)}
                        >
                            {t("words.cancel")}
                        </UiButton>
                        <UiButton
                            className={styles.button}
                            onClick={() => {
                                if (image) props.onSuccess(image);
                            }}
                            disabled={!image?.title || image.title === ""}
                            variant={"contained"}
                            color={"primary"}
                        >
                            {t("words.save")}
                        </UiButton>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
