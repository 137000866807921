import { LoadingStateMdl } from "common/loaders/_models/LoadingStateMdl";
import { useState } from "react";
import { createLoadingStateFromPromise } from "common/loaders/loadingStateUtils";

export function useLoadingFromPromise<TValue>(promise?: Promise<TValue>) {
    const [loading, setLoading] = useState<LoadingStateMdl<TValue> | undefined>(
        promise ? createLoadingStateFromPromise(promise) : undefined,
    );

    return {
        loading,
        setPromise: (promise: Promise<TValue> | undefined) =>
            setLoading(promise ? createLoadingStateFromPromise(promise) : undefined),
    };
}
