import { TPersonMdl } from "./PersonMdl";
import { TPeriodMdl } from "scenarios/_models/PeriodMdl";
import dayjs, { Dayjs } from "dayjs";
import { TBookmarkMdl } from "bookmarks/_models/BookmarkMdl";
import { TThoughtMdl } from "scenarios/_models/ThoughtMdl";
import { TAnswerMdl } from "scenarios/_models/AnswerMdl";
import { TCollaboratorMdl } from "scenarios/_models/CollaboratorMdl";
import { TUserMdl } from "users/_models/UserMdl";
import { guid, s4 } from "common/_utils/coreUtils";
import { TFunction } from "i18next";

export enum TEXT_SIZES {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
}

export type TBaseScenarioMdl = {
    _id: string;

    title: string;
    sumUp?: string;
    sumUpSize?: TEXT_SIZES;
    firstName: string;
    lastName?: string;
    coverImg?: string;
    birthday?: Dayjs;
    deathDate?: Dayjs;
    birthCity?: string;
    birthCountry?: string;

    persons: TPersonMdl[];

    currentPeriodMark?: number;
    lifeMark?: number;
    finaleLifeMark?: number;

    bookmarks: TBookmarkMdl[];
    thoughts: TThoughtMdl[];
    hiddenThoughts: string[];
    answers: TAnswerMdl[];

    user: string;
    collaborators: TCollaboratorMdl[];
    token: string;
    isShareable: boolean;
    isDeleted: boolean;

    legacy?: {
        type: "testament" | "people" | "delete";
        lastName: string;
        firstName: string;
        email: string;
        phone: string;
    };

    updatedAt: Dayjs;
    createdAt: Dayjs;
};

export type TScenarioMdl = TBaseScenarioMdl & {
    periods: TPeriodMdl[];
};

export type TScenarioUserdMdl = TBaseScenarioMdl & {
    user: TUserMdl;
};

export const defaultScenario = (user: TUserMdl, t: TFunction) => {
    return {
        title: s4(),
        user: user._id,
        birthday: dayjs(),
        firstName: user.firstName,
        periods: [
            {
                _id: guid(),
                title: t("templateScenario.babyHood"),
            },
            {
                _id: guid(),
                title: t("templateScenario.childhood"),
            },
            {
                _id: guid(),
                title: t("templateScenario.teens"),
            },
            {
                _id: guid(),
                title: t("templateScenario.youngAdult"),
            },
            {
                _id: guid(),
                title: t("templateScenario.adult"),
            },
        ],
    };
};
