import { appConfig } from "_configs/appConfig";

class AnalyticsService {
    readonly enabled = process.env.NODE_ENV !== "development";
    readonly crispPromise: Promise<any> | undefined;

    constructor() {
        if (!__BROWSER__) {
            return;
        }

        // this._loadHotJar();
        // this.crispPromise = this._loadCrisp();
    }

    _loadHotJar() {
        if (appConfig.hotjar.id && this.enabled) {
            (function (h: any, o: any, t: any, j: any, a?: any, r?: any) {
                h.hj =
                    h.hj ||
                    function () {
                        (h.hj.q = h.hj.q || []).push(arguments);
                    };
                h._hjSettings = { hjid: appConfig.hotjar.id, hjsv: 6 };
                a = o.getElementsByTagName("head")[0];
                r = o.createElement("script");
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
        }
    }

    _loadCrisp() {
        return new Promise((resolve) => {
            (window as any).$crisp = [];
            (window as any).CRISP_WEBSITE_ID = "89c306ee-b08f-44ae-8299-ff771de5e714";
            (function () {
                const script = document.createElement("script");
                script.src = "https://client.crisp.chat/l.js";
                script.async = true;
                script.onload = () => resolve((window as any).$crisp);
                document.getElementsByTagName("head")[0].appendChild(script);
            })();
        });
    }

    setUserInfo(email: string) {
        // this.crispPromise?.then((crisp) => {
        //     crisp.push(["set", "user:email", email]);
        // });
    }
}

const analyticsService = new AnalyticsService();
export default analyticsService;
