import React from "react";
import { ImageSection } from "scenarios/sections/ImageSection";
import clsx from "clsx";
import styles from "scenarios/sections/_css/gallerySection.module.css";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { imagesStore } from "images/_stores/imagesStore";
import { sectionStore } from "scenarios/sections/_stores/sectionStore";
import { useTranslation } from "react-i18next";

type Props = {
    imageUrl: string;
    alt?: string;
    title?: string;
    isEditable?: boolean;
    onEdit?: () => void;
};

const AddImageButton = ({
    onButtonClick,
}: {
    onButtonClick: (e: { preventDefault: () => void; stopPropagation: () => void }) => void;
}) => {
    const { t } = useTranslation();

    return (
        <UiButton size={"small"} variant={"contained"} onClick={onButtonClick} color={"primary"}>
            {t("editor.event.addImage")}
        </UiButton>
    );
};

export function ImageSectionEditor({ ...props }: Props) {
    return (
        <div>
            <ImageSection imageUrl={props.imageUrl} />
            <div className={clsx(styles.addImage, "position_absolute")}>
                <AddImageButton
                    onButtonClick={(e: { preventDefault: () => void; stopPropagation: () => void }) => {
                        e.preventDefault();
                        e.stopPropagation();
                        imagesStore.setGalleryState({
                            onSelect: (image) => {
                                if (!Array.isArray(image)) {
                                    if (sectionStore.editingSection?.props?.imageUrl) {
                                        sectionStore.editingSection.props.imageUrl = image.imageUrl;
                                    }
                                }
                            },
                        });
                    }}
                />
            </div>
        </div>
    );
}
