import React, { useState } from "react";
import styles from "./_css/legacyOption.module.css";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { UiSelect } from "common/ui/mui/forms/UiSelect";
import { MenuItem } from "@material-ui/core";
import { Input } from "common/ui/forms/Input";
import { InputBlock } from "common/ui/forms/InputBlock";
import { scenariosStore } from "scenarios/_stores/scenariosStore";
import { toast } from "react-toastify";

type Props = {
    onClose: () => void;
};

export function LegacyOptionDialog(props: Props) {
    const { t } = useTranslation();
    const scenarioStore = useScenarioStore();
    const [legacyType, setLegacyType] = useState<"testament" | "people" | "delete">(
        scenarioStore.scenario.legacy?.type ?? "people",
    );
    const [legacyData, setLegacyData] = useState<{
        lastName: string;
        firstName: string;
        email: string;
        phone: string;
    }>({
        lastName: scenarioStore.scenario.legacy?.lastName || "",
        firstName: scenarioStore.scenario.legacy?.firstName || "",
        email: scenarioStore.scenario.legacy?.email || "",
        phone: scenarioStore.scenario.legacy?.phone || "",
    });

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.scenarioName}>{scenarioStore.scenarioName}</div>
            </div>
            <div className={"mt_10"}>{t("legacy.text")}</div>
            <div className={"mt_20 textAlign_center"}>
                <UiSelect
                    value={legacyType}
                    onChange={(e) => {
                        setLegacyType(e.target.value as "testament" | "people" | "delete");
                    }}
                >
                    <MenuItem value={"testament"}>{t("legacy.type.testament")}</MenuItem>
                    <MenuItem value={"people"}>{t("legacy.type.people")}</MenuItem>
                    <MenuItem value={"delete"}>{t("legacy.type.delete")}</MenuItem>
                </UiSelect>
            </div>
            {legacyType !== "delete" && (
                <div className={"mt_20 gap_5"}>
                    <InputBlock
                        required
                        labelStyle={styles.label}
                        label={t("legacy.lastName")}
                        className={clsx("mb_25", styles.inputBlock)}
                    >
                        <Input
                            value={legacyData.lastName}
                            onValueChange={(value) => setLegacyData({ ...legacyData, lastName: value })}
                            className={styles.input}
                            name="lastName"
                        />
                    </InputBlock>
                    <InputBlock
                        required
                        labelStyle={styles.label}
                        label={t("legacy.firstName")}
                        className={clsx("mb_25", styles.inputBlock)}
                    >
                        <Input
                            value={legacyData.firstName}
                            onValueChange={(value) => setLegacyData({ ...legacyData, firstName: value })}
                            className={styles.input}
                            name="firstName"
                        />
                    </InputBlock>
                    <InputBlock
                        required
                        labelStyle={styles.label}
                        label={t("legacy.email")}
                        className={clsx("mb_25", styles.inputBlock)}
                    >
                        <Input
                            value={legacyData.email}
                            onValueChange={(value) => setLegacyData({ ...legacyData, email: value })}
                            className={styles.input}
                            name="email"
                        />
                    </InputBlock>
                    <InputBlock
                        labelStyle={styles.label}
                        label={t("legacy.phone")}
                        className={clsx("mb_25", styles.inputBlock)}
                    >
                        <Input
                            value={legacyData.phone}
                            onValueChange={(value) => setLegacyData({ ...legacyData, phone: value })}
                            className={styles.input}
                            name="phone"
                        />
                    </InputBlock>
                </div>
            )}
            <div className={clsx("flex_row_center mt_50 pb_20", styles.contentBtn)}>
                <div className={"flex-1"} />
                <UiButton
                    variant={"outlined"}
                    onClick={() => props.onClose()}
                    color={"primary"}
                    className={clsx("mr_10", styles.button)}
                >
                    {t("legacy.cancel")}
                </UiButton>
                <UiButton
                    className={styles.button}
                    onClick={() => {
                        scenariosStore
                            .save({
                                _id: scenarioStore.scenario._id,
                                legacy: { ...legacyData, type: legacyType },
                            })
                            .then(() => {
                                props.onClose();
                                toast.success(t("profil.success", { position: "bottom-center" }));
                            });
                    }}
                    variant={"contained"}
                    color={"primary"}
                >
                    {t("legacy.confirm")}
                </UiButton>
            </div>
        </div>
    );
}
