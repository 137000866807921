import { LoadingStateMdl, TLoadingStatus } from "common/loaders/_models/LoadingStateMdl";
import { reaction } from "mobx";

export function createLoadingStateFromPromise<TValue>(promise: Promise<TValue>) {
    const loading = new LoadingStateMdl<TValue>("LOADING");
    promise.then(loading.setSuccess, loading.setError);

    return loading;
}

function combinedStatusOfAllStatuses(statuses: TLoadingStatus[]) {
    const loadingOrIdleStatuses = statuses.filter((status) => status === "LOADING" || status === "IDLE");
    if (loadingOrIdleStatuses.length > 0) return "LOADING";
    else return "SUCCEEDED";
}

export function combineLoadingStates(states: LoadingStateMdl<any>[]) {
    const combinedLoadingState = new LoadingStateMdl<any>();
    reaction(
        () => combinedStatusOfAllStatuses(states.map((loadingState) => loadingState.status)),
        combinedLoadingState.setStatus,
    );
    return combinedLoadingState;
}
