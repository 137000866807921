import React, { useState } from "react";
import { Dialog } from "common/ui/dialogs/Dialog";
import { DialogContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import styles from "../_css/signUpInvitation.module.css";
import logoWithoutBrand from "../../../assets/img/logoWithoutBrand.svg";
import clsx from "clsx";
import { SignUpDialog } from "users/auth/dialog/SignUpDialog";
import { ValidationNotSignUpDialog } from "users/auth/dialog/ValidationNotSignUpDialog";
import { observer } from "mobx-react";
import { authStore } from "users/auth/_stores/authStore";

export const SignUpInvitationDialog = observer(() => {
    const { t } = useTranslation();
    const [openModalSignUp, setOpenModalSignUp] = useState(false);
    const [openValidationNotSignUpDialog, setOpenValidationNotSignUpDialog] = useState(false);
    return (
        <div>
            <Dialog
                open={authStore.statusInvitationSignUp}
                onClose={() => authStore.setStatusInvitationSignUp(false)}
                className={styles.dialog}
            >
                <DialogContent className={"mb_40"}>
                    <div className={"flex_column justifyContent_center alignItems_center"}>
                        <img className={styles.image} src={logoWithoutBrand} alt={"logo It's ur life"} />
                        <h4 className={styles.title}>{t("signUpInvitation.title")}</h4>
                        <div className={styles.text}>{t("signUpInvitation.text")}</div>
                    </div>
                </DialogContent>
                <div className={"flex_column alignItems_center justifyContent_center"}>
                    <UiButton
                        onClick={() => setOpenModalSignUp(true)}
                        color={"primary"}
                        variant={"contained"}
                        className={clsx("mb_10", styles.btn)}
                    >
                        {t("signUpInvitation.createAccount")}
                    </UiButton>
                    <UiButton
                        onClick={() => {
                            setOpenValidationNotSignUpDialog(true);
                            authStore.setStatusInvitationSignUp(false);
                        }}
                        className={styles.btn}
                    >
                        {t("signUpInvitation.noThanks")}
                    </UiButton>
                </div>
            </Dialog>
            <SignUpDialog open={openModalSignUp} setOpen={setOpenModalSignUp} />
            <ValidationNotSignUpDialog
                open={openValidationNotSignUpDialog}
                setOpen={setOpenValidationNotSignUpDialog}
            />
        </div>
    );
});
