import React from "react";
import { appConfig } from "_configs/appConfig";
import { Helmet } from "react-helmet-async";
import jwt from "jsonwebtoken";
import { authStore } from "users/auth/_stores/authStore";
import { toast } from "react-toastify";
import i18next from "i18next";
import { URLS } from "_configs/URLS";

function handleCredentialResponse(response: any) {
    if (__BROWSER__) {
        const lang = i18next.language;
        const googleUser = (window as any).decodeJWT(response.credential);
        const user = {
            profileObj: {
                email: googleUser.email,
                givenName: googleUser.given_name,
                familyName: googleUser.family_name,
                googleId: googleUser.google_id,
                imageUrl: googleUser.image_url,
                name: googleUser.name,
            },
        };
        authStore.googleAuth(user)?.then(
            () => {
                window.location.href = URLS.scenarios.base(lang);
            },
            () => toast.error("An error occurred, please try again."),
        );
    }
}

if (__BROWSER__) {
    (window as any).decodeJWT = jwt.decode;
    (window as any).handleCredentialResponse = handleCredentialResponse;
}

export function GoogleSignUp() {
    return (
        <div>
            <Helmet>
                <script src="https://accounts.google.com/gsi/client" async defer></script>
            </Helmet>
            <div
                id="g_id_onload"
                data-client_id={appConfig.authGoogleId}
                data-callback="handleCredentialResponse"
                data-auto_prompt="false"
            ></div>
            <div
                className="g_id_signin"
                data-type="standard"
                data-size="large"
                data-theme="outline"
                data-text="sign_in_with"
                data-shape="rectangular"
                data-logo_alignment="left"
            ></div>
        </div>
    );
}
