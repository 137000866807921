import React, { useState } from "react";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { ArrowBackOutlined } from "@material-ui/icons";
import { useScenarioStore } from "scenarios/ScenarioContext";
import styles from "../_css/ScenarioDraftHeader.module.css";
import clsx from "clsx";
import { Container } from "@material-ui/core";
import printIcon from "../../assets/icons/printIcon.svg";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import i18next from "i18next";
import { UiButton } from "common/ui/mui/buttons/UiButton";

type Props = {
    isEditable: boolean;
};

export function ScenarioDraftHeader(props: Props) {
    const store = useScenarioStore();
    const { t } = useTranslation();

    const [openFilter, setOpenFilter] = useState(false);
    const allFilter = store.getAllTag();
    const lang = i18next.language;

    return (
        <Container className={clsx("flex no-print", styles.containerHeader)}>
            <div className={clsx("flex_row_center justifyContent_spaceBetween", styles.content)}>
                <div className={"flex_row_center"}>
                    {props.isEditable && (
                        <Link to={URLS.scenarios.scenario(store.scenario._id)} className={"flex_row_center"}>
                            <ArrowBackOutlined fontSize={"large"} />
                            <div className={clsx("ml_5", styles.back)}>{t("draftPage.back").toUpperCase()} </div>
                        </Link>
                    )}
                </div>
                <div className={"flex_row_center"}>
                    {allFilter.length != 0 && (
                        <div
                            onClick={() => {
                                setOpenFilter(!openFilter);
                            }}
                            className={clsx("position_relative", styles.filter)}
                        >
                            <img src={printIcon} className={"mr_30"} />
                            {store.filters.length != 0 && (
                                <div className={clsx("position_absolute", styles.numberFilters)}>
                                    {store.filters.length}
                                </div>
                            )}
                            {openFilter && (
                                <div
                                    onMouseLeave={() => setOpenFilter(false)}
                                    className={clsx("position_absolute", styles.contentFilter)}
                                >
                                    {allFilter.map((filter) => {
                                        if (!filter || !filter.title[lang]) return null;
                                        return (
                                            <div
                                                className={clsx(styles.itemFilter, {
                                                    [styles.itemActive]: store.filters.find(
                                                        (_filter) => _filter._id === filter._id,
                                                    ),
                                                })}
                                                key={filter._id}
                                                onClick={() => {
                                                    store.setFilters(filter);
                                                }}
                                            >
                                                <div className={styles.tag}> #</div>
                                                <div className={"ml_20"}>{filter.title[lang]}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                    {!userStore.isGuest() && props.isEditable && (
                        <UiButton
                            onClick={() => {
                                window.print();
                            }}
                            className={styles.btn}
                            variant={"contained"}
                            color={"primary"}
                        >
                            {t("draftPage.print")}
                        </UiButton>
                    )}
                </div>
            </div>
        </Container>
    );
}
