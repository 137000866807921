import React, { useState } from "react";
import styles from "./_css/header.module.css";
import { Container, NoSsr } from "@material-ui/core";
import clsx from "clsx";
import { Logo } from "main/Logo";
import { Nav } from "main/header/Nav";
import { URLS } from "_configs/URLS";
import { LangSelector } from "main/header/LangSelector";
import { userStore } from "users/_stores/userStore";
import { useHistory } from "react-router";
import i18next from "i18next";
import { SignInDialog } from "users/auth/dialog/SignInDialog";
import { SignUpDialog } from "users/auth/dialog/SignUpDialog";
import { MobileMenuBtn } from "main/header/MobileMenuBtn";
import { Dialog } from "common/ui/dialogs/Dialog";
import { observer } from "mobx-react";

export type TMenu = {
    id: string;
    labelKey: string;
    label?: string;
    position: string;
    url: string;
    button?: boolean;
    isVisible?: boolean;
    cb?: (txt: string) => string;
    dialog?: (bool: boolean, setOpen: (bool: boolean) => void) => JSX.Element;
    onClick?: () => void;
};

export enum MENU_ID {
    STORIES = "STORIES",
    REGISTER = "REGISTER",
    LOGIN = "LOGIN",
    OUR_MISSION = "OUR_MISSION",
    CONTACT = "CONTACT",
    DEMO = "DEMO",
    TRY_NOW = "TRY_NOW",
}

function Header() {
    const history = useHistory();
    const [openVideo, setOpenVideo] = useState(false);

    const menu: { user: TMenu[]; guest: TMenu[] } = {
        user: [
            {
                id: MENU_ID.STORIES,
                labelKey: "header.stories",
                position: "left",
                url: URLS.scenarios.base(i18next.language),
            },
            {
                id: MENU_ID.CONTACT,
                labelKey: "header.contact",
                position: "left",
                url: URLS.contact(i18next.language),
            },
            {
                id: MENU_ID.REGISTER,
                labelKey: "header.register",
                position: "right",
                url: URLS.auth.signUp(i18next.language),
                dialog: (open: boolean, setOpen: (bool: boolean) => void) => (
                    <SignUpDialog open={open} setOpen={setOpen} />
                ),
            },
            {
                id: MENU_ID.LOGIN,
                labelKey: "header.login",
                label: userStore.user?.email,
                position: "right",
                url: URLS.profile(i18next.language),
                cb: (txt: string) => txt.toUpperCase(),
            },
        ],
        guest: [
            {
                id: MENU_ID.OUR_MISSION,
                labelKey: "header.ourMission",
                position: "left",
                url: `/${i18next.language}/#ourMission`,
                isVisible: true,
            },
            {
                id: MENU_ID.CONTACT,
                labelKey: "header.contact",
                position: "left",
                url: URLS.contact(i18next.language),
            },
            {
                id: MENU_ID.DEMO,
                labelKey: "header.seeDemo",
                position: "left",
                url: "#",
                onClick: () => setOpenVideo(true),
            },
            {
                id: MENU_ID.LOGIN,
                labelKey: "header.login",
                position: "right",
                url: URLS.auth.signIn(i18next.language),
                dialog: (open: boolean, setOpen: (bool: boolean) => void) => (
                    <SignInDialog open={open} setOpen={setOpen} />
                ),
            },
            {
                id: MENU_ID.TRY_NOW,
                labelKey: "header.tryItNow",
                position: "right",
                url: URLS.scenarios.base(),
                button: true,
            },
        ],
    };

    return (
        <div className={"no-print"}>
            <div className={styles.mobileContainerMenu}>
                <MobileMenuBtn menu={userStore.isLogged ? menu.user : menu.guest} />
            </div>
            <header className={clsx("mui-fixed", styles.wrapper)}>
                <Container>
                    <div className={clsx("flex_row_center", styles.container)}>
                        <div className={"flex-1"}>
                            <NoSsr fallback={<Nav menu={menu.guest} position={"left"} />}>
                                <Nav menu={userStore.isLogged ? menu.user : menu.guest} position={"left"} />
                            </NoSsr>
                        </div>
                        <div
                            id={"home"}
                            className={"cursor_pointer"}
                            onClick={() => history.push(URLS.home() + "/#home")}
                        >
                            <Logo />
                        </div>
                        {!userStore.isLogged && (
                            <Dialog
                                borderRadius={0}
                                maxWidth={"xl"}
                                fullWidth
                                className={"flex_center_center"}
                                open={openVideo}
                                onClose={() => setOpenVideo(false)}
                            >
                                <video autoPlay controls>
                                    <source
                                        src={
                                            "https://itsurlife.net/static/uploads/videos/" +
                                            i18next.language +
                                            "_demo.mp4"
                                        }
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            </Dialog>
                        )}
                        <div className={"flex-1"}>
                            <div className={"flex-1 flex_row_center justifyContent_flexEnd"}>
                                <div className={"mr_20"}>
                                    <div className={styles.separator}>
                                        <NoSsr fallback={<Nav menu={menu.guest} position={"right"} />}>
                                            <Nav
                                                menu={userStore.isLogged ? menu.user : menu.guest}
                                                position={"right"}
                                            />
                                        </NoSsr>
                                    </div>
                                </div>
                                <LangSelector />
                            </div>
                        </div>
                    </div>
                </Container>
            </header>
            <div className={styles.placeholder} />
        </div>
    );
}

export default observer(Header);
