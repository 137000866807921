import { action, observable } from "mobx";

export type TEditingEvents = { eventId: string; userId: string }[];

export class EventsStore {
    @observable editingEvents: TEditingEvents = [];

    @action
    setEventsOnEdit(editingEvents: TEditingEvents) {
        this.editingEvents = editingEvents;
    }
}

const eventsStore = new EventsStore();
export { eventsStore };
