import React, { useState } from "react";
import { useLoadingFromPromise } from "common/loaders/useLoadingFromPromise";
import { ImagePlaceholder } from "images/ImagePlaceholder";
import styles from "./_css/addImageBlock.module.css";
import { imagesStore } from "images/_stores/imagesStore";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { foldersStore } from "folders/_stores/foldersStore";
import { toast } from "react-toastify";
import sharedConfig from "_configs/sharedConfig";
import { convertToMb, getAllEntries, isValidFile, reformatFileEntries } from "common/_utils/fileUtils";
import { IFolderMdl } from "folders/_models/FolderMdl";
import { userStore } from "users/_stores/userStore";

const BATCH_SIZE = 10;

type TFolderWithPath = IFolderMdl & { fullPathName: string };
const updateChildFolders = async (folders: TFolderWithPath[], directoriesPathList: string[]) => {
    return Promise.all(
        directoriesPathList.map((path) => {
            const splittedPath = path.split("/");
            const parentFolderPath = splittedPath.slice(0, splittedPath.length - 1).join("/");
            const parentFolder = folders.find((folder) => folder.fullPathName === parentFolderPath);
            const folder = folders.find((folder) => folder.fullPathName === path);
            if (parentFolder && folder) {
                return foldersStore.updateFolder(folder._id, {
                    parentFolderId: parentFolder._id,
                });
            }
            return folder;
        }),
    );
};

export function AddImageBlock() {
    const { setPromise } = useLoadingFromPromise();
    const { scenarioId } = useParams();
    const { t } = useTranslation();
    const [uploadQueue, setUploadQueue] = useState(0);
    const [uploadCount, setUploadCount] = useState(0);

    if (!scenarioId) return null;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files || files.length === 0) return;

        if ([...files].some((file) => file.size > sharedConfig.maxUploadSize)) {
            toast.error(t("editor.maxSizeError", { maxSize: convertToMb(sharedConfig.maxUploadSize) }));
            return;
        }
        setPromise(
            imagesStore.createFromBlobs(Array.from(files), scenarioId, foldersStore.selectedFolderId).catch(() => {
                toast.error(t("editor.imagesUploadError"));
            }),
        );
    };

    const handleDrop = async (event: React.DragEvent<HTMLInputElement>) => {
        const folders: TFolderWithPath[] = [];

        try {
            event.preventDefault();
            const { directoryEntries, fileEntries } = await getAllEntries(event.dataTransfer.items);
            setUploadQueue(directoryEntries.length + fileEntries.length);
            for (let i = 0; i < directoryEntries.length; i += BATCH_SIZE) {
                const batch = directoryEntries.slice(i, i + BATCH_SIZE);
                await Promise.all(
                    batch.map(async (item) => {
                        return foldersStore
                            .createFolder({
                                name: item.name,
                                scenarioId,
                                userId: userStore.user?._id,
                            })
                            .then((folder) => {
                                setUploadCount((prev) => prev + 1);
                                folders.push({ ...folder, fullPathName: item.fullPath });
                                return folder;
                            });
                    }),
                );
            }
            const directoriesPathList = directoryEntries.map((item) => item.fullPath);
            await updateChildFolders(folders, directoriesPathList);

            const reformattedFiles = await reformatFileEntries(fileEntries);

            const ignoredFiles: File[] = [];

            for (let i = 0; i < reformattedFiles.length; i += BATCH_SIZE) {
                const batch = reformattedFiles.slice(i, i + BATCH_SIZE);

                await Promise.all(
                    batch.map(({ fileEntry, file }) => {
                        const parentFolderPath = fileEntry.fullPath.split("/").slice(0, -1).join("/");
                        const parentFolder = folders.find((folder) => folder.fullPathName === parentFolderPath);
                        if (!parentFolder) return;

                        if (!isValidFile(file)) {
                            ignoredFiles.push(file);
                            return;
                        }

                        return imagesStore
                            .createFromBlob(file, scenarioId, parentFolder._id)
                            .then(() => {
                                setUploadCount((prev) => prev + 1);
                            })
                            .catch(() => {
                                toast.error(t("editor.imagesUploadError"));
                            });
                    }),
                );
            }

            if (ignoredFiles.length > 0) {
                toast.info(t("editor.ignoredFiles", { count: ignoredFiles.length }));
            }

            setUploadQueue(0);
            setUploadCount(0);
        } catch (error) {
            console.log(error);
            toast.error(t("editor.foldersUploadError"));
            if (folders.length > 0) {
                await Promise.all<IFolderMdl | IFolderMdl[]>(
                    folders.map((folder) => foldersStore.deleteFolder(folder._id)),
                );
            }
        }
    };
    return (
        <div className={styles.container}>
            <input
                className={styles.input}
                type="file"
                multiple
                onDrop={handleDrop}
                onChange={handleChange}
                accept="image/png, image/gif, image/jpeg, image/svg+xml,"
            />
            <ImagePlaceholder
                className={styles.placeholder}
                text={t("editor.addImages")}
                queue={uploadQueue ? { current: uploadCount, total: uploadQueue } : undefined}
            />
        </div>
    );
}
