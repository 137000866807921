import React, { useState } from "react";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { i18nextInstance } from "common/i18n/IntlProvider";
import { MenuItem } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import arrowDown from "../../assets/icons/arrowDown.svg";
import styles from "./_css/lagSelector.module.css";

function changeLang(lang: TLang) {
    if (window.location.pathname === "/") {
        i18nextInstance.changeLanguage(lang);
    } else {
        const newPathname = "/" + lang + window.location.pathname.substr(3);
        window.location.href = window.location.origin + newPathname + window.location.search + window.location.hash;
    }
}

export function LangSelector() {
    const [isOpen, setIsOpen] = useState(null);

    return (
        <div>
            <div
                className={"p_20 cursor_pointer flex_row_center fontWeight_600"}
                onClick={(event) => setIsOpen(event.currentTarget)}
            >
                <div className="mr_10">{i18nextInstance.language === "fr" ? "FR" : "EN"} </div>
                <img src={arrowDown} alt={"Arrow down"} title={"Arrow down"} />
            </div>
            <Menu open={!!isOpen} anchorEl={isOpen} onClose={() => setIsOpen(null)} className={styles.content}>
                {Object.entries(sharedConfig.languages).map(([lang, { title }]) => (
                    <MenuItem
                        onClick={() => {
                            setIsOpen(null);
                            changeLang(lang as TLang);
                        }}
                        key={lang}
                        value={lang}
                        className={styles.item}
                    >
                        <em className={styles.text}>{title}</em>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
