import React from "react";
import styles from "./_css/inputBlock.module.css";

type Props = {
    label: string;
    text?: string;
    required?: boolean;
    className?: string;
    inputClassName?: string;
    labelStyle?: string;
};

export function InputBlock(props: React.PropsWithChildren<Props>) {
    return (
        <div className={props.className}>
            <div className={props.labelStyle ?? styles.label}>
                {props.label}
                {props.required && <span className={styles.required}>*</span>}
            </div>
            {props.text && <div className={styles.text}>{props.text}</div>}
            <div className={props.inputClassName ?? "mb_5"}>{props.children}</div>
        </div>
    );
}
