import React, { Props, PropsWithoutRef } from "react";
import { SECTION_LIBRARY, sectionStore, TRatio, TRatioType } from "scenarios/sections/_stores/sectionStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import clsx from "clsx";
import styles from "./_css/editorForm.module.css";
import { Checkbox, FormControlLabel, FormGroup, MenuItem } from "@material-ui/core";
import { UiSelect } from "common/ui/mui/forms/UiSelect";
import { useTranslation } from "react-i18next";
import { GalleryEditor } from "scenarios/sections/editor/GalleryEditor";
import { IImageMdl } from "images/_models/ImageMdl";
import { VideoSectionEditor } from "scenarios/sections/editor/VideoSectionEditor";

const CheckboxEditor = (props: Props<any>) => {
    return (
        <div className={styles.checkbox}>
            <FormGroup>
                <FormControlLabel
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.onCheck();
                    }}
                    control={<Checkbox checked={props.checked} />}
                    label={props.label}
                />
            </FormGroup>
        </div>
    );
};

const EnumEditor = (props: PropsWithoutRef<{ values: [TRatio]; value: string; onClick: (value: string) => void }>) => {
    const { t } = useTranslation();
    return (
        <div className={styles.textSideBar}>
            <UiSelect value={props.value} onChange={(e) => props.onClick(e.target.value)}>
                {props.values.map((value) => {
                    return (
                        <MenuItem style={{ fontSize: "20px" }} value={value.id} key={value.id}>
                            {t(value.labelKey)}
                        </MenuItem>
                    );
                })}
            </UiSelect>
        </div>
    );
};

export const EditorForm = observer(() => {
    const section = sectionStore.editingSection;
    if (!section) return null;
    const librarySection = SECTION_LIBRARY[section.id];
    let Form: (props: React.Props<any>) => JSX.Element;
    if (!librarySection?.def?.formDef) return null;
    if (Object.entries(librarySection?.def?.formDef).length === 0) return null;
    return Object.entries(librarySection?.def?.formDef).map((field) => {
        let props = JSON.parse(JSON.stringify(toJS(section.props)));
        if (field[1]) {
            if (field[1] === "image") {
                props = {
                    ...props,
                    onDropImages: (uploadedImages: IImageMdl[]) => {
                        if (section.props?.[field[0]]) {
                            const images = uploadedImages.map((image) => {
                                return {
                                    imageUrl: image.url,
                                    height: image.height,
                                    width: image.width,
                                };
                            });
                            section.props[field[0]] = section.props[field[0]].concat(images);
                        }
                    },
                };
                Form = GalleryEditor;
            } else if (typeof field[1] === "object" && "enum" in field[1]) {
                props = {
                    ...props,
                    value: props[field[0]],
                    values: (field[1] as TRatioType).enum,
                    onClick: (value: string) => {
                        if (section.props?.[field[0]]) section.props[field[0]] = value;
                    },
                };
                Form = EnumEditor;
            } else if (field[1] === "gallery") {
                props = {
                    ...props,
                    onDropImages: (uploadedImages: IImageMdl[]) => {
                        if (section.props?.[field[0]]) {
                            const images = uploadedImages.map((image) => {
                                return {
                                    imageUrl: image.url,
                                    height: image.height,
                                    width: image.width,
                                };
                            });
                            section.props[field[0]] = section.props[field[0]].concat(images);
                        }
                    },
                };
                Form = GalleryEditor;
            } else if (field[1] === "boolean") {
                props = {
                    ...props,
                    label: field?.[0] ? librarySection.def.labels[field[0]] : "",
                    checked: section.props?.[field[0]] ? section.props[field[0]] : false,
                    onCheck: () => {
                        if (typeof section.props?.[field[0]] === "boolean") {
                            section.props[field[0]] = !section.props[field[0]];
                        }
                    },
                };
                Form = CheckboxEditor;
            } else if (field[1] === "video") {
                props = {
                    ...props,
                };
                Form = VideoSectionEditor;
            }
        }
        if (!Form) return null;
        return (
            <div className={clsx("flex-1 pb_20", styles.container)} key={field[0]}>
                <h3 className={clsx("pb_10", styles.title)}>{field?.[0] ? librarySection.def.labels[field[0]] : ""}</h3>
                <Form {...props} />
            </div>
        );
    });
});
