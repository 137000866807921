import { useEffect, useState } from "react";
import _ from "lodash";

export function useWindowSize(throttle?: number) {
    const [windowSize, setWindowSize] = useState({ width: -1, height: -1 });
    useEffect(() => {
        if (__BROWSER__) {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            const listener = _.throttle(
                () =>
                    setWindowSize({
                        width: window.innerWidth,
                        height: window.innerHeight,
                    }),
                throttle ?? 100,
            );
            window.addEventListener("resize", listener);
            return () => window.removeEventListener("resize", listener);
        }
    }, []);
    return windowSize;
}

export function useIsFirstRender() {
    const [isFirstRender, setIsFirstRender] = useState(true);
    useEffect(() => {
        setIsFirstRender(false);
    }, []);

    return isFirstRender;
}

export function useWindowWidthAfterFirstRender() {
    const windowSize = useWindowSize();
    const isFirstRender = useIsFirstRender();
    const [windowWidth, setWindowWidth] = useState(windowSize.width);

    useEffect(() => {
        if (!isFirstRender) setWindowWidth(windowSize.width);
    }, [isFirstRender]);

    return windowWidth;
}
