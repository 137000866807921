import React from "react";
import styles from "../_css/footer.module.css";
import { Logo } from "../../../Logo";
import { URLS } from "_configs/URLS";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export function Footer() {
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <div className={styles.content}>
            <div className={"cursor_pointer"} onClick={() => history.push(URLS.home())}>
                <Logo width={56} />
            </div>
            <div className={"flex_row flex_row_center gap_10"}>
                <span>@ItsUrLife {dayjs().format("YYYY")}.</span>
                <div>
                    <a href={t("termsOfUse.url")}>{t("termsOfUse.title")}</a>.
                </div>
                <div>
                    <a href={t("privacyPolicy.url")}>{t("privacyPolicy.title")}</a>.
                </div>
            </div>
        </div>
    );
}
