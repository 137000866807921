import React from "react";
import styles from "./_css/breadcrumbFolder.module.css";
import clsx from "clsx";
import { foldersStore } from "folders/_stores/foldersStore";
import { IFolderMdl } from "folders/_models/FolderMdl";

export function BreadcrumbFolder() {
    const parentsFolder: IFolderMdl[] = [];

    if (foldersStore.selectedFolder && foldersStore.selectedFolder.parentFolderId) {
        const parent = foldersStore.folders.find(
            (folder) => folder._id === foldersStore.selectedFolder?.parentFolderId,
        );
        if (parent) parentsFolder.push(parent);
        parentsFolder.map((_folder) => {
            if (!_folder.parentFolderId) return null;
            const tmp = foldersStore.folders.find((__folder) => _folder.parentFolderId == __folder._id);
            if (tmp) {
                parentsFolder.push(tmp);
            }
        });
    }

    return (
        <div className={clsx(styles.container, "flex_row_center")}>
            <ul className={clsx(styles.list)}>
                {foldersStore.selectedFolder && (
                    <li className="flex_center_center">
                        <div className={styles.item} onClick={() => foldersStore.setSelectedFolder(undefined)}>
                            Galerie
                        </div>
                        <div className={styles.icon}> {">"} </div>
                    </li>
                )}
                {parentsFolder.length != 0 &&
                    parentsFolder.reverse().map((item, idx) => {
                        return (
                            <>
                                <li
                                    onClick={() => foldersStore.setSelectedFolder(item._id)}
                                    className={clsx(styles.item, "flex_row_center")}
                                    key={idx}
                                >
                                    {item.name}
                                </li>
                                <div className={styles.icon}> {">"} </div>
                            </>
                        );
                    })}
                <li className={styles.currentItem}>
                    {foldersStore.selectedFolder ? foldersStore.selectedFolder.name : "Galerie"}
                </li>
            </ul>
        </div>
    );
}
