import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { UiButton } from "common/ui/mui/buttons/UiButton";

type Props = {
    disabled?: boolean;
    onlyImage?: boolean;
    placeholder?: string;
};

export function FilePlaceholder(props: PropsWithChildren<Props>) {
    const { t } = useTranslation();
    return (
        <div className="p_20">
            {props.children}
            {props.disabled ? (
                <div>{t(props.onlyImage ? "files.image.empty" : "files.empty")}</div>
            ) : (
                <div className="flex_column justifyContent_center alignItems_center">
                    {props.onlyImage ? (
                        <div className="flex_column justifyContent_center alignItems_center">
                            <div>
                                <UiButton color={"primary"} variant={"outlined"}>
                                    {t(props.onlyImage ? "files.image.upload" : "files.upload")}
                                </UiButton>
                            </div>
                            <div className={"mt_10"}>.jpg, .png</div>
                            <div>(512x512 minimum)</div>
                        </div>
                    ) : (
                        <div>{props.placeholder ?? t(props.onlyImage ? "files.image.upload" : "files.upload")}</div>
                    )}
                </div>
            )}
        </div>
    );
}
