import React from "react";
import "../../../_css/fonts/font.css";
import { HeaderHomePage } from "../home/section/HeaderHomePage";
import { YourMemories } from "main/pages/home/section/YourMemories";
import { Options } from "main/pages/home/section/Options";
import { HowWork } from "main/pages/home/section/HowWork";
import { OurMission } from "main/pages/home/section/OurMission";
import { Footer } from "main/pages/home/section/Footer";
import styles from "./_css/home.module.css";
import clsx from "clsx";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { socketStore } from "scenarios/socket/_stores/socketStore";

function Home() {
    const history = useHistory();
    const { t } = useTranslation();
    if (history.location.search.split("?")[1] === "error") {
        toast.error(t("errors.scenarioView"), { position: "top-center", autoClose: false });
    }
    socketStore.init();
    return (
        <div className={styles.contentHome}>
            <HeaderHomePage />
            <YourMemories />
            <div className={clsx(styles.backgroundCircle)}>
                <Options />
                <HowWork />
            </div>
            <OurMission />
            <Footer />
        </div>
    );
}

export default Home;
