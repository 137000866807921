import React from "react";
import { Helmet } from "react-helmet-async";
import { Route, Switch } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { AskResetPasswordBlock } from "users/auth/blocks/AskResetPasswordBloc";
import { ResetPasswordBlock } from "users/auth/blocks/ResetPasswordBlock";
import { SignUpPage } from "users/auth/SignUpPage";
import { SignInPage } from "users/auth/SignInPage";

export function AuthPage() {
    return (
        <div className="flex_center_center pt_10">
            <Helmet>
                <meta name="robots" content="NOINDEX" />
            </Helmet>
            <Switch>
                <Route path={URLS.auth.askResetPassword(":lang")} component={AskResetPasswordBlock} />
                <Route path={URLS.auth.resetPassword(":lang")} component={ResetPasswordBlock} />
                <Route path={URLS.auth.signUp(":lang")} component={SignUpPage} />
                <Route path={URLS.auth.signIn(":lang")} component={SignInPage} />
            </Switch>
        </div>
    );
}
