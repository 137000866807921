import { TextSection } from "scenarios/sections/TextSection";
import { TComponent, TPropsValues } from "scenarios/_models/EventMdl";
import { GallerySection } from "scenarios/sections/GallerySection";
import { TextImageSection } from "../TextImageSection";
import gallery from "../../../assets/img/sections/gallery.svg";
import text from "../../../assets/img/sections/text.svg";
import text_image from "../../../assets/img/sections/text_image.svg";
import image from "../../../assets/img/sections/image.svg";
import video from "../../../assets/img/sections/video-icon.svg";
import { action, observable } from "mobx";
import { TextSectionEditor } from "scenarios/sections/editor/TextSectionEditor";
import { TextImageSectionEditor } from "scenarios/sections/editor/TextImageSectionEditor";
import { VideoSection } from "scenarios/sections/VideoSection";
import { s4 } from "common/_utils/coreUtils";

export const PLACEHOLDER_IMAGE =
    "https://itsurlife.net/static/uploads/itsurlife/placeholde-1679413648064-62ac750149b6e602919c0a63.webp";

export enum SECTION_TYPE {
    TEXT = "TEXT",
    TEXT_IMAGE = "TEXT_IMAGE",
    IMAGE = "IMAGE",
    GALLERY = "GALLERY",
    VIDEO = "VIDEO",
    // BREAK_PAGE = "BREAK_PAGE",
}

export type TRatio = { id: string; labelKey: string };
export type TRatioType = { enum: TRatio[] };

export const SECTION_TYPES_ARRAY = Object.values(SECTION_TYPE).map((sectionType) => {
    let icon, type, labelKey;
    switch (sectionType) {
        case SECTION_TYPE.GALLERY:
            type = SECTION_TYPE.GALLERY;
            icon = gallery;
            labelKey = "sectionComponent.gallery";
            break;
        case SECTION_TYPE.TEXT:
            type = SECTION_TYPE.TEXT;
            icon = text;
            labelKey = "sectionComponent.text";
            break;
        case SECTION_TYPE.TEXT_IMAGE:
            type = SECTION_TYPE.TEXT_IMAGE;
            icon = text_image;
            labelKey = "sectionComponent.text_image";
            break;
        case SECTION_TYPE.IMAGE:
            type = SECTION_TYPE.IMAGE;
            icon = image;
            labelKey = "sectionComponent.image";
            break;
        case SECTION_TYPE.VIDEO:
            type = SECTION_TYPE.VIDEO;
            icon = video;
            labelKey = "sectionComponent.video";
            break;
    }
    return { type, icon, labelKey };
});

export const SECTION_LIBRARY: {
    [key: string]: {
        def: { id: SECTION_TYPE; props: TPropsValues; formDef?: any; labels?: any };
        component: (props: any) => JSX.Element | null;
        editor: (props: any) => JSX.Element | null;
    };
} = {
    [SECTION_TYPE.IMAGE]: {
        def: {
            id: SECTION_TYPE.GALLERY,
            formDef: {
                images: "gallery",
            },
            labels: {
                images: "Image",
            },
            props: {
                images: [
                    {
                        imageUrl:
                            "https://itsurlife.net/static/uploads/itsurlife/placeholde-1679413648064-62ac750149b6e602919c0a63.webp",
                        height: 350,
                        width: 350,
                        _id: s4(),
                    },
                ],
                nbColumns: 1,
            },
        },
        component: GallerySection,
        editor: GallerySection,
    },
    [SECTION_TYPE.TEXT]: {
        def: {
            id: SECTION_TYPE.TEXT,
            formDef: {
                textSize: {
                    enum: [
                        { id: "small", labelKey: "editor.textSize.small" },
                        { id: "medium", labelKey: "editor.textSize.medium" },
                        { id: "large", labelKey: "editor.textSize.large" },
                    ],
                },
            },
            props: {
                text:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, " +
                    "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. " +
                    "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. " +
                    "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. " +
                    "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                textSize: "small",
            },
            labels: {
                text: "Description",
                textSize: "Taille du texte",
            },
        },
        component: TextSection, // (props) => TextSection({ ...props, styles: { ...props.styles, padding: 20 } }),
        editor: TextSectionEditor,
    },
    [SECTION_TYPE.TEXT_IMAGE]: {
        def: {
            id: SECTION_TYPE.TEXT_IMAGE,
            formDef: {
                imageUrl: "image",
                ratio: {
                    enum: [
                        { id: "half", labelKey: "editor.ratio.half" },
                        { id: "smallBig", labelKey: "editor.ratio.smallBig" },
                        { id: "bigSmall", labelKey: "editor.ratio.bigSmall" },
                        { id: "onlyOneColumn", labelKey: "editor.ratio.onlyOneColumn" },
                    ],
                },
                reverse: "boolean",
                textSize: {
                    enum: [
                        { id: "small", labelKey: "editor.textSize.small" },
                        { id: "medium", labelKey: "editor.textSize.medium" },
                        { id: "large", labelKey: "editor.textSize.large" },
                    ],
                },
            },
            labels: {
                imageUrl: "Image",
                ratio: "Présentation des colonnes",
                reverse: "Inverser les colonnes",
                textSize: "Taille du texte",
            },
            props: {
                text:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, " +
                    "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. " +
                    "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. " +
                    "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. " +
                    "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                imageUrl: "https://via.placeholder.com/350x150",
                ratio: "smallBig",
                reverse: false,
                textSize: "small",
            },
        },
        component: TextImageSection,
        editor: TextImageSectionEditor,
    },
    [SECTION_TYPE.GALLERY]: {
        def: {
            id: SECTION_TYPE.GALLERY,
            formDef: {
                images: "gallery",
            },
            labels: {
                images: "Image",
            },
            props: {
                images: [
                    {
                        imageUrl:
                            "https://itsurlife.net/static/uploads/itsurlife/placeholde-1679413648064-62ac750149b6e602919c0a63.webp",
                        height: 350,
                        width: 350,
                        _id: s4(),
                    },
                ],
                nbColumns: 3,
            },
        },
        component: GallerySection,
        editor: GallerySection,
    },
    [SECTION_TYPE.VIDEO]: {
        def: {
            id: SECTION_TYPE.VIDEO,
            formDef: {
                video: "video",
            },
            labels: {
                video: "Vidéo",
            },
            props: {
                video: "https://youtu.be/ScMzIvxBSi4",
            },
        },
        component: VideoSection,
        editor: VideoSection,
    },
};

class SectionStore {
    @observable editingSection: TComponent | undefined;

    @action setEditingSection(section: TComponent | undefined) {
        this.editingSection = section;
    }

    create(type: SECTION_TYPE, props?: Partial<TPropsValues>) {
        return { ...SECTION_LIBRARY[type].def, props: { ...SECTION_LIBRARY[type].def.props, ...props } };
    }
}

export const sectionStore = new SectionStore();
