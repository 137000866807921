import { i18nextInstance } from "common/i18n/IntlProvider";

export const URLS = {
    home: (lang?: string) => `/${lang || i18nextInstance.language}`,
    profile: (lang?: string) => `/${lang || i18nextInstance.language}/profil`,
    contact: (lang?: string) => `/${lang || i18nextInstance.language}/contact`,

    auth: {
        base: (lang?: string) => `/${lang || i18nextInstance.language}/auth`,
        signIn: (lang?: string) => `/${lang || i18nextInstance.language}/auth/signIn`,
        signUp: (lang?: string) => `/${lang || i18nextInstance.language}/auth/signUp`,
        askResetPassword: (lang?: string) => `/${lang || i18nextInstance.language}/auth/askResetPassword`,
        resetPassword: (lang?: string) => `/${lang || i18nextInstance.language}/auth/resetPassword`,
    },

    scenarios: {
        base: (lang?: string) => `/${lang || i18nextInstance.language}/stories`,
        draft: (scenarioId: string, lang?: string) =>
            `/${lang || i18nextInstance.language}/stories/draft/${scenarioId}`,
        scenario: (scenarioId: string, lang?: string) => `/${lang || i18nextInstance.language}/stories/${scenarioId}`,
        view: (scenarioToken: string) => `/view/${scenarioToken}`,
    },
};
