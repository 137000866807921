import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLoadingFromPromise } from "common/loaders/useLoadingFromPromise";
import { authStore } from "users/auth/_stores/authStore";
import { InputBlock } from "common/ui/forms/InputBlock";
import { Input } from "common/ui/forms/Input";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import styles from "users/auth/_css/signUp.module.css";
import clsx from "clsx";
import { Dialog } from "common/ui/dialogs/Dialog";
import { DialogContent } from "@material-ui/core";
import logoWithoutBrand from "../../../assets/img/logoWithoutBrand.svg";
import { ForgotPasswordValidationDialog } from "users/auth/dialog/ForgotPasswordValidationDialog";

type Props = {
    open: boolean;
    onOpen: (bool: boolean) => void;
};

type TFormData = {
    email: string;
};

export function ForgotPasswordDialog(props: Props) {
    const { t } = useTranslation();
    const [isSent, setIsSent] = useState(false);
    const [openValidation, setOpenValidation] = useState(false);

    const { handleSubmit, register, errors } = useForm<TFormData>({ mode: "onBlur" });
    const { loading, setPromise } = useLoadingFromPromise();
    const onSubmit = (values: TFormData) => {
        const promise = authStore.askResetPassword(values.email);
        setPromise(promise);
        promise.then(() => setIsSent(true));
        setOpenValidation(true);
    };
    const isLoading = loading?.status === "LOADING";
    return (
        <div>
            <Dialog open={props.open} onClose={() => props.onOpen(false)} className={styles.dialog} padding={30}>
                <DialogContent className={"pt_0 pb_0"}>
                    <div className={"flex_column justifyContent_center alignItems_center"}>
                        <img
                            className={clsx("mt_50 mb_25", styles.image)}
                            src={logoWithoutBrand}
                            alt={"logo It's ur life"}
                        />
                        <h3 className={clsx("mb_5", styles.title)}>{t("forgot.title")}</h3>
                        <div className={clsx("mb_50", styles.text)}>{t("forgot.text")}</div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputBlock
                            labelStyle={styles.label}
                            label={t("forgot.form.labelEmail")}
                            className={clsx("mb_60", styles.inputBlock)}
                        >
                            <Input
                                placeholder={t("forgot.form.placeholderEmail")}
                                className={clsx(styles.input)}
                                name="email"
                                ref={register({
                                    required: t("errors.forms.required"),
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: t("errors.forms.emailInvalid"),
                                    },
                                })}
                                disabled={isLoading}
                            />
                        </InputBlock>
                        <div className="flex_column mb_15">
                            <UiButton
                                className={"mb_15"}
                                type="submit"
                                disabled={isLoading || isSent}
                                variant="contained"
                                color="primary"
                            >
                                {t(isLoading ? "loaders.message" : "forgot.reset")}
                            </UiButton>
                            <UiButton color={"primary"} variant={"outlined"} onClick={() => props.onOpen(false)}>
                                {t("forgot.back")}
                            </UiButton>
                        </div>
                    </form>
                </DialogContent>
                <ForgotPasswordValidationDialog
                    open={openValidation}
                    setOpen={setOpenValidation}
                    setOpenForgot={props.onOpen}
                />
            </Dialog>
        </div>
    );
}
