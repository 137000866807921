import React, { useEffect, useState } from "react";
import styles from "./_css/scenarioSideBar.module.css";
import clsx from "clsx";
import closeSidebar from "../assets/icons/closeSidebar.svg";
import { observer } from "mobx-react";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { InputFrontBlock } from "common/ui/forms/InputFrontBlock";
import { InputFront } from "common/ui/forms/InputFront";
import { TextAreaFront } from "common/ui/forms/TextAreaFront";
import { SingleFilePicker } from "common/ui/forms/images/SingleFilePicker";
import _ from "lodash";
import { DateInput } from "scenarios/inputs/DateInput";
import { userStore } from "users/_stores/userStore";
import { socketStore } from "scenarios/socket/_stores/socketStore";
import { useWindowWidthAfterFirstRender } from "common/_utils/hookUtils";
import { TEXT_SIZES, TScenarioMdl } from "scenarios/_models/ScenarioMdl";
import { UiSelect } from "common/ui/mui/forms/UiSelect";
import { MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Props = {
    className?: string;
};

const FIRST_RENDER_REF = { current: true };

export const ScenarioSideBar = observer((props: Props) => {
    const { t } = useTranslation();
    const scenarioStore = useScenarioStore();
    const [display, setDisplay] = useState(scenarioStore.sidebarOpened);
    const [summaryInfo, setSummaryInfo] = useState<
        Pick<TScenarioMdl, "title" | "sumUp" | "sumUpSize" | "coverImg" | "birthday" | "deathDate">
    >(_.pick(scenarioStore.scenario, ["title", "sumUp", "sumUpSize", "coverImg", "birthday", "deathDate"]));
    const windowWidth = useWindowWidthAfterFirstRender();
    useEffect(() => {
        if (windowWidth < 960) scenarioStore.setSidebar(false);
        else {
            scenarioStore.setSidebar(
                localStorage.getItem("sidebarOpened")
                    ? JSON.parse(localStorage.getItem("sidebarOpened") as string)
                    : true,
            );
        }
    }, [windowWidth]);

    const isOwner = scenarioStore.scenario.user === userStore.user?._id;

    const debounceSaveFn = _.debounce(() => {
        if (!scenarioStore.eventId) {
            scenarioStore.updateScenario({ ...summaryInfo });
            socketStore.updateScenario(summaryInfo, scenarioStore.scenario._id);
        }
    }, 800);

    useEffect(() => {
        if (!FIRST_RENDER_REF.current) {
            if (isOwner) debounceSaveFn();
        }
        FIRST_RENDER_REF.current = false;
    }, [summaryInfo]);

    useEffect(() => {
        if (
            JSON.stringify(_.pick(scenarioStore.scenario, ["title", "sumUp", "coverImg", "birthday", "deathDate"])) !=
            JSON.stringify(summaryInfo)
        ) {
            setSummaryInfo(_.pick(scenarioStore.scenario, ["title", "sumUp", "coverImg", "birthday", "deathDate"]));
        }
    }, [scenarioStore.scenario]);

    useEffect(() => {
        if (!scenarioStore.sidebarOpened) {
            setTimeout(() => {
                setDisplay(scenarioStore.sidebarOpened);
            }, 600);
        } else {
            setDisplay(true);
        }
    }, [scenarioStore.sidebarOpened]);

    return (
        <div
            className={clsx(styles.sidebar, "position_relative", {
                [styles.close]: !scenarioStore.sidebarOpened,
            })}
        >
            <div
                className={clsx(styles.closeBtn, "position_absolute cursor_pointer")}
                onClick={() => scenarioStore.toggleSidebar()}
            >
                <img className={styles.closeImg} src={closeSidebar} alt={"close icon"} />
            </div>
            <div
                className={clsx(styles.container, "overflow_auto p_20", props.className, {
                    [styles.close]: !scenarioStore.sidebarOpened,
                })}
            >
                {display && (
                    <>
                        <div className={"mt_20"}>
                            <InputFrontBlock theme={"noBorder"} label={t("scenarioPage.sideBar.title")}>
                                <InputFront
                                    disabled={!isOwner}
                                    value={summaryInfo.title}
                                    placeholder={t("scenarioPage.sideBar.placeholder.title")}
                                    onChange={(e) =>
                                        setSummaryInfo({ ...scenarioStore.scenario, title: e.target.value })
                                    }
                                />
                            </InputFrontBlock>
                        </div>
                        <div className={"mt_10"}>
                            <SingleFilePicker
                                scenarioId={scenarioStore.scenario._id}
                                disabled={!isOwner}
                                onlyImage
                                onChange={(fileUrl) => setSummaryInfo({ ...scenarioStore.scenario, coverImg: fileUrl })}
                                value={summaryInfo.coverImg}
                            />
                        </div>
                        <div className={"mt_10"}>
                            <InputFrontBlock theme={"noBorder"} label={t("scenarioPage.sideBar.summary")}>
                                <TextAreaFront
                                    disabled={!isOwner}
                                    rows={6}
                                    value={summaryInfo.sumUp}
                                    onValueChange={(text) => setSummaryInfo({ ...scenarioStore.scenario, sumUp: text })}
                                    placeholder={t("scenarioPage.sideBar.placeholder.sumUp")}
                                    className={clsx({
                                        scenario_text_small: summaryInfo.sumUpSize === TEXT_SIZES.SMALL,
                                        scenario_text_medium:
                                            summaryInfo.sumUpSize === TEXT_SIZES.MEDIUM || !summaryInfo.sumUpSize,
                                        scenario_text_large: summaryInfo.sumUpSize === TEXT_SIZES.LARGE,
                                    })}
                                />
                                <UiSelect
                                    value={
                                        summaryInfo.sumUpSize ?? scenarioStore.scenario.sumUpSize ?? TEXT_SIZES.MEDIUM
                                    }
                                    displayEmpty
                                    className={"mt_5"}
                                    onChange={(e) => {
                                        setSummaryInfo({
                                            ...scenarioStore.scenario,
                                            sumUpSize: e.target.value as TEXT_SIZES,
                                        });
                                    }}
                                >
                                    {Object.values(TEXT_SIZES).map((size) => (
                                        <MenuItem key={size} value={size}>
                                            {t(`editor.textSize.${size}`)}
                                        </MenuItem>
                                    ))}
                                </UiSelect>
                            </InputFrontBlock>
                        </div>
                        <div className={"mt_10"}>
                            <DateInput
                                disabled={!isOwner}
                                label={t("scenarioPage.sideBar.start")}
                                date={summaryInfo.birthday}
                                onSave={(date) => setSummaryInfo((prevState) => ({ ...prevState, birthday: date }))}
                            />
                        </div>
                        <div className={"mt_10"}>
                            <DateInput
                                className={styles.lastInput}
                                disabled={!isOwner}
                                isUndefinedAllowed
                                label={t("scenarioPage.sideBar.end")}
                                date={summaryInfo.deathDate}
                                onSave={(date) => setSummaryInfo((prevState) => ({ ...prevState, deathDate: date }))}
                            />
                        </div>
                    </>
                )}
            </div>
            <div
                className={clsx(styles.placeholder, {
                    [styles.close]: !scenarioStore.sidebarOpened,
                })}
            />
        </div>
    );
});
