import React from "react";
import clsx from "clsx";
import { ImageSection } from "scenarios/sections/ImageSection";
import { TextSection } from "./TextSection";

type Props = {
    imageUrl: string;
    text: string;
    ratio: "half" | "smallBig" | "bigSmall" | "onlyOneColumn";
    reverse: boolean;
    id: string;
    isEditable?: boolean;
    onEdit?: () => void;
    textSize: "small" | "medium" | "large";
};

export function TextImageSection(props: Props) {
    return (
        <div
            onClick={(e) => {
                if (props.isEditable) {
                    e.preventDefault();
                    e.stopPropagation();
                    props.onEdit ? props.onEdit() : undefined;
                }
            }}
            className={"flex_row"}
        >
            <div
                className={clsx({
                    ["flex-2 mr_20"]: props.ratio === "bigSmall",
                    ["flex-1 mr_20"]:
                        props.ratio === "smallBig" || props.ratio === "half" || props.ratio === "onlyOneColumn",
                })}
            >
                {props.reverse ? (
                    <ImageSection imageUrl={props.imageUrl} />
                ) : (
                    <TextSection text={props.text} textSize={props.textSize} />
                )}
            </div>
            <div
                className={clsx({
                    ["flex-2"]: props.ratio === "smallBig",
                    ["flex-1"]: props.ratio === "bigSmall" || props.ratio === "half",
                    ["display_none"]: props.ratio === "onlyOneColumn",
                })}
            >
                {props.reverse ? (
                    <TextSection text={props.text} textSize={props.textSize} />
                ) : (
                    <ImageSection imageUrl={props.imageUrl} />
                )}
            </div>
        </div>
    );
}
