import jwt_decode from 'jwt-decode';
import { action, observable } from 'mobx';
import { appConfig } from '_configs/appConfig';
import { fetchUtils } from 'common/_utils/fetchUtils';
import sharedConfig from '_configs/sharedConfig';

function getTokenExpirationDate(token: string) {
    const decoded = jwt_decode<any>(token);
    if (decoded.exp === undefined) {
        return undefined;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
}

function getToken() {
    try {
        const token = localStorage.getItem(appConfig.token.storageKey);
        if (!token) {
            return undefined;
        }
        const date = getTokenExpirationDate(token);
        if (date === undefined || date.valueOf() < new Date().valueOf()) {
            return undefined;
        }
        return token;
    } catch (e) {
    }
    return undefined;
}

class TokenStore {

    @observable token = getToken();
    setToken = action((token: string | undefined) => {
        this.token = token;
        try {
            if (token) localStorage.setItem(appConfig.token.storageKey, token);
            else localStorage.removeItem(appConfig.token.storageKey);
        } catch (e) {
            //nothing to do
        }
    });

    constructor() {
        this.refreshToken();
    }

    refreshToken() {
        if (this.token) {
            return fetchUtils.post<string>(sharedConfig.apiUrl + '/auth/token').then(
                ({ data }) => this.setToken(data),
                (error) => {
                    if (error.key && error.key.startsWith('errors.api.')) {
                        this.setToken(undefined);
                    }
                },
            );
        }
    }

    getTokenData<TTokenData>() {
        return this.token ? jwt_decode<{ data: TTokenData }>(this.token).data : undefined;
    }
}

export const tokenStore = new TokenStore();
