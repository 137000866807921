import React, { useState } from "react";
import { Dialog } from "common/ui/dialogs/Dialog";
import styles from "users/auth/_css/signUpInvitation.module.css";
import { DialogContent } from "@material-ui/core";
import logoWithoutBrand from "../../../assets/img/logoWithoutBrand.svg";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { SignUpDialog } from "users/auth/dialog/SignUpDialog";

type Props = {
    open: boolean;
    setOpen: (bool: boolean) => void;
};

export function ValidationNotSignUpDialog(props: Props) {
    const { t } = useTranslation();
    const [openSignUp, setOpenSignUp] = useState(false);
    return (
        <div>
            <Dialog open={props.open} onClose={() => props.setOpen(false)} className={styles.dialog}>
                <DialogContent className={"mb_40"}>
                    <div className={"flex_column justifyContent_center alignItems_center"}>
                        <img className={styles.image} src={logoWithoutBrand} alt={"logo It's ur life"} />
                        <h4 className={styles.title}>{t("validationNotSignUp.title")}</h4>
                        <div className={styles.text}>{t("validationNotSignUp.text")}</div>
                    </div>
                </DialogContent>
                <div className={"flex_column alignItems_center justifyContent_center"}>
                    <UiButton onClick={() => props.setOpen(false)} className={clsx("mb_10", styles.btn)}>
                        {t("validationNotSignUp.dontCreate")}
                    </UiButton>
                    <UiButton
                        onClick={() => {
                            setOpenSignUp(true);
                            props.setOpen(false);
                        }}
                        className={styles.btn}
                    >
                        {t("validationNotSignUp.create")}
                    </UiButton>
                </div>
            </Dialog>
            <SignUpDialog open={openSignUp} setOpen={setOpenSignUp} />
        </div>
    );
}
