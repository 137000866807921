const isDev = process.env.NODE_ENV === "development";
const isStaging = process.env.IS_STAGING === "true";
const isProd = process.env.NODE_ENV === "production" && !isStaging;

const languages = {
    fr: {
        lang: "fr",
        baseUrl: `${process.env.APP_URL}/fr`,
        title: "Français",
    },
    en: {
        lang: "en",
        baseUrl: `${process.env.APP_URL}/en`,
        title: "English",
    },
};

export type TLang = keyof typeof languages;

const sharedConfig = {
    env: process.env.NODE_ENV,
    isDev,
    isStaging,
    isProd,

    emailName: "It's ur life",
    email: "itsurlife@lesentrecodeurs.com",

    defaultLang: "en" as TLang, // langue par défaut, utilisé si des textes ne sont pas présent dans une autre langue
    languages,

    apiUrl: process.env.API_URL,
    appUrl: process.env.APP_URL,

    uploadsBaseUrl: "/static/uploads/itsurlife",

    maxFiles: 10,
    maxUploadSize: 15000000,
};

export default sharedConfig;
