import React, { useEffect, useState } from "react";

import { useScenarioStore } from "scenarios/ScenarioContext";
import styles from "./_css/sharedHistoryDialog.module.css";
import { useTranslation } from "react-i18next";
import sharedConfig from "_configs/sharedConfig";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { Toggle } from "common/ui/forms/Toggle";
import { observer } from "mobx-react";
import clsx from "clsx";
import { Input } from "common/ui/forms/Input";
import { FileCopy } from "@material-ui/icons";

export const SharedHistoryDialog = observer(() => {
    const scenarioStore = useScenarioStore();
    const { t } = useTranslation();
    const [hover, setHover] = useState(false);

    useEffect(() => {
        if (!scenarioStore.scenario.token) {
            scenarioStore.refreshToken();
        }
    }, [scenarioStore.scenario]);

    return (
        <div className={styles.container}>
            <div className={clsx(styles.title, "pt_10 pb_10")}> {t("scenarioPage.sharedHistory")}</div>
            <div className={clsx(styles.text, "p_5")}> {t("scenarioPage.sharedText")}</div>

            {hover && <div className={styles.tooltip}> {t("scenarioPage.copyPath")} </div>}
            <Input
                value={sharedConfig.appUrl + "/view/" + scenarioStore.scenario.token}
                readOnly={true}
                endIcon={
                    <div
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        className={styles.iconCopy}
                        onClick={() => {
                            navigator.clipboard.writeText(
                                sharedConfig.appUrl + "/view/" + scenarioStore.scenario.token,
                            );
                        }}
                    >
                        <FileCopy fontSize={"large"} />
                    </div>
                }
            />
            <div className={"flex_row justifyContent_spaceBetween"}>
                <UiButton
                    className={"mt_30"}
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => scenarioStore.refreshToken()}
                >
                    {t("scenarioPage.sharedRefresh")}
                </UiButton>
                <div className="mt_20 flex_row justifyContent_flexEnd">
                    <Toggle
                        hasNotText
                        value={scenarioStore.scenario.isShareable}
                        preLabel={<span className={"mr_5"}>{t("scenarioPage.shared")}</span>}
                        onChange={() => {
                            scenarioStore.setShareable(!scenarioStore.scenario.isShareable);
                        }}
                    />
                </div>
            </div>
        </div>
    );
});
