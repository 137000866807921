import React from "react";
import { ScenarioDraftEvent } from "scenarios/draft/ScenarioDraftEvent";
import styles from "../_css/ScenarioDraftBody.module.css";
import clsx from "clsx";
import { TPeriodMdl } from "scenarios/_models/PeriodMdl";

type Props = {
    period: TPeriodMdl;
    isEditable: boolean;
};

export const ScenarioDraftPeriod = (props: Props) => {
    if (!props.period) return null;
    return (
        <div>
            <div className={clsx("mb_20", styles.periodTitle)}>{props.period.title}</div>
            {props.period.events.map((event) => {
                if (!event || event.private || !event.published) return null;
                return (
                    <div key={event.id}>
                        <ScenarioDraftEvent event={event} period={props.period} isEditable={props.isEditable} />
                    </div>
                );
            })}
            <div className={"pageBreak"}></div>
        </div>
    );
};
