import _ from "lodash";
import { TFilesData } from "common/_utils/fetchUtils";
import imageCompression from "browser-image-compression";
import sharedConfig from "_configs/sharedConfig";

const files: { [url: string]: File } = {};

export function getFileUrl(file: File) {
    return URL.createObjectURL(file);
}

export function storeFile(file: File) {
    const url = getFileUrl(file);
    files[url] = file;
    return url;
}

export function getFile(url: string) {
    return files[url];
}

export function hasImageExtension(name: string) {
    name = name.toLowerCase();
    return (
        name.endsWith("jpg") ||
        name.endsWith("jpeg") ||
        name.endsWith("png") ||
        name.endsWith("gif") ||
        name.endsWith("svg") ||
        name.endsWith("bmp")
    );
}

export function isBlobUrl(url: string) {
    return url.startsWith("blob:");
}

export function isImage(url: string) {
    let name = url;
    const isBlob = isBlobUrl(url);
    if (isBlob) name = getFile(url)?.name ?? url;
    return hasImageExtension(name);
}

export async function createFileData(url: string, path: string, maxWidthOrHeight?: number, index?: number) {
    if (isBlobUrl(url)) {
        let blob: File | Blob = getFile(url);
        if (!blob) return;
        blob = maxWidthOrHeight && isImage(url) ? await imageCompression(blob as File, { maxWidthOrHeight }) : blob;
        const indexes = index !== undefined ? [index] : undefined;
        return { path, blob, indexes };
    }
}

export async function createFilesData(
    value: undefined | null | string | string[],
    path: string | string[],
    maxWidthOrHeight?: number,
) {
    let filesData: TFilesData | undefined;
    if (value) {
        if (_.isArray(value)) {
            await Promise.all(
                value.map(async (url, index) => {
                    const fileData = await createFileData(
                        url,
                        _.isArray(path) ? path[index] : path,
                        maxWidthOrHeight,
                        index,
                    );
                    if (fileData) {
                        filesData = filesData ?? [];
                        filesData.push(fileData);
                    }
                }),
            );
        } else if (!_.isArray(path)) {
            const fileData = await createFileData(value, path, maxWidthOrHeight);
            if (fileData) filesData = [fileData];
        }
    }
    return filesData;
}

export function convertToMb(size: number) {
    return size / 1000000;
}

type Entry = {
    isFile: boolean;
    isDirectory: boolean;
    name: string;
    fullPath: string;
    filesystem: any;
};

export type FileEntry = Entry & {
    file: (cb: (file: File) => void) => void;
};

export type DirectoryEntry = Entry & {
    createReader: () => any;
};

export const DUMB_FILES = [".DS_Store", "Thumbs.db"];

export function isValidFile(file: File) {
    return file.type && file.type.includes("image") && file.size < sharedConfig.maxUploadSize;
}

export function isFileEntry(entry: Entry): entry is FileEntry {
    return entry.isFile;
}

export function isDirectoryEntry(entry: Entry): entry is DirectoryEntry {
    return entry.isDirectory;
}

// Wrap readEntries in a promise to make working with readEntries easier
function readEntriesPromise(directoryReader: any) {
    try {
        return new Promise((resolve, reject) => {
            directoryReader.readEntries(resolve, reject);
        });
    } catch (err) {
        console.log(err);
    }
}

export function readFile(entry: FileEntry) {
    return new Promise<File>((resolve) => {
        entry.file((file) => {
            resolve(file);
        });
    });
}

// Get all the entries (files or sub-directories) in a directory by calling readEntries until it returns empty array
async function readAllDirectoryEntries(directoryReader: any) {
    const entries = [];
    let readEntries = (await readEntriesPromise(directoryReader)) as DirectoryEntry[];
    while (readEntries.length > 0) {
        entries.push(...readEntries);
        readEntries = (await readEntriesPromise(directoryReader)) as DirectoryEntry[];
    }
    return entries;
}

export async function getAllEntries(dataTransferItemList: DataTransferItemList) {
    const entries: { directoryEntries: DirectoryEntry[]; fileEntries: FileEntry[] } = {
        directoryEntries: [],
        fileEntries: [],
    };
    // Use BFS to traverse entire directory/file structure
    const queue: Entry[] = [];
    // Unfortunately dataTransferItemList is not iterable i.e. no forEach
    for (let i = 0; i < dataTransferItemList.length; i++) {
        // Note webkitGetAsEntry a non-standard feature and may change
        // Usage is necessary for handling directories
        queue.push(dataTransferItemList[i].webkitGetAsEntry());
    }
    while (queue.length > 0) {
        const entry = queue.shift();
        if (!entry) continue;
        if (isFileEntry(entry)) {
            // Ignore hidden files that may be present on different OSes
            if (DUMB_FILES.includes(entry.name)) continue;

            entries.fileEntries.push(entry);
        } else if (isDirectoryEntry(entry)) {
            entries.directoryEntries.push(entry);
            const reader = entry.createReader();
            queue.push(...(await readAllDirectoryEntries(reader)));
        }
    }
    return entries;
}

export function reformatFileEntries(fileEntries: FileEntry[]) {
    return Promise.all(
        fileEntries.map((fileEntry) => {
            return readFile(fileEntry).then((file) => ({ fileEntry, file }));
        }),
    );
}
