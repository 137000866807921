import React, { useState } from "react";
import styles from "./_css/galleryEditor.module.css";
import clsx from "clsx";

import { InputFrontBlock } from "common/ui/forms/InputFrontBlock";
import { InputFront } from "common/ui/forms/InputFront";
import { sectionStore } from "scenarios/sections/_stores/sectionStore";
import { ExternalOrNavLink } from "common/ui/links/ExternalOrNavLink";
import { useTranslation } from "react-i18next";
import youtubeGif from "../../../assets/img/youtubeShareLink.gif";
import vimeoGif from "../../../assets/img/vimeoShareLink.gif";
import { VIDEO_TYPE } from "scenarios/sections/VideoSection";
import { VisibilityRounded } from "@material-ui/icons";
import { DialogHelpVideo } from "scenarios/sections/editor/DialogHelpVideo";

type Props = {
    video: string;
    style: string;
};

export function VideoSectionEditor(props: Props) {
    const { t } = useTranslation();

    const [openModalVideo, setOpenModalVideo] = useState(false);
    const [typeVideoSelected, setTypeVideoSelected] = useState<VIDEO_TYPE | undefined>();

    return (
        <div className={clsx(styles.inputNbColumns, "mt_30")}>
            <InputFrontBlock className={styles.input} theme={"noBorder"} label={"Url video"}>
                <InputFront
                    type={"text"}
                    onChange={(e) => {
                        if (sectionStore.editingSection?.props) {
                            sectionStore.editingSection.props.video = e.target.value;
                        }
                    }}
                    value={props.video}
                    placeholder={props.video}
                />
            </InputFrontBlock>

            <div className={"flex_column mt_30"}>
                <p className={styles.title}>{t("editor.youtubeShareLinkTitle")}</p>
                <p className={"mt_15 text"}>{t("editor.youtubeShareLinkText")}</p>
                <div
                    className={clsx(styles.container, "position_relative")}
                    onMouseEnter={() => setTypeVideoSelected(VIDEO_TYPE.YOUTUBE)}
                    onMouseLeave={() => {
                        if (!openModalVideo) {
                            setTypeVideoSelected(undefined);
                        }
                    }}
                >
                    <img
                        onClick={() => {
                            setTypeVideoSelected(VIDEO_TYPE.YOUTUBE);
                            setOpenModalVideo(true);
                        }}
                        src={youtubeGif}
                        alt="youtubeGif"
                        className={clsx(styles.gif, "mt_15")}
                    />
                    <div
                        className={clsx("position_absolute", styles.btnHidden, {
                            [styles.visibleBtn]: typeVideoSelected === VIDEO_TYPE.YOUTUBE,
                        })}
                    >
                        <VisibilityRounded style={{ color: "#11111" }} fontSize={"large"} />
                    </div>
                </div>
                <ExternalOrNavLink
                    className={clsx(styles.link, "mt_15")}
                    url={"https://support.google.com/youtube/answer/57407?hl=en&co=GENIE.Platform%3DDesktop"}
                >
                    {t("editor.uploadYoutube")}
                </ExternalOrNavLink>
            </div>
            <div className={"flex_column mt_30"}>
                <p className={styles.title}>{t("editor.vimeoShareLinkTitle")}</p>
                <p className={"mt_15"}>{t("editor.vimeoShareLinkText")}</p>
                <div
                    className={clsx(styles.container, "position_relative")}
                    onMouseEnter={() => setTypeVideoSelected(VIDEO_TYPE.VIMEO)}
                    onMouseLeave={() => {
                        if (!openModalVideo) setTypeVideoSelected(undefined);
                    }}
                >
                    <img
                        onClick={() => {
                            setTypeVideoSelected(VIDEO_TYPE.VIMEO);
                            setOpenModalVideo(true);
                        }}
                        src={vimeoGif}
                        alt="vimeoGif"
                        className={clsx(styles.gif, "mt_15")}
                    />
                    <div
                        className={clsx("position_absolute", styles.btnHidden, {
                            [styles.visibleBtn]: typeVideoSelected === VIDEO_TYPE.VIMEO,
                        })}
                    >
                        <VisibilityRounded style={{ color: "#11111" }} fontSize={"large"} />
                    </div>
                </div>
                <ExternalOrNavLink
                    className={clsx(styles.link, "mt_15")}
                    url={
                        "https://vimeo.com/fr/upload?gclid=CjwKCAjwx7GYBhB7EiwA0d8oe762lOGKktkrEna7I_Gt0Nlh1DMTSot2PSz4dCt4TMH7MX6Ht5-nohoCPWsQAvD_BwE&gclsrc=aw.ds&utm_campaign=seat_plan&utm_medium=cpc&utm_source=google&utm_term=brand&vcid=42493"
                    }
                >
                    {t("editor.uploadVimeo")}
                </ExternalOrNavLink>
            </div>
            <DialogHelpVideo
                open={openModalVideo}
                onClose={() => setOpenModalVideo(false)}
                typeVideoSelected={typeVideoSelected}
            />
        </div>
    );
}
