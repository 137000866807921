import { useEffect, useState } from "react";

export function useHideDomElement(selector: string) {
    const [_selector, setSelector] = useState(selector);

    useEffect(() => {
        const crispEltms = document.querySelectorAll<HTMLDivElement>(_selector);
        if (crispEltms) {
            crispEltms.forEach((el) => {
                el.style.setProperty("display", "none", "important");
            });
        }
        return () => {
            if (crispEltms) {
                crispEltms.forEach((el) => {
                    el.style.setProperty("display", "unset");
                });
            }
        };
    }, []);

    return { setSelector };
}
