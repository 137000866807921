import React, { ReactNode } from "react";
import { FieldError } from "react-hook-form";
import styles from "./_css/inputFront.module.css";
import clsx from "clsx";

type Props<T = string> = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    error?: FieldError;
    onValueChange?: (value: T, event: React.ChangeEvent<HTMLInputElement>) => void;
    endIcon?: ReactNode;
};

export const InputFront = React.forwardRef<HTMLInputElement, Props>(
    ({ error, className, endIcon, onValueChange, onChange, ...props }, ref) => {
        return (
            <>
                <div className={styles.container}>
                    <input
                        {...props}
                        className={clsx(styles.input, { [styles.inputWithEndIcon]: !!endIcon }, className)}
                        ref={ref}
                        onChange={(event) => {
                            if (onChange) onChange(event);
                            if (onValueChange) onValueChange(event.target.value, event);
                        }}
                    />
                    {endIcon && <div className={styles.endIcon}>{endIcon}</div>}
                </div>
                {error && <div className={styles.error}>{error.message}</div>}
            </>
        );
    },
);
