import React from "react";
import clsx from "clsx";
import styles from "./_css/eventTag.module.css";
import { observer } from "mobx-react";

type Props = {
    title: string;
    imageUrl: string;
    isSelected?: boolean;
    onToggleEvent?: () => void;
};

export const EventTag = observer((props: Props) => {
    return (
        <div
            onClick={props.onToggleEvent ? props.onToggleEvent : undefined}
            className={clsx("flex_row_center p_5 pr_10 mr_10 mt_10 pl_7 cursor_pointer", styles.container, {
                [styles.isSelected]: props.isSelected,
            })}
        >
            {"# " + props.title}
        </div>
    );
});
