import React from "react";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import styles from "./_css/imagePlaceholder.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import sharedConfig from "_configs/sharedConfig";
import { convertToMb } from "common/_utils/fileUtils";
import { CircularProgress } from "@material-ui/core";

type Props = {
    onClick?: () => void;
    text: string;
    className?: string;
    queue?: {
        current: number;
        total: number;
    };
};

export function ImagePlaceholder(props: Props) {
    const { t } = useTranslation();
    const progress = props.queue ? (props.queue.current / props.queue.total) * 100 : 0;
    return (
        <div className={clsx(styles.container, "flex_center_center", props.className)} onClick={props.onClick}>
            {!props.queue && (
                <>
                    <WallpaperIcon fontSize="large" />
                    <div className={styles.text}>{props.text}</div>
                    <div className={styles.warning}>
                        {t("editor.maxSize", { maxSize: convertToMb(sharedConfig.maxUploadSize) })}
                    </div>
                </>
            )}
            {props.queue && (
                <div className={styles.queue}>
                    <p className={"mb_5"}>
                        {t("editor.queue", { current: props.queue.current, total: props.queue.total })}
                    </p>
                    <CircularProgress variant={"determinate"} value={progress} />
                </div>
            )}
        </div>
    );
}
