import React, { useState } from "react";
import { Dialog } from "common/ui/dialogs/Dialog";
import { InputBlock } from "common/ui/forms/InputBlock";
import { Input } from "common/ui/forms/Input";
import { DialogContent, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useLoadingFromPromise } from "common/loaders/useLoadingFromPromise";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";
import styles from "users/auth/_css/signUp.module.css";
import logoWithoutBrand from "../../../assets/img/logoWithoutBrand.svg";
import clsx from "clsx";
import { toast } from "react-toastify";
import { ErrorBlock } from "common/errors/ErrorBlock";
import { userStore } from "users/_stores/userStore";

type TFormData = {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
};

type Props = {
    open: boolean;
    setOpen: (bool: boolean) => void;
    notClose?: boolean;
};

export function ChangePasswordDialog(props: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const { handleSubmit, register, errors } = useForm<TFormData>({ mode: "onBlur" });

    const [showPassword, setShowPassword] = useState(false);
    const { loading, setPromise } = useLoadingFromPromise();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmitForm = (values: TFormData) => {
        const promise = userStore.changePassword(values.oldPassword, values.newPassword, values.confirmNewPassword);
        setPromise(promise);
        setIsLoading(true);
        promise.then(
            () => {
                history.push(URLS.scenarios.base());
                toast.success(t("auth.forgottenPassword.updated"), { position: "bottom-center" });
            },
            () => {
                setIsLoading(false);
                toast.error(t("errors.global.unknownLight"), { position: "bottom-center" });
                setPromise(new Promise((_resolve, reject) => reject(false)));
            },
        );
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.notClose ? () => history.push(URLS.home()) : () => props.setOpen(false)}
                className={styles.dialog}
                padding={30}
            >
                <DialogContent className={"pt_0 pb_0"}>
                    <div className={"flex_column mb_50"}>
                        <img className={clsx("mb_30", styles.image)} src={logoWithoutBrand} alt={"logo It's ur life"} />
                        <h4 className={styles.title}>{t("profil.changePasswordTitle")}</h4>
                    </div>
                    <ErrorBlock error={loading?.error} />
                    <form onSubmit={handleSubmit(onSubmitForm)}>
                        <InputBlock
                            labelStyle={styles.label}
                            label={t("profil.oldPassword")}
                            className={clsx("mb_40", styles.inputBlock)}
                        >
                            <Input
                                required
                                className={styles.input}
                                placeholder={t("signIn.form.placeholderPassword")}
                                name="oldPassword"
                                type={showPassword ? "text" : "password"}
                                ref={register({
                                    required: t("errors.forms.required"),
                                    minLength: { value: 8, message: t("auth.signUp.passwordsLength") },
                                })}
                                error={errors.oldPassword}
                                disabled={isLoading}
                                endIcon={
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        size="small"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff fontSize="large" />
                                        ) : (
                                            <Visibility fontSize="large" />
                                        )}
                                    </IconButton>
                                }
                            />
                        </InputBlock>
                        <InputBlock
                            labelStyle={styles.label}
                            label={t("profil.newPassword")}
                            className={clsx("mb_40", styles.inputBlock)}
                        >
                            <Input
                                required
                                className={styles.input}
                                placeholder={t("signIn.form.placeholderPassword")}
                                name="newPassword"
                                type={showPassword ? "text" : "password"}
                                ref={register({
                                    required: t("errors.forms.required"),
                                    minLength: { value: 8, message: t("auth.signUp.passwordsLength") },
                                })}
                                error={errors.newPassword}
                                disabled={isLoading}
                                endIcon={
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        size="small"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff fontSize="large" />
                                        ) : (
                                            <Visibility fontSize="large" />
                                        )}
                                    </IconButton>
                                }
                            />
                        </InputBlock>
                        <InputBlock
                            labelStyle={styles.label}
                            label={t("profil.confirmPassword")}
                            className={clsx("mb_40", styles.inputBlock)}
                        >
                            <Input
                                required
                                className={styles.input}
                                placeholder={t("signIn.form.placeholderPassword")}
                                name="confirmNewPassword"
                                type={showPassword ? "text" : "password"}
                                ref={register({
                                    required: t("errors.forms.required"),
                                    minLength: { value: 8, message: t("auth.signUp.passwordsLength") },
                                })}
                                error={errors.confirmNewPassword}
                                disabled={isLoading}
                                endIcon={
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        size="small"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff fontSize="large" />
                                        ) : (
                                            <Visibility fontSize="large" />
                                        )}
                                    </IconButton>
                                }
                            />
                        </InputBlock>

                        <div className="flex_center_center justifyContent_spaceBetween mb_15">
                            <UiButton
                                type="button"
                                disabled={isLoading}
                                variant="outlined"
                                color="primary"
                                onClick={() => props.setOpen(false)}
                            >
                                {t("profil.cancel")}
                            </UiButton>
                            <UiButton type="submit" disabled={isLoading} variant="contained" color="primary">
                                {t(isLoading ? "loaders.message" : "profil.save")}
                            </UiButton>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}
