import React, { useState } from "react";
import { Dialog } from "common/ui/dialogs/Dialog";
import { InputBlock } from "common/ui/forms/InputBlock";
import { Input } from "common/ui/forms/Input";
import { DialogContent, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useLoadingFromPromise } from "common/loaders/useLoadingFromPromise";
import { authStore } from "users/auth/_stores/authStore";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";
import styles from "users/auth/_css/signUp.module.css";
import logoWithoutBrand from "../../../assets/img/logoWithoutBrand.svg";
import clsx from "clsx";
import { ForgotPasswordDialog } from "users/auth/dialog/ForgotPasswordDialog";
import { toast } from "react-toastify";
import { SignUpDialog } from "users/auth/dialog/SignUpDialog";
import { ErrorBlock } from "common/errors/ErrorBlock";
import { GoogleSignUp } from "users/auth/GoogleSignUp";

type TFormData = {
    email: string;
    password: string;
};

type Props = {
    open: boolean;
    setOpen: (bool: boolean) => void;
    notClose?: boolean;
};

export function SignInDialog(props: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const { handleSubmit, register, errors } = useForm<TFormData>({ mode: "onBlur" });

    const [showPassword, setShowPassword] = useState(false);
    const { loading, setPromise } = useLoadingFromPromise();
    const [isLoading, setIsLoading] = useState(false);
    const [openForgotPassword, setOpenForgotPassword] = useState(false);
    const [openSignUp, setOenSignUp] = useState(false);

    const onSubmit = (values: TFormData) => {
        const promise = authStore.signIn(values.email, values.password);
        setPromise(promise);
        setIsLoading(true);
        promise
            .then(() => {
                history.push(URLS.scenarios.base());
                props.setOpen(false);
            })
            .catch(() => {
                toast.error(t("errors.global.unknownLight", { position: "bottom-center" }));
                setPromise(new Promise((_resolve, reject) => reject(false)));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.notClose ? () => history.push(URLS.home()) : () => props.setOpen(false)}
                className={styles.dialog}
                padding={30}
            >
                <DialogContent className={"pt_0 pb_0"}>
                    <div className={"flex_column mb_50"}>
                        <img className={clsx("mb_30", styles.image)} src={logoWithoutBrand} alt={"logo It's ur life"} />
                        <h4 className={styles.title}>{t("signIn.title")}</h4>
                        <div className={styles.text}>{t("signIn.text")}</div>
                    </div>
                    <ErrorBlock error={loading?.error} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputBlock
                            labelStyle={styles.label}
                            label={t("signIn.form.labelEmail")}
                            className={clsx("mb_40", styles.inputBlock)}
                        >
                            <Input
                                className={styles.input}
                                name="email"
                                placeholder={t("signIn.form.placeholderEmail")}
                                ref={register({
                                    required: t("errors.forms.required"),
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: t("errors.forms.emailInvalid"),
                                    },
                                })}
                                disabled={isLoading}
                                required
                                error={errors.email}
                            />
                        </InputBlock>
                        <InputBlock
                            labelStyle={styles.label}
                            label={t("signIn.form.labelPassword")}
                            className={clsx("mb_40", styles.inputBlock)}
                        >
                            <Input
                                required
                                className={styles.input}
                                placeholder={t("signIn.form.placeholderPassword")}
                                name="password"
                                type={showPassword ? "text" : "password"}
                                ref={register({
                                    required: t("errors.forms.required"),
                                })}
                                error={errors.password}
                                disabled={isLoading}
                                endIcon={
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        size="small"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff fontSize="large" />
                                        ) : (
                                            <Visibility fontSize="large" />
                                        )}
                                    </IconButton>
                                }
                            />
                        </InputBlock>
                        <div className={"mb_25"}>
                            <GoogleSignUp />
                        </div>
                        <div className={clsx("mb_50", styles.forgot)}>
                            <div className={"cursor_pointer mb_10"} onClick={() => setOpenForgotPassword(true)}>
                                {t("signIn.forgot")}
                            </div>
                            <div className={"cursor_pointer"} onClick={() => setOenSignUp(true)}>
                                {t("signUp.register")}
                            </div>
                        </div>
                        <div className="flex_center_center mb_15">
                            <UiButton type="submit" disabled={isLoading} variant="contained" color="primary">
                                {t(isLoading ? "loaders.message" : "auth.signIn.title")}
                            </UiButton>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
            <ForgotPasswordDialog open={openForgotPassword} onOpen={setOpenForgotPassword} />
            <SignUpDialog open={openSignUp} setOpen={setOenSignUp} />
        </div>
    );
}
