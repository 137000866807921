import React, { useMemo } from 'react';
import { UiSelect } from 'common/ui/mui/forms/UiSelect';
import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { i18nextInstance } from 'common/i18n/IntlProvider';

type Props = {
    value?: dayjs.Dayjs;
    onChange: (date: dayjs.Dayjs) => void;
    birthDate: dayjs.Dayjs;
    minDate?: dayjs.Dayjs;
    maxDate?: dayjs.Dayjs;
    disabled?: boolean;
};

const MenuProps = {
    PaperProps: { style: { maxHeight: 250 } },
};

function getOptions(birthDate: dayjs.Dayjs, minDate?: dayjs.Dayjs, maxDate?: dayjs.Dayjs) {
    const options = [];
    minDate = minDate ?? birthDate;
    maxDate = maxDate ?? dayjs.utc();
    const minAge = minDate.diff(birthDate, 'year');
    const maxAge = maxDate.diff(birthDate, 'year');
    for (let i = minAge; i <= maxAge; i++) {
        options.push(
            <MenuItem key={i} value={i}>
                {i18nextInstance.t('scenario.period.age', { count: i })}
            </MenuItem>,
        );
    }
    return options;
}

export function AgePicker(props: Props) {
    const { t } = useTranslation();
    const options = useMemo(
        () => getOptions(props.birthDate, props.minDate, props.maxDate),
        [props.birthDate, props.minDate, props.maxDate],
    );
    const valueAge = props.value ? props.value.diff(props.birthDate, 'year') : '';
    return (
        <UiSelect
            value={valueAge}
            onChange={event => {
                const age = event.target.value as number;
                props.onChange(props.birthDate.add(age, 'year'));
            }}
            MenuProps={MenuProps}
            displayEmpty
            disabled={props.disabled}
            disabledAltStyle
        >
            {!props.value && (
                <MenuItem value="">
                    {t('words.age')}
                </MenuItem>
            )}
            {options}
        </UiSelect>
    );
}
