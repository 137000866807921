import React from "react";
import styles from "./_css/spinner.module.css";

export function Spinner() {
    return (
        <div className={styles.ldsHeart}>
            <div></div>
        </div>
    );
}
