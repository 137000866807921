import io from "socket.io-client";
import sharedConfig from "_configs/sharedConfig";
import { socketConfig } from "_configs/socketConfig";
import { reaction } from "mobx";
import { userStore } from "users/_stores/userStore";
import { tokenStore } from "users/_stores/tokenStore";
import { TScenarioMdl } from "scenarios/_models/ScenarioMdl";
import { eventsStore, TEditingEvents } from "scenarios/_stores/eventsStore";
import { TEventMdl } from "scenarios/_models/EventMdl";
import { ScenarioStore } from "scenarios/_stores/ScenarioStore";
import { TPeriodMdl } from "scenarios/_models/PeriodMdl";
import { TPeriod } from "api/scenarios/_models/PeriodSchema";

type TPayloadNewEvent = { periodId: string; event: TEventMdl; position: number; editingEvents: TEditingEvents };
type TPayloadPeriodEdited = { periodId: string; title: string };
type TPayloadPeriodDeleted = { period: TPeriodMdl };
type TPayloadNewPeriod = TPayloadPeriodDeleted & { position: number };
type TPayloadMoveEvent = { scenarioId: string; period: TPeriod; eventMovable: TEventMdl[] };

class SocketStore {
    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    private socket: SocketIOClient.Socket;

    constructor() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.socket = io(sharedConfig.appUrl, socketConfig);
        this.socket.on("connect", () => {
            reaction(
                () => userStore.isLogged,
                (isLogged) => {
                    if (isLogged) this.socket.emit("user:signIn", { token: tokenStore.token });
                    else this.socket.emit("user:signOut", { token: undefined });
                },
            );
            if (userStore.isLogged) {
                this.socket.emit("user:signIn", { token: tokenStore.token });
            }
        });
        this.init();
    }

    //for import purpose
    init() {}

    subscribeScenarioToSocket(scenario: TScenarioMdl, scenarioStore: ScenarioStore) {
        if (!scenario || !scenario.periods) return null;

        this.socket.on(`scenario:${scenario._id}`, (payload: { editingEvents: TEditingEvents }) => {
            eventsStore.setEventsOnEdit(payload.editingEvents);
        });

        this.socket.on(`scenarioUpdate:${scenario._id}`, (payload: Partial<TScenarioMdl>) => {
            scenarioStore.updateInfoForScenario(payload);
        });

        this.socket.on(`newEvent:${scenario._id}`, (payload: TPayloadNewEvent) => {
            scenarioStore.addEvent(payload.periodId, payload.event, false, payload.position);
            this.subscribeSocketForNewEvent(payload.event, scenarioStore);
        });

        scenarioStore.periods.map((period) => {
            this.socket.on(`periodEdit:${period._id}`, (payload: TPayloadPeriodEdited) => {
                scenarioStore.changeTitleForPeriod(payload.periodId, payload.title);
            });
            this.socket.on(`periodDelete:${period._id}`, (payload: TPayloadPeriodDeleted) => {
                scenarioStore.deletePeriod(payload.period._id, false);
            });
            this.socket.on(`eventMovable:${period._id}`, (payload: TPayloadMoveEvent) => {
                scenarioStore.editPeriod({ ...payload.period, hiddenBookmarks: [], events: payload.eventMovable });
            });
        });

        this.socket.on(`newPeriod:${scenario._id}`, (payload: TPayloadNewPeriod) => {
            scenarioStore.addPeriod(payload.period, payload.position, false);
            this.socket.on(`periodEdit:${payload.period._id}`, (payload: TPayloadPeriodEdited) => {
                scenarioStore.changeTitleForPeriod(payload.periodId, payload.title);
            });
            this.socket.on(`periodDelete:${payload.period._id}`, (payload: TPayloadPeriodDeleted) => {
                scenarioStore.deletePeriod(payload.period._id, false);
            });

            this.socket.on(`eventMovable:${payload.period._id}`, (payload: TPayloadMoveEvent) => {
                scenarioStore.editPeriod({ ...payload.period, hiddenBookmarks: [], events: payload.eventMovable });
            });
        });

        scenario.periods.forEach((period) => {
            period.events.forEach((event) => {
                this.socket.on(
                    `eventEditStart:${event.id}`,
                    (payload: { editingEvents: TEditingEvents; event: TEventMdl }) => {
                        eventsStore.setEventsOnEdit(payload.editingEvents);
                    },
                );
                this.socket.on(
                    `eventEditEnd:${event.id}`,
                    (payload: { editingEvents: TEditingEvents; event: TEventMdl }) => {
                        eventsStore.setEventsOnEdit(payload.editingEvents);
                        scenarioStore.editEvent(payload.event, false);
                    },
                );
                this.socket.on(`eventDelete:${event.id}`, (payload: { event: TEventMdl }) => {
                    scenarioStore.deleteEvent(payload.event.id, false);
                });
            });
        });
    }

    openScenario(scenarioId: string) {
        this.socket.emit(`scenario:open`, { scenarioId: scenarioId, userId: userStore.user?._id });
    }

    eventOnEditStart(scenarioId: string, eventId: string, userId: string) {
        this.socket.emit(`eventOnEdit:start`, { scenarioId: scenarioId, userId: userId, eventId: eventId });
    }

    eventOnEditEnd(scenarioId: string, event: TEventMdl) {
        this.socket.emit(`eventOnEdit:end`, { scenarioId: scenarioId, event: event });
    }

    newEvent(scenarioId: string, periodId: string, event: TEventMdl, position?: number) {
        this.socket.emit(`event:new`, {
            scenarioId: scenarioId,
            periodId: periodId,
            event: event,
            position: position,
            userId: userStore.user?._id,
        });
    }

    newPeriod(scenarioId: string, period: TPeriodMdl, position?: number) {
        this.socket.emit(`period:new`, {
            scenarioId: scenarioId,
            period: period,
            position: position,
            userId: userStore.user?._id,
        });
    }

    periodEdited(scenarioId: string, period: TPeriodMdl, title: string) {
        this.socket.emit(`period:edit`, {
            scenarioId: scenarioId,
            periodId: period._id,
            title: title,
        });
    }

    updateScenario(infos: Partial<TScenarioMdl>, scenarioId: string) {
        this.socket.emit("scenarioUpdate", {
            infos,
            scenarioId,
        });
    }

    deleteEvent(scenarioId: string, event: TEventMdl) {
        this.socket.emit(`eventDelete`, {
            scenarioId: scenarioId,
            event: event,
        });
    }

    deletePeriod(scenarioId: string, period: TPeriodMdl) {
        this.socket.emit(`periodDelete`, {
            scenarioId: scenarioId,
            period: period,
        });
    }

    moveEvent(scenarioId: string, period: TPeriodMdl, eventMovable: TEventMdl[]) {
        this.socket.emit(`eventMove`, {
            scenarioId: scenarioId,
            period: period,
            eventMovable: eventMovable,
        });
    }

    subscribeSocketForNewEvent(event: TEventMdl, scenarioStore: ScenarioStore) {
        this.socket.on(
            `eventEditStart:${event.id}`,
            (payload: { editingEvents: TEditingEvents; eventId: string; userId: string }) => {
                eventsStore.setEventsOnEdit(payload.editingEvents);
            },
        );
        this.socket.on(`eventEditEnd:${event.id}`, (payload: { editingEvents: TEditingEvents; event: TEventMdl }) => {
            eventsStore.setEventsOnEdit(payload.editingEvents);
            scenarioStore.editEvent(payload.event, false);
        });
        this.socket.on(`eventDelete:${event.id}`, (payload: { event: TEventMdl }) => {
            scenarioStore.deleteEvent(payload.event.id, false);
        });
    }
}

const socketStore = new SocketStore();
export { socketStore };
