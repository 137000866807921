import * as React from "react";
import { Photo, PhotoAlbum, RenderPhotoProps } from "react-photo-album";
import clsx from "clsx";
import { IImageMdl } from "images/_models/ImageMdl";
import { useState } from "react";
import { PLACEHOLDER_IMAGE, sectionStore } from "scenarios/sections/_stores/sectionStore";
import { useScenarioStore } from "scenarios/ScenarioContext";
import styles from "scenarios/sections/_css/gallerySection.module.css";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { imagesStore, TImage } from "images/_stores/imagesStore";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { GallerySectionSortableContext } from "scenarios/sections/GallerySectionSortableContext";
import _ from "lodash";
import { toJS } from "mobx";
import { s4 } from "common/_utils/coreUtils";

export interface SortablePhoto extends Photo {
    id: string;
}

export type SortablePhotoProps = RenderPhotoProps<SortablePhoto>;

type Props = {
    id: string;
    componentId?: string;
    images: { imageUrl: string; height: number; width: number; id: string }[];
};

export const GallerySection = observer(
    (
        props: Props & {
            images: IImageMdl[];
            nbColumns: number;
            isEditable?: boolean;
            onEdit?: () => void;
            imageUrl?: string;
        },
    ) => {
        const { t } = useTranslation();
        const [photos, setPhotos] = useState<SortablePhoto[]>(
            props.images.map((image) => ({
                width: image.width,
                height: image.height,
                src: image.imageUrl,
                id: image.id ?? s4(),
            })),
        );

        const scenarioStore = useScenarioStore();
        const isEditingCurrentSection = sectionStore.editingSection?.props?.id === props.id;

        return (
            <div
                onClick={(e) => {
                    if (props.isEditable) {
                        e.preventDefault();
                        e.stopPropagation();
                        props.onEdit ? props.onEdit() : undefined;
                        scenarioStore.toggleSidebar();
                    }
                }}
            >
                {isEditingCurrentSection ? (
                    <div className={"position_relative"}>
                        <div className={clsx(styles.addImage, "position_absolute")}>
                            <UiButton
                                className={styles.btn}
                                size={"small"}
                                variant={"contained"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    imagesStore.setGalleryState({
                                        multiple: true,
                                        onSelect: (_images) => {
                                            if (Array.isArray(_images)) {
                                                const images = _images.map((image) => ({ ...image, id: image._id }));
                                                if (sectionStore.editingSection?.props?.images) {
                                                    const arrayImages = _.uniqBy(
                                                        _.concat(
                                                            toJS(sectionStore.editingSection.props.images),
                                                            images,
                                                        ),
                                                        "id",
                                                    );

                                                    sectionStore.editingSection.props.images = arrayImages;

                                                    if (sectionStore.editingSection?.props?.images.length > 1) {
                                                        const index = sectionStore.editingSection.props.images.findIndex(
                                                            (img: TImage) => img.imageUrl === PLACEHOLDER_IMAGE,
                                                        );
                                                        if (index !== -1) {
                                                            sectionStore.editingSection.props.images.shift();
                                                        }
                                                    }

                                                    setPhotos(
                                                        sectionStore.editingSection.props.images.map(
                                                            (image: TImage) => {
                                                                return {
                                                                    src: image.imageUrl,
                                                                    width: image.width,
                                                                    height: image.height,
                                                                    id: image.id,
                                                                };
                                                            },
                                                        ),
                                                    );
                                                    sectionStore.editingSection = undefined;
                                                }
                                            }
                                        },
                                    });
                                }}
                                color={"primary"}
                            >
                                {t("editor.event.addImage")}
                            </UiButton>
                        </div>
                        <GallerySectionSortableContext
                            id={props.id}
                            photos={photos}
                            onEditPhotos={(sortedPhotos) => {
                                setPhotos(sortedPhotos);
                            }}
                            nbColumns={props.nbColumns}
                        />
                    </div>
                ) : (
                    <PhotoAlbum photos={photos} layout="columns" columns={props.nbColumns} spacing={2} padding={2} />
                )}
            </div>
        );
    },
);
