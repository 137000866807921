export const opItems = [
    { value: "gt", label: "plus que" },
    { value: "gte", label: "plus ou égale à" },
    { value: "eq", label: "égale à" },
    { value: "ne", label: "pas égale à" },
    { value: "lt", label: "moins que" },
    { value: "lte", label: "moins ou égale à" },
    { value: "minMax", label: "compris entre" },
];

export type TOpItems = "gt" | "gte" | "eq" | "ne" | "lt" | "lte" | "minMax" | undefined;

export enum TFilterType {
    STRING = "string",
    DATE = "date",
    BOOLEAN = "boolean",
    NUMBER = "number",
    ENUM = "enum",
    ID = "_id",
}

export type TFilter = {
    id: string;
    type: TFilterType;
    value?: any;
    op?: TOpItems;
};
