import React from "react";
import styles from "../_css/options.module.css";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Container } from "@material-ui/core";
import lifeStory from "../../../../assets/icons/lifeStory.svg";
import obituary from "../../../../assets/icons/obituary.svg";
import { authStore } from "users/auth/_stores/authStore";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";
import { userStore } from "users/_stores/userStore";

export function Options() {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <Container className={styles.container}>
            <div className={clsx("flex_row_center justifyContent_spaceBetween", styles.content)}>
                <div
                    className={clsx("flex-1", styles.card)}
                    onClick={() => {
                        if (!userStore.isLogged) {
                            authStore.signUpGuest().then((res) => history.push(URLS.scenarios.base()));
                        }
                        history.push(URLS.scenarios.base());
                    }}
                >
                    <img className={styles.images} src={lifeStory} alt={"life story icon"} />
                    <h5 className={styles.h5}>{t("homePage.options.lifeStory")}</h5>
                    <span className={styles.text}> {t("homePage.options.lifeStoryText")}</span>
                </div>
                <div
                    className={clsx("flex-1", styles.card)}
                    onClick={() => {
                        if (!userStore.isLogged) {
                            authStore.signUpGuest().then((res) => history.push(URLS.scenarios.base()));
                        }
                        history.push(URLS.scenarios.base());
                    }}
                >
                    <img className={styles.images} src={obituary} alt={"obituary icon"} />
                    <h5 className={styles.h5}>{t("homePage.options.events")}</h5>
                    <span className={styles.text}> {t("homePage.options.eventsText")}</span>
                </div>
            </div>
        </Container>
    );
}
