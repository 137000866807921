import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { userStore } from "users/_stores/userStore";
import { useTranslation } from "react-i18next";
import { TUserMdl } from "users/_models/UserMdl";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import clsx from "clsx";
import styles from "./_css/userPage.module.css";
import { InputBlock } from "common/ui/forms/InputBlock";
import { Input } from "common/ui/forms/Input";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { authStore } from "users/auth/_stores/authStore";
import { URLS } from "_configs/URLS";
import { useLoadingFromPromise } from "common/loaders/useLoadingFromPromise";
import { useHistory } from "react-router";
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { ResetPasswordBlock } from "users/auth/blocks/ResetPasswordBlock";
import { ChangePassword, ChangePasswordDialog } from "users/auth/dialog/ChangePasswordDialog";

type TFormData = {
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
};

type Props = {
    isEditing?: boolean;
    onStopEditing?: () => void;
    cannotCancel?: boolean;
};

export const UserForm = observer((props: Props) => {
    const { t } = useTranslation();
    const user = userStore.user;
    const { setPromise } = useLoadingFromPromise();
    const history = useHistory();

    const [changeOpenPasswordDialog, setOpenChangePasswordDialog] = useState(false);

    const { handleSubmit, register, errors } = useForm<TUserMdl>({
        mode: "onBlur",
        defaultValues: user,
    });
    const onSubmit = (values: TFormData) => {
        /*const promise = authStore.signUp(values.email, values.password, values.firstName, values.lastName);
        setPromise(promise);
        promise
            .then(() => {
                history.push(URLS.scenarios.base());
            })
            .catch((err) => {
                const error = t(err.key);
                toast.error(error, { position: "bottom-center" });
            });*/
    };

    return (
        <>
            <form className={"mt_30"} onSubmit={handleSubmit(onSubmit)}>
                <div className={clsx(styles.firstRowForm)}>
                    <InputBlock
                        labelStyle={styles.label}
                        label={t("profil.firstName")}
                        className={clsx("mb_25", styles.inputBlock)}
                    >
                        <Input
                            className={styles.input}
                            name="firstName"
                            ref={register({
                                required: t("errors.forms.required"),
                            })}
                            error={errors.firstName}
                            disabled={!props.isEditing}
                        />
                    </InputBlock>
                    <InputBlock
                        labelStyle={styles.label}
                        label={t("profil.lastName")}
                        className={clsx("mb_25", styles.inputBlock)}
                    >
                        <Input
                            className={styles.input}
                            name="lastName"
                            ref={register({
                                required: t("errors.forms.required"),
                            })}
                            error={errors.lastName}
                            disabled={!props.isEditing}
                        />
                    </InputBlock>
                </div>
                <InputBlock
                    labelStyle={styles.label}
                    label={t("profil.email")}
                    className={clsx("mb_25", styles.inputBlock)}
                >
                    <Input
                        className={styles.input}
                        name="email"
                        ref={register({
                            required: t("errors.forms.required"),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: t("errors.forms.emailInvalid"),
                            },
                        })}
                        error={errors.email}
                        disabled={!props.isEditing}
                    />
                </InputBlock>

                {props.isEditing && (
                    <>
                        <UiButton onClick={() => setOpenChangePasswordDialog(!changeOpenPasswordDialog)}>
                            {t("profil.changePassword")}
                        </UiButton>
                        <ChangePasswordDialog open={changeOpenPasswordDialog} setOpen={setOpenChangePasswordDialog} />
                    </>
                )}
            </form>
            {props.isEditing && (
                <div className="mt_20">
                    {!props.cannotCancel && (
                        <UiButton
                            onClick={() => {
                                props.onStopEditing?.();
                            }}
                            variant={"outlined"}
                            color={"primary"}
                            className="mr_5"
                        >
                            {t("profil.cancel")}
                        </UiButton>
                    )}
                    {
                        <UiButton
                            onClick={handleSubmit(async (data) => {
                                return userStore
                                    .save(
                                        {
                                            ...userStore.user,
                                            ...data,
                                        } as TUserMdl,
                                        undefined,
                                    )
                                    .then(
                                        () => {
                                            toast.success(t("profil.success", { position: "bottom-center" }));
                                            props.onStopEditing?.();
                                        },
                                        (err) => {
                                            toast.error(err, { position: "bottom-center" });
                                        },
                                    );
                            })}
                            color={"primary"}
                            variant={"contained"}
                        >
                            {t("profil.save")}
                        </UiButton>
                    }
                </div>
            )}
        </>
    );
});
