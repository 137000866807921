import React, { useContext } from "react";
import { ScenarioStore } from "scenarios/_stores/ScenarioStore";

const ScenarioFormCtxt = React.createContext<ScenarioStore>({} as ScenarioStore);

type Props = {
    scenarioStore: ScenarioStore;
};

export function useScenarioStore() {
    return useContext(ScenarioFormCtxt);
}

export function ScenarioContext(props: React.PropsWithChildren<Props>) {
    return <ScenarioFormCtxt.Provider value={props.scenarioStore}>{props.children}</ScenarioFormCtxt.Provider>;
}
