import React, { useMemo } from 'react';
import { UiSelect } from 'common/ui/mui/forms/UiSelect';
import dayjs from 'dayjs';
import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type Props = {
    value?: number;
    onChange: (value: number) => void;
    minYear?: number;
    maxYear?: number;
    disabled?: boolean;
};

const MenuProps = {
    PaperProps: { style: { maxHeight: 250 } },
};

function getOptions(minYear?: number, maxYear?: number) {
    const options = [];
    minYear = minYear || 1900;
    maxYear = maxYear || dayjs.utc().year() + 20;
    for (let i = minYear; i <= maxYear; i++) {
        options.push(
            <MenuItem key={i} value={i}>
                {i}
            </MenuItem>,
        );
    }
    return options;
}

export function YearDropdown(props: Props) {
    const { t } = useTranslation();
    const options = useMemo(() => getOptions(props.minYear, props.maxYear), [props.minYear, props.maxYear]);

    return (
        <UiSelect
            value={props.value || ''}
            onChange={event => props.onChange(event.target.value as number)}
            MenuProps={MenuProps}
            displayEmpty
            disabled={props.disabled}
        >
            {!props.value && (
                <MenuItem value="">
                    {t('dates.year')}
                </MenuItem>
            )}
            {options}
        </UiSelect>
    );
}
