import React from "react";
import { useScenarioStore } from "scenarios/ScenarioContext";
import clsx from "clsx";
import styles from "./_css/videoSection.module.css";
import { sectionStore } from "scenarios/sections/_stores/sectionStore";

type Props = {
    video: string;
    alt?: string;
    title?: string;
    isEditable?: boolean;
    onEdit?: () => void;
};

export enum VIDEO_TYPE {
    YOUTUBE = "youtube",
    VIMEO = "vimeo",
}

function parseVideoUrl(url: string) {
    let type: VIDEO_TYPE | undefined;
    let id;

    const ytRegex = /https:\/\/youtu\.be\/[a-zA-Z0-9]*/;
    const vimeoRegex = /https:\/\/vimeo\.com\/[a-zA-Z0-9]*/;
    if (url.match(ytRegex)) {
        type = VIDEO_TYPE.YOUTUBE;
    } else if (url.match(vimeoRegex)) {
        type = VIDEO_TYPE.VIMEO;
    }

    const splittedUrl = url.split("/");
    if (splittedUrl[splittedUrl.length - 1].match(/[a-zA-Z0-9]*/)) {
        id = splittedUrl[splittedUrl.length - 1];
    }

    return { type, id };
}

export function VideoSection({ ...props }: Props) {
    const scenarioStore = useScenarioStore();
    if (!props.video) return null;
    const { type, id } = parseVideoUrl(props.video);
    const height = "100%";
    const width = "100%";
    return (
        <div className={"no-print position_relative"}>
            <div
                onClick={(e) => {
                    if (props.isEditable) {
                        e.preventDefault();
                        e.stopPropagation();
                        props.onEdit ? props.onEdit() : undefined;
                        scenarioStore.toggleSidebar();
                    }
                }}
                className={clsx({
                    [styles.isVideo]: sectionStore.editingSection?.props?.video != props.video && props.isEditable,
                })}
            ></div>
            <div
                className={clsx(styles.container)}
                onClick={(e) => {
                    if (props.isEditable) {
                        e.preventDefault();
                        e.stopPropagation();
                        props.onEdit ? props.onEdit() : undefined;
                        scenarioStore.toggleSidebar();
                    }
                }}
            >
                <div>
                    {type === VIDEO_TYPE.YOUTUBE && (
                        <iframe
                            src={`https://www.youtube.com/embed/${id}`}
                            width={width}
                            height={height}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    )}
                    {type === VIDEO_TYPE.VIMEO && (
                        <iframe
                            src={`https://player.vimeo.com/video/${id}`}
                            frameBorder="0"
                            width={width}
                            height={height}
                            allow="autoplay; fullscreen"
                            allowFullScreen
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
