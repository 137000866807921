import { TBookmarkMdl } from "bookmarks/_models/BookmarkMdl";

class BookmarksStore {
    bookmarks: TBookmarkMdl[] = [];

    private readonly _bookmarksIndex: { [bookmarkId: string]: TBookmarkMdl } = {};

    constructor() {
        if (__BROWSER__) {
            this.init(window.__PRELOADED_STATE__.bookmarks);
        }
    }

    init(bookmarks: TBookmarkMdl[]) {
        this.bookmarks = bookmarks;
        for (let i = 0; i < bookmarks.length; i++) {
            this._bookmarksIndex[bookmarks[i]._id] = bookmarks[i];
        }
    }
}

const bookmarksStore = new BookmarksStore();
export { bookmarksStore };
