import React from "react";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import { Dialog as MuiDialog } from "@material-ui/core";
import styles from "./_css/dialog.module.css";
import clsx from "clsx";

type Props = DialogProps & {
    borderRadius?: number;
    backgroundColor?: string;
    padding?: number;
};

export function Dialog({ borderRadius, backgroundColor, ...props }: Props) {
    return (
        <MuiDialog
            PaperProps={{
                style: { borderRadius: borderRadius ?? 8, backgroundColor: backgroundColor ?? "#f3f3f3" },
            }}
            {...props}
        >
            <div className={clsx(styles.container, "p_50")} style={{ padding: props.padding }}>
                {props.children}
            </div>
        </MuiDialog>
    );
}
