import { action, autorun, computed, observable } from "mobx";
import { tokenStore } from "users/_stores/tokenStore";
import { TUserMdl } from "users/_models/UserMdl";
import { authStore } from "users/auth/_stores/authStore";
import { fetchUtils, TFilesData } from "common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import analyticsService from "common/_utils/analyticsService";
import { USER_ROLES } from "api/users/_models/UserModel";
import { LoadingStateMdl } from "common/loaders/_models/LoadingStateMdl";
import { TFilter, TFilterType } from "common/filters/TFilter";
import _ from "lodash";

class UserStore {
    @observable user: TUserMdl | undefined = undefined;
    @observable usersState = new LoadingStateMdl<TUserMdl[]>();
    @observable searchResult: TUserMdl[] = [];

    constructor() {
        autorun(() => {
            const token = tokenStore.token;
            this._setDataFromToken(token);
        });
    }

    @computed get isLogged() {
        return this.user !== undefined;
    }

    deleteUser() {
        return fetchUtils.delete(sharedConfig.apiUrl + "/users/" + this.user?._id).then(() => authStore.signOut());
    }

    private readonly _setDataFromToken = action((_token: string | undefined) => {
        const tokenData = tokenStore.getTokenData<TUserMdl>();
        if (!tokenData) {
            if (this.isLogged) authStore.signOut();
        } else {
            this.user = tokenData;
            analyticsService.setUserInfo(this.user.email);
        }
    });

    save(user: TUserMdl, files?: TFilesData) {
        const body = files ? fetchUtils.createBodyWithFiles(user, files) : user;
        const isLoggedIn = !!this?.user && this?.user?._id;
        return fetchUtils[user._id ? "patch" : "post"](
            sharedConfig.apiUrl + "/users/" + this.user._id,
            body,
            !!files,
        ).then(({ data }) => {
            const user = data as TUserMdl;
            if (isLoggedIn) {
                tokenStore.refreshToken();
                this.user = user;
            } else {
                tokenStore.setToken(data as string);
            }
        });
    }

    isGuest() {
        return this.user?.firstName === "guest";
    }

    changePassword(oldPassword: string, newPassword: string, confirmPassword: string) {
        const userId = this.user?._id;
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + `/users/${userId}/changePassword`, {
            oldPassword,
            newPassword,
            confirmPassword,
        });
    }

    isAdmin() {
        return this.user?.roles.includes(USER_ROLES.ADMIN);
    }

    get(_id: string) {
        return fetchUtils.get<TUserMdl>(sharedConfig.apiUrl + "/users/" + _id);
    }

    setSearch = _.debounce((value: string) => {
        if (!this.usersState.isLoading) {
            this.usersState.startLoading();
            const filters: TFilter[] = [];
            filters.push({ id: "lastName", type: TFilterType.STRING, value });
            filters.push({ id: "firstName", type: TFilterType.STRING, value });
            const filtersParam = filters.length > 0 ? `filters=${JSON.stringify(filters)}` : "";
            const promise = fetchUtils.get<TUserMdl[]>(`${sharedConfig.apiUrl}/users/listing?${filtersParam}`);
            promise
                .then(
                    action(({ data }) => {
                        this.searchResult = data;
                        this.usersState.setSuccess(data);
                    }),
                )
                .catch(() => this.usersState.setError);
        }
    }, 200);
    resetSearch() {
        this.searchResult = [];
    }
}

export const userStore = new UserStore();
