import React from "react";
import clsx from "clsx";
import styles from "../_css/headerHomePage.module.css";
import home1 from "../../../../assets/img/home1.png";
import home2 from "../../../../assets/img/home2.png";
import home3 from "../../../../assets/img/home3.png";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function HeaderHomePage() {
    const { t } = useTranslation();

    return (
        <div className={clsx("mb_100", styles.topContainer)}>
            <div className={clsx(styles.container)}>
                <div className={clsx("position_relative", styles.content)}>
                    <div className={clsx("flex_row justifyContent_center", styles.sectionOne)}>
                        <div className={styles.contentImage}>
                            <div className={styles.overlay} />
                            <LazyLoadImage
                                src={home1}
                                alt={"image illustration home page"}
                                className={clsx(styles.images, styles.image1)}
                            />
                        </div>
                        <div className={styles.contentImage}>
                            <div className={styles.overlay} />
                            <LazyLoadImage
                                src={home2}
                                alt={"image illustration home page"}
                                className={clsx(styles.images, styles.image2)}
                            />
                        </div>
                        <div className={styles.contentImage}>
                            <div className={styles.overlay} />
                            <LazyLoadImage
                                src={home3}
                                alt={"image illustration home page"}
                                className={clsx(styles.images, styles.image3)}
                            />
                        </div>
                    </div>
                    <div className={clsx("flex_column position_absolute", styles.sectionTwo)}>
                        <h3 className={clsx("mt_30", styles.text1)}> {t("homePage.textTitle1")}</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}
