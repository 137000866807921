import React from "react";
import styles from "../_css/ScenarioDraftBody.module.css";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { Container } from "@material-ui/core";
import clsx from "clsx";
import { ScenarioDraftPeriod } from "scenarios/draft/ScenarioDraftPeriod";
import { observer } from "mobx-react";
import { TEXT_SIZES } from "scenarios/_models/ScenarioMdl";

type Props = {
    isEditable: boolean;
};

export const ScenarioDraftBody = observer((props: Props) => {
    const store = useScenarioStore();
    const scenario = store.scenario;

    return (
        <div className={clsx("print", "printContainer")}>
            <div className={clsx("flex justifyContent_spaceBetween")}>
                <Container className={clsx("flex", styles.container)}>
                    <div className={clsx(styles.scenarioHeader, "scenarioHeader")}>
                        <div className={styles.contentImage}>
                            <img
                                alt={"Main image"}
                                className={clsx("mr_50", styles.image)}
                                src={store.scenario.coverImg ?? "https://via.placeholder.com/512x512"}
                            />
                        </div>
                        <div className={clsx("flex_column alignItems_flexStart mb_20", styles.contentText)}>
                            <div className={clsx("mb_25", styles.scenarioTitle)}>{store.scenario.title}</div>
                            <div
                                className={clsx(styles.scenarioDescription, {
                                    scenario_text_small: store.scenario.sumUpSize === TEXT_SIZES.SMALL,
                                    scenario_text_medium:
                                        store.scenario.sumUpSize === TEXT_SIZES.MEDIUM || !store.scenario.sumUpSize,
                                    scenario_text_large: store.scenario.sumUpSize === TEXT_SIZES.LARGE,
                                })}
                            >
                                {store.scenario.sumUp}
                            </div>
                        </div>
                    </div>

                    {scenario.periods &&
                        store.filteredPeriods.map((period) => {
                            if (!period) return null;
                            return (
                                <div key={period._id} className={styles.draftPeriod}>
                                    <ScenarioDraftPeriod period={period} isEditable={props.isEditable} />
                                </div>
                            );
                        })}
                </Container>
            </div>
        </div>
    );
});
