import React from "react";
import { useTranslation } from "react-i18next";
import styles from "users/auth/_css/forgotPassword.module.css";
import clsx from "clsx";
import { Dialog } from "common/ui/dialogs/Dialog";
import { DialogContent } from "@material-ui/core";
import logoWithoutBrand from "../../../assets/img/logoWithoutBrand.svg";
import { UiButton } from "common/ui/mui/buttons/UiButton";

type Props = {
    open: boolean;
    setOpen: (bool: boolean) => void;
    setOpenForgot: (bool: boolean) => void;
};

export function ForgotPasswordValidationDialog(props: Props) {
    const { t } = useTranslation();

    return (
        <div>
            <Dialog open={props.open} onClose={() => props.setOpen(false)} className={styles.dialog} padding={30}>
                <DialogContent className={"pt_0 pb_0 mt_50 mb_50"}>
                    <div className={"flex_column justifyContent_center alignItems_center mb_50"}>
                        <img className={clsx("mb_30", styles.image)} src={logoWithoutBrand} alt={"logo It's ur life"} />
                        <h3 className={clsx("mb_10", styles.title)}>{t("forgot.validation.title")}</h3>
                        <div className={styles.text}>{t("forgot.validation.text")}</div>
                    </div>
                    <div className={"flex_center_center"}>
                        <UiButton
                            onClick={() => {
                                props.setOpen(false);
                                props.setOpenForgot(false);
                            }}
                            variant={"outlined"}
                            color={"primary"}
                        >
                            {t("forgot.validation.back")}
                        </UiButton>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
