import React from "react";
import styles from "./_css/galleryEditor.module.css";
import clsx from "clsx";
import { IImageMdl } from "images/_models/ImageMdl";
import { InputFrontBlock } from "common/ui/forms/InputFrontBlock";
import { InputFront } from "common/ui/forms/InputFront";
import { sectionStore } from "scenarios/sections/_stores/sectionStore";

type Props = {
    onDropImages: (uploadedImages: IImageMdl[]) => void;
    images?: any[];
    isEditable?: boolean;
    nbColumns: number;
};

export function GalleryEditor(props: Props) {
    const nbColumns = props.nbColumns ?? 1;
    return (
        <div>
            <div className={clsx(styles.inputNbColumns, "mt_30")}>
                <InputFrontBlock className={styles.input} theme={"noBorder"} label={"Nombre de colonne"}>
                    <InputFront
                        min={1}
                        max={6}
                        type={"number"}
                        onChange={(e) => {
                            sectionStore.editingSection.props.nbColumns = Number(e.target.value);
                        }}
                        value={nbColumns}
                        placeholder={nbColumns.toString()}
                    />
                </InputFrontBlock>
            </div>
        </div>
    );
}
