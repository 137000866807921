import React from "react";
import styles from "./_css/inputFrontBlock.module.css";
import clsx from "clsx";

type Props = {
    label: string;
    theme?: "noBorder";
    text?: string;
    required?: boolean;
    className?: string;
    inputClassName?: string;
};

export function InputFrontBlock(props: React.PropsWithChildren<Props>) {
    return (
        <div
            className={clsx(props.className, styles.container, {
                [styles.noBorder]: props.theme === "noBorder",
            })}
        >
            <div className={styles.label}>
                {props.label}
                {props.required && <span className={styles.required}>*</span>}
            </div>
            {props.text && <div className={styles.text}>{props.text}</div>}
            <div className={props.inputClassName ?? "mb_5"}>{props.children}</div>
        </div>
    );
}
