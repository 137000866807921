import React, { useState } from "react";
import clsx from "clsx";
import styles from "main/header/_css/nav.module.css";
import { useTranslation } from "react-i18next";

type Props = {
    cb: (open: boolean, setOpen: (bool: boolean) => void) => JSX.Element;
    label: string;
    button?: boolean;
    isMobile?: boolean;
};

export function WrapperDialog(props: Props) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <div
                onClick={() => setOpen(true)}
                className={clsx(styles.liLoginIn, styles.li, "pv_15", {
                    [styles.button]: props.button,
                    [styles.isMobile]: props.isMobile,
                })}
            >
                {t(props.label).toUpperCase()}
            </div>
            {props.cb(open, setOpen)}
        </>
    );
}
