import React from "react";

type Props = {
    width?: number;
};

export function Logo(props: Props) {
    return (
        <div>
            <svg
                width={props.width ?? "73"}
                height="73"
                viewBox="0 0 72 73"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M53.3662 42.8227C54.7851 43.3091 55.7758 43.7182 55.7758 43.7182V21.3318H58.1855V46.2055H14.812V21.3318H17.2216V43.7182C17.2216 43.7182 18.1921 43.2729 19.6313 42.7592C21.5936 42.0587 24.4274 41.2308 26.8602 41.2308C31.077 41.2308 36.4987 43.7182 36.4987 43.7182C36.4987 43.7182 42.5228 41.2308 46.1373 41.2308C48.286 41.2308 51.2862 42.1098 53.3662 42.8227ZM17.2216 18.8445V12.626C17.2216 12.626 22.6433 10.1387 26.8602 10.1387C31.077 10.1387 36.4987 12.626 36.4987 12.626C36.4987 12.626 41.318 10.1387 46.1373 10.1387C50.9566 10.1387 55.7758 12.626 55.7758 12.626V18.8445H60.5951V48.6929H12.4023V18.8445H17.2216ZM53.3662 14.255V40.2083C53.2527 40.1713 53.1375 40.1342 53.0206 40.0971C51.049 39.4709 48.3372 38.7434 46.1373 38.7434C43.9374 38.7434 41.2256 39.4709 39.2539 40.0971C38.2272 40.4232 37.3219 40.7483 36.673 40.9919L36.5372 41.0431L36.4679 41.0148C35.8564 40.7665 34.9967 40.4363 33.9952 40.1054C32.0446 39.4611 29.3487 38.7434 26.8602 38.7434C24.3716 38.7434 21.6758 39.4611 19.7252 40.1054L19.6313 40.1366V14.3095C20.0791 14.1376 20.6097 13.9444 21.194 13.7514C23.0084 13.152 25.1318 12.626 26.8602 12.626C28.5885 12.626 30.7119 13.152 32.5264 13.7514C33.4074 14.0424 34.1666 14.334 34.7035 14.5521C34.9712 14.6608 35.1818 14.7505 35.3225 14.8116C35.3927 14.8422 35.4454 14.8655 35.4789 14.8805L35.5146 14.8965L35.5201 14.899L35.5216 14.8997L36.5592 15.3758L37.5731 14.8525L37.5766 14.8507L37.6036 14.8371C37.6303 14.8238 37.6742 14.8021 37.7342 14.7732C37.8543 14.7153 38.038 14.629 38.2762 14.5236C38.7539 14.3123 39.4437 14.0273 40.2728 13.7421C41.9597 13.1616 44.0921 12.626 46.1373 12.626C48.1825 12.626 50.3148 13.1616 52.0018 13.7421C52.5159 13.919 52.9765 14.0958 53.3662 14.255Z"
                    fill="#2B3141"
                />
                <path
                    d="M47.1976 25.1114C48.5868 18.1632 39.564 16.8742 36.7791 20.6641C33.3129 16.8742 24.412 18.1632 25.8012 25.1114C26.9125 30.6699 34.4638 33.9659 36.7791 35.4397C39.0943 33.9659 46.0863 30.6699 47.1976 25.1114Z"
                    fill="#E66752"
                />
                <path
                    d="M2.02148 64.3564V63.6479H2.46968C2.72028 63.6479 2.93233 63.5949 3.10582 63.4889C3.28895 63.3732 3.38052 63.1033 3.38052 62.6792V55.7106C3.38052 55.2865 3.28895 55.0214 3.10582 54.9154C2.93233 54.7997 2.72028 54.7419 2.46968 54.7419H2.02148V54.0335H6.70582V54.7419H6.25763C6.00703 54.7419 5.79498 54.7997 5.62148 54.9154C5.44799 55.0214 5.36124 55.2865 5.36124 55.7106V62.6792C5.36124 63.1033 5.44799 63.3732 5.62148 63.4889C5.79498 63.5949 6.00703 63.6479 6.25763 63.6479H6.70582V64.3564H2.02148Z"
                    fill="#2B3141"
                />
                <path
                    d="M11.8677 64.5009C11.1833 64.5009 10.658 64.3323 10.2918 63.9949C9.93515 63.6576 9.75684 63.0696 9.75684 62.2311V57.5323H8.65804V56.8527C8.91828 56.8527 9.16407 56.8045 9.39539 56.7082C9.62672 56.6021 9.81949 56.4672 9.97371 56.3033C10.2725 55.9853 10.4942 55.4889 10.6388 54.8142H11.6364V56.607H13.5014V57.5323H11.6364V62.1588C11.6364 63.0455 11.9689 63.4889 12.6339 63.4889C12.8171 63.4889 12.9906 63.4792 13.1544 63.46C13.3183 63.4311 13.4773 63.3973 13.6315 63.3588V64.1829C13.487 64.2503 13.2556 64.3178 12.9376 64.3853C12.6291 64.4624 12.2725 64.5009 11.8677 64.5009Z"
                    fill="#2B3141"
                />
                <path
                    d="M18.3607 64.5009C17.4354 64.5009 16.751 64.3515 16.3077 64.0527C15.8739 63.7539 15.6571 63.3588 15.6571 62.8672C15.6571 62.578 15.7245 62.3564 15.8595 62.2021C15.9944 62.0479 16.1534 61.9467 16.3366 61.8985C16.5197 61.8407 16.6836 61.8118 16.8281 61.8118C16.8281 62.3708 16.9583 62.8286 17.2185 63.1853C17.4884 63.5323 17.8787 63.7058 18.3896 63.7058C18.9004 63.7058 19.2763 63.5997 19.5173 63.3877C19.7583 63.1756 19.8787 62.9106 19.8787 62.5925C19.8787 62.3708 19.8257 62.1829 19.7197 62.0286C19.6233 61.8744 19.445 61.725 19.1848 61.5804C18.9245 61.4359 18.5631 61.2768 18.1004 61.1033C17.339 60.8142 16.7607 60.4961 16.3655 60.1491C15.98 59.7925 15.7872 59.2913 15.7872 58.6455C15.7872 57.9226 16.0571 57.378 16.5968 57.0118C17.1366 56.6359 17.8498 56.4479 18.7366 56.4479C19.5655 56.4479 20.1679 56.5877 20.5438 56.8672C20.9197 57.1371 21.1077 57.4455 21.1077 57.7925C21.1077 58.0913 21.0016 58.3226 20.7896 58.4865C20.5872 58.6503 20.2595 58.7323 19.8065 58.7323C19.8065 58.26 19.7004 57.8889 19.4884 57.619C19.2763 57.3491 18.9583 57.2142 18.5342 57.2142C18.1583 57.2142 17.8547 57.3009 17.6233 57.4744C17.4016 57.6479 17.2908 57.8937 17.2908 58.2118C17.2908 58.4335 17.3486 58.6214 17.4643 58.7756C17.58 58.9298 17.7775 59.0792 18.0571 59.2238C18.3366 59.3684 18.7173 59.5274 19.1992 59.7009C19.8932 59.9612 20.4281 60.2696 20.8041 60.6262C21.1896 60.9732 21.3824 61.4503 21.3824 62.0576C21.3824 62.8383 21.1125 63.4407 20.5727 63.8648C20.0426 64.2889 19.3053 64.5009 18.3607 64.5009Z"
                    fill="#2B3141"
                />
                <path
                    d="M28.6542 64.5009C27.4397 64.5009 26.4903 64.2503 25.806 63.7491C25.1216 63.2383 24.7795 62.3949 24.7795 61.219V55.6527C24.7795 55.2576 24.6879 55.007 24.5048 54.9009C24.3216 54.7949 24.1096 54.7419 23.8686 54.7419H23.4204V54.0335H28.1048V54.7419H27.6566C27.406 54.7419 27.1891 54.7997 27.006 54.9154C26.8325 55.0214 26.7457 55.2865 26.7457 55.7106V61.3202C26.7457 62.1395 26.953 62.713 27.3674 63.0407C27.7819 63.3684 28.3265 63.5323 29.0012 63.5323C29.7144 63.5323 30.2638 63.3491 30.6493 62.9829C31.0349 62.607 31.2277 62.0672 31.2277 61.3636V55.6527C31.2277 55.2576 31.1361 55.007 30.953 54.9009C30.7795 54.7949 30.5674 54.7419 30.3168 54.7419H29.8686V54.0335H33.5987V54.7419H33.1361C32.8951 54.7419 32.6831 54.7997 32.4999 54.9154C32.3265 55.0214 32.2397 55.2865 32.2397 55.7106V61.3925C32.2397 62.3564 31.9457 63.1178 31.3578 63.6768C30.7698 64.2262 29.8686 64.5009 28.6542 64.5009Z"
                    fill="#2B3141"
                />
                <path
                    d="M35.4011 64.3564V63.6479H35.4445C35.7722 63.6479 36.042 63.5901 36.2541 63.4744C36.4758 63.3491 36.5866 63.06 36.5866 62.607V58.2985C36.5866 57.8744 36.4806 57.6045 36.2685 57.4889C36.0661 57.3732 35.8059 57.3154 35.4878 57.3154H35.4445V56.607H38.1047L38.3794 57.8503H38.4517C38.6444 57.4166 38.8758 57.0744 39.1457 56.8238C39.4252 56.5732 39.8685 56.4479 40.4758 56.4479C41.0252 56.4479 41.43 56.5443 41.6902 56.7371C41.9601 56.9298 42.0951 57.2094 42.0951 57.5756C42.0951 57.9612 41.9553 58.2696 41.6758 58.5009C41.3963 58.7226 40.9866 58.8335 40.4469 58.8335C40.4469 58.4383 40.3938 58.1443 40.2878 57.9515C40.1818 57.7588 39.9938 57.6624 39.724 57.6624C39.4637 57.6624 39.2517 57.7588 39.0878 57.9515C38.924 58.1347 38.7938 58.3708 38.6975 58.66C38.6107 58.9395 38.5481 59.2286 38.5095 59.5274C38.4806 59.8166 38.4661 60.0624 38.4661 60.2648V62.6792C38.4661 63.1033 38.5673 63.3732 38.7697 63.4889C38.9818 63.5949 39.2372 63.6479 39.536 63.6479H39.9842V64.3564H35.4011Z"
                    fill="#2B3141"
                />
                <path
                    d="M43.7326 64.3564V63.6479H43.9205C44.0844 63.6479 44.2483 63.6286 44.4121 63.5901C44.5856 63.5419 44.7254 63.4503 44.8314 63.3154C44.947 63.1708 45.0049 62.9588 45.0049 62.6792V54.9877C45.0049 54.7274 44.947 54.5347 44.8314 54.4094C44.7254 54.2744 44.5856 54.1877 44.4121 54.1491C44.2483 54.1009 44.0844 54.0768 43.9205 54.0768H43.7326V53.3684H46.8844V62.6792C46.8844 62.9588 46.9374 63.1708 47.0434 63.3154C47.1591 63.4503 47.2989 63.5419 47.4627 63.5901C47.6362 63.6286 47.8049 63.6479 47.9687 63.6479H48.1567V64.3564H43.7326Z"
                    fill="#2B3141"
                />
                <path
                    d="M51.9919 55.2479C51.6931 55.2479 51.4377 55.1708 51.2257 55.0166C51.0233 54.8624 50.9221 54.6118 50.9221 54.2648C50.9221 53.9082 51.0233 53.6576 51.2257 53.513C51.4377 53.3588 51.6931 53.2817 51.9919 53.2817C52.2811 53.2817 52.5317 53.3588 52.7437 53.513C52.9654 53.6576 53.0763 53.9082 53.0763 54.2648C53.0763 54.6118 52.9654 54.8624 52.7437 55.0166C52.5317 55.1708 52.2811 55.2479 51.9919 55.2479ZM49.8522 64.3564V63.6479H50.0401C50.204 63.6479 50.3678 63.6286 50.5317 63.5901C50.7052 63.5419 50.8449 63.4503 50.951 63.3154C51.0666 63.1804 51.1245 62.9732 51.1245 62.6937V58.2262C51.1245 57.966 51.0666 57.7732 50.951 57.6479C50.8353 57.513 50.6955 57.4262 50.5317 57.3877C50.3678 57.3395 50.204 57.3154 50.0401 57.3154H49.91V56.607H53.004V62.6792C53.004 62.9588 53.057 63.1708 53.163 63.3154C53.2787 63.4503 53.4184 63.5419 53.5823 63.5901C53.7461 63.6286 53.9148 63.6479 54.0883 63.6479H54.2618V64.3564H49.8522Z"
                    fill="#2B3141"
                />
                <path
                    d="M56.0138 64.3564V63.6479H56.2885C56.4523 63.6479 56.6162 63.6286 56.78 63.5901C56.9535 63.5419 57.0933 63.4503 57.1993 63.3154C57.315 63.1708 57.3728 62.9588 57.3728 62.6792V57.4744H56.0716V56.607H57.3728V55.9997C57.3728 55.113 57.6041 54.4335 58.0668 53.9612C58.5391 53.4792 59.2427 53.2383 60.1776 53.2383C61.0355 53.2383 61.6234 53.3395 61.9415 53.5419C62.2692 53.7347 62.433 53.9756 62.433 54.2648C62.433 54.5732 62.2981 54.7997 62.0282 54.9443C61.768 55.0889 61.3873 55.1612 60.8861 55.1612C60.8861 54.9973 60.8571 54.8238 60.7993 54.6407C60.7511 54.4576 60.6692 54.3033 60.5535 54.178C60.4379 54.0527 60.2788 53.9901 60.0764 53.9901C59.768 53.9901 59.5511 54.1347 59.4258 54.4238C59.3102 54.713 59.2523 55.166 59.2523 55.7829V56.607H61.262V57.4744H59.2523V62.6792C59.2523 62.9588 59.3053 63.1708 59.4114 63.3154C59.527 63.4503 59.6668 63.5419 59.8306 63.5901C59.9945 63.6286 60.1632 63.6479 60.3367 63.6479H60.9728V64.3564H56.0138Z"
                    fill="#2B3141"
                />
                <path
                    d="M66.8992 64.5009C65.733 64.5009 64.8414 64.1539 64.2245 63.46C63.6077 62.766 63.2992 61.7877 63.2992 60.525C63.2992 59.1756 63.598 58.1588 64.1956 57.4744C64.7932 56.7805 65.6414 56.4335 66.7402 56.4335C67.7426 56.4335 68.533 56.7274 69.1113 57.3154C69.6896 57.8937 69.9788 58.7564 69.9788 59.9033V60.6118H65.2077C65.2366 61.6142 65.4197 62.3467 65.7571 62.8094C66.104 63.2624 66.5956 63.4889 67.2318 63.4889C67.733 63.4889 68.1571 63.378 68.5041 63.1564C68.851 62.9347 69.1113 62.6744 69.2848 62.3756C69.5161 62.4913 69.6318 62.6985 69.6318 62.9973C69.6318 63.2383 69.5306 63.4744 69.3281 63.7058C69.1354 63.9371 68.8366 64.1298 68.4318 64.2841C68.0269 64.4286 67.5161 64.5009 66.8992 64.5009ZM68.0559 59.7443C68.0559 59.0021 67.9547 58.4142 67.7522 57.9804C67.5595 57.5371 67.2269 57.3154 66.7546 57.3154C66.3016 57.3154 65.945 57.5226 65.6848 57.9371C65.4245 58.3515 65.2751 58.9539 65.2366 59.7443H68.0559Z"
                    fill="#2B3141"
                />
            </svg>
        </div>
    );
}
