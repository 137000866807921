import React, { useState } from "react";
import { IImageMdl } from "./_models/ImageMdl";
import { LoadableFromLoading } from "common/loaders/LoadableFromLoading";
import { observer } from "mobx-react";
import { Grid, IconButton } from "@material-ui/core";
import { ImagePreview } from "./ImagePreview";
import { imagesStore } from "./_stores/imagesStore";
import { toast } from "react-toastify";
import { userStore } from "users/_stores/userStore";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { foldersStore } from "folders/_stores/foldersStore";
import { combineLoadingStates } from "common/loaders/loadingStateUtils";
import { FoldersAndImages } from "folders/FoldersAndImages";
import { Edit } from "@material-ui/icons";
import { InputTitleDialog } from "images/InputTitleDialog";
import { ConfirmationDialog } from "common/ui/dialogs/ConfirmationDialog";
import { useTranslation } from "react-i18next";

type Props = {
    onSelect: (image: IImageMdl) => void;
    onlyUser: boolean;
    close: () => void;
};

export const ImageGalleryLoaded = observer((props: Props) => {
    let images = imagesStore.getImages;
    const [imageToEdit, setImageToEdit] = useState<IImageMdl>();
    const [deleteImageId, setDeleteImageId] = useState<string | undefined>();
    const { t } = useTranslation();
    const scenarioStore = useScenarioStore();

    if (props.onlyUser) {
        images = images.filter((image) => image.user === userStore.user?._id);
    }
    return (
        <>
            {images
                .sort((a, b) => (a.title && b.title && a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1))
                .map(
                    (image) =>
                        image && (
                            <Grid key={image._id} item xs={12} md={3} lg={2}>
                                {userStore.user?._id === image.user ? (
                                    <>
                                        <ImagePreview
                                            onDragStart={() => {
                                                foldersStore.setDraggedImage(image);
                                            }}
                                            onDragEnd={() => {
                                                foldersStore.setDraggedImage(undefined);
                                            }}
                                            imageUrl={image.url}
                                            onClick={() =>
                                                imagesStore.galleryState?.multiple
                                                    ? imagesStore.addToSelectedImagesForSection({
                                                          imageUrl: image.url,
                                                          height: image.height,
                                                          width: image.width,
                                                          _id: image._id,
                                                      })
                                                    : props.onSelect(image)
                                            }
                                            onDelete={() => {
                                                setDeleteImageId(image._id);
                                            }}
                                        />
                                        <div className={"flex_center_center textAlign_center mt_10"}>
                                            {image.title ??
                                                image.url.split("/")[image.url.split("/").length - 1].split(".")[0]}
                                            <IconButton
                                                title={"Edit title"}
                                                onClick={() => {
                                                    setImageToEdit(image);
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <ImagePreview
                                            imageUrl={image.url}
                                            onClick={() =>
                                                imagesStore.galleryState?.multiple
                                                    ? imagesStore.addToSelectedImagesForSection({
                                                          imageUrl: image.url,
                                                          height: image.height,
                                                          width: image.width,
                                                          _id: image._id,
                                                      })
                                                    : props.onSelect(image)
                                            }
                                        />
                                        <div className={"flex_center_center textAlign_center mt_10"}>
                                            {image.title ??
                                                image.url.split("/")[image.url.split("/").length - 1].split(".")[0]}
                                        </div>
                                    </>
                                )}
                            </Grid>
                        ),
                )}
            <InputTitleDialog
                open={!!imageToEdit}
                onClose={() => setImageToEdit(undefined)}
                onSuccess={(image) => {
                    imagesStore.update(image);
                    setImageToEdit(undefined);
                }}
                image={imageToEdit}
            />
            <ConfirmationDialog
                open={!!deleteImageId}
                title={t("gallery.delete.title")}
                message={
                    deleteImageId && scenarioStore.isImageIdUsed(deleteImageId)
                        ? t("gallery.delete.messageForce")
                        : t("gallery.delete.message")
                }
                agreeLabel={t("gallery.delete.agree")}
                onAgree={() => {
                    if (deleteImageId) {
                        imagesStore
                            .delete(deleteImageId)
                            .then(() => {
                                scenarioStore.refresh();
                            })
                            .catch((error) => toast.error(error, { position: "bottom-center" }));
                    }
                    setDeleteImageId(undefined);
                }}
                disagreeLabel={t("words.cancel")}
                onDisagree={() => setDeleteImageId(undefined)}
                isDeleteButton={true}
            />
        </>
    );
});

export const ImageGallery = observer((props: Props) => {
    if (!userStore.user?._id) return null;
    const store = useScenarioStore();
    return (
        <div className="height_full">
            <LoadableFromLoading
                loading={combineLoadingStates([
                    imagesStore.fetchImages(store.scenario._id),
                    foldersStore.fetchFoldersByScenarioId(store.scenario._id),
                ])}
                renderer={() => {
                    return <FoldersAndImages {...props} close={props.close} />;
                }}
            />
        </div>
    );
});
