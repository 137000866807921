import React, { ReactNode } from "react";
import { Logo } from "main/Logo";
import { ExternalOrNavLink } from "common/ui/links/ExternalOrNavLink";
import styles from "./_css/acceptDialog.module.css";
import clsx from "clsx";

type Props = {
    onClose: () => void;
    title: string;
    body: string;
    btn: ReactNode;
    backUrl?: string;
    withLogo?: boolean;
};

export function AcceptDialog(props: Props) {
    return (
        <div>
            {props.withLogo && (
                <div className={"flex_center_center mb_10"}>
                    <Logo />
                </div>
            )}
            <div className={"flex_column alignItems_center"}>
                <div className={clsx("textAlign_center", styles.title)}>{props.title}</div>
                {props.body && <div className={"textAlign_center mt_10"}>{props.body}</div>}
                <div className={"mt_30"}>
                    <ExternalOrNavLink noRouting onClick={() => props.onClose()}>
                        {props.btn}
                    </ExternalOrNavLink>
                </div>
            </div>
        </div>
    );
}
