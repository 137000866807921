import React from "react";
import "../../../_css/fonts/font.css";
import { HeaderHomePage } from "../home/section/HeaderHomePage";
import { Footer } from "main/pages/home/section/Footer";
import styles from "./_css/contact.module.css";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { socketStore } from "scenarios/socket/_stores/socketStore";
import { Container } from "@material-ui/core";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import clsx from "clsx";

function Contact() {
    const history = useHistory();
    const { t } = useTranslation();
    if (history.location.search.split("?")[1] === "error") {
        toast.error(t("errors.scenarioView"), { position: "top-center", autoClose: false });
    }
    socketStore.init();
    return (
        <div className={styles.contentHome}>
            <Container>
                <div className={styles.sectionTitle}>{t("contact.title")}</div>
                <div className={styles.section}>
                    <div className={styles.sectionContent}>info@itsurlife.net</div>
                    <div className={styles.sectionSubContent}>{t("contact.subContent")}</div>
                    <a href="mailto:info@itsurlife.net" className={styles.sectionOverlay}>
                        <UiButton
                            variant={"contained"}
                            color={"primary"}
                            className={clsx("mt_10", styles.sectionHover)}
                        >
                            {t("contact.cta")}
                        </UiButton>
                    </a>
                </div>
            </Container>
            <Footer />
        </div>
    );
}

export default Contact;
