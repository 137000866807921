import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import clsx from "clsx";
import heart from "../../../../assets/img/heart.png";
import styles from "../_css/ourMission.module.css";

export function OurMission() {
    const { t } = useTranslation();
    return (
        <Container className={styles.container} id={"ourMission"}>
            <div className={clsx("position_relative", styles.content)}>
                <div className={styles.image}>
                    <img src={heart} alt={"heart icon"} />
                </div>
                <div className={styles.title}> {t("homePage.ourMission.title")} </div>
                <div className={styles.text}> {t("homePage.ourMission.text")} </div>
            </div>
        </Container>
    );
}
