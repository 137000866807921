import React, { useState } from "react";
import clsx from "clsx";
import styles from "scenarios/periods/_css/scenarioPeriod.module.css";
import { InputFrontBlock } from "common/ui/forms/InputFrontBlock";
import { InputFront } from "common/ui/forms/InputFront";
import { TPeriodMdl } from "scenarios/_models/PeriodMdl";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { observer } from "mobx-react-lite";
import { IconButton } from "@material-ui/core";
import { ArrowDownward, ArrowUpwardTwoTone, Edit } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";
import { ConfirmationDialog } from "common/ui/dialogs/ConfirmationDialog";
import { scenariosStore } from "scenarios/_stores/scenariosStore";
import { socketStore } from "scenarios/socket/_stores/socketStore";

type Props = {
    period: TPeriodMdl;
    position: number;
    isEditable?: boolean;
};

type TitleProps = Props & {
    onEdit: () => void;
    onDelete: () => void;
};

function _PeriodTitle(props: Omit<TitleProps, "isEditable">) {
    const scenarioStore = useScenarioStore();
    const [isHover, setIsHover] = useState(false);
    const { t } = useTranslation();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    return (
        <div className={"flex_row_center"} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <div className={clsx("ml_20", styles.title)}>{props.period.title}</div>
            {!scenarioStore.isCollaborator() && (
                <>
                    <div
                        className={clsx("ml_20 flex_row display_none", {
                            ["display_flex"]: isHover,
                        })}
                    >
                        <IconButton onClick={() => props.onEdit()}>
                            <Edit />
                        </IconButton>
                        {props.position > 0 && (
                            <IconButton onClick={() => scenarioStore.movePeriod(props.period._id, "up", true)}>
                                <ArrowUpwardTwoTone />
                            </IconButton>
                        )}
                        {props.position < scenarioStore.scenario.periods.length - 1 && (
                            <IconButton onClick={() => scenarioStore.movePeriod(props.period._id, "down", true)}>
                                <ArrowDownward />
                            </IconButton>
                        )}
                        <IconButton onClick={() => setOpenDeleteDialog(true)}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                    <ConfirmationDialog
                        title={t("editor.period.delete.title")}
                        open={openDeleteDialog}
                        message={t("editor.period.delete.message")}
                        agreeLabel={t("editor.period.delete.yes")}
                        onAgree={() => {
                            socketStore.deletePeriod(scenarioStore.scenario._id, props.period);
                            props.onDelete();
                        }}
                        disagreeLabel={t("editor.period.delete.no")}
                        onDisagree={() => setOpenDeleteDialog(false)}
                    />
                </>
            )}
        </div>
    );
}

export const PeriodTitle = observer(({ isEditable, ...props }: Props) => {
    const scenarioStore = useScenarioStore();
    const [localTitle, setLocalTitle] = useState(props.period.title);
    const [isEditing, setIsEditing] = useState(false);
    const { t } = useTranslation();

    if (isEditable) {
        return isEditing ? (
            <div className={clsx("flex_row_center", styles.container)}>
                <InputFrontBlock className={clsx("ml_20", styles.input)} label={"Title"}>
                    <InputFront
                        onChange={(e) => {
                            setLocalTitle(e.target.value);
                        }}
                        value={localTitle}
                    />
                </InputFrontBlock>
                <div className={clsx(styles.contentBtns, "flex_row")}>
                    <div
                        className={clsx("ml_10", styles.btn)}
                        color={"primary"}
                        variant={"outlined"}
                        onClick={() => setIsEditing(false)}
                    >
                        {t("words.cancel")}
                    </div>
                    <div
                        className={clsx("ml_10", styles.btnOutline)}
                        color={"primary"}
                        variant={"contained"}
                        onClick={() => {
                            scenarioStore.editPeriod({ ...props.period, title: localTitle });
                            socketStore.periodEdited(scenarioStore.scenario._id, props.period, localTitle);
                            setIsEditing(false);
                        }}
                    >
                        {t("words.save")}
                    </div>
                </div>
            </div>
        ) : (
            <_PeriodTitle
                onDelete={() => scenarioStore.deletePeriod(props.period._id)}
                onEdit={() => setIsEditing(true)}
                {...props}
            />
        );
    }
    return <_PeriodTitle {...props} />;
});
