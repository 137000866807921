import React from "react";
import { scenariosStore } from "scenarios/_stores/scenariosStore";
import styles from "scenarios/_css/scenariosPage.module.css";
import { ScenarioCard } from "scenarios/ScenarioCard";
import { observer } from "mobx-react";
import { TScenarioMdl } from "scenarios/_models/ScenarioMdl";

export const ScenarioList = observer(() => {
    if (scenariosStore.scenarios.length === 0) return <div>No results</div>;

    return (
        <>
            {scenariosStore.sortedScenarios.map((scenario: TScenarioMdl) => {
                return (
                    <div className={styles.card} key={scenario._id}>
                        <ScenarioCard scenario={scenario} />
                    </div>
                );
            })}
        </>
    );
});
