import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Input } from 'common/ui/forms/Input';
import { InputBlock } from 'common/ui/forms/InputBlock';
import { useLoadingFromPromise } from 'common/loaders/useLoadingFromPromise';
import { observer } from 'mobx-react';
import { authStore } from 'users/auth/_stores/authStore';
import { ErrorBlock } from 'common/errors/ErrorBlock';
import { CardContent, CardHeader } from '@material-ui/core';
import { UiButton } from 'common/ui/mui/buttons/UiButton';
import { URLS } from '_configs/URLS';
import { Link } from 'react-router-dom';
import { UiPaper } from 'common/ui/mui/surfaces/UiPaper';

type TFormData = {
    email: string;
}

export const AskResetPasswordBlock = observer(() => {
    const { t } = useTranslation();
    const { handleSubmit, register, errors } = useForm<TFormData>({ mode: 'onBlur' });
    const { loading, setPromise } = useLoadingFromPromise();
    const onSubmit = (values: TFormData) => {
        const promise = authStore.askResetPassword(values.email);
        setPromise(promise);
        promise.then(() => setIsSent(true));
    };
    const isLoading = loading?.status === 'LOADING';
    const [isSent, setIsSent] = useState(false);

    return (
        <UiPaper style={{ width: 450 }}>
            <CardHeader
                className='mt_10'
                style={{ textAlign: 'center' }}
                title={t('auth.forgottenPassword.title')}
            />
            <hr/>
            <CardContent>
                {t(isSent ? 'auth.forgottenPassword.mailSent' : 'auth.forgottenPassword.mailToSend')}
            </CardContent>
            <hr/>
            <CardContent>
                <ErrorBlock error={loading?.error}/>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputBlock label={t('words.email')}>
                        <Input
                            name="email"
                            ref={register({
                                required: t('errors.forms.required') as string,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: t('errors.forms.emailInvalid') as string,
                                },
                            })}
                            error={errors.email}
                            disabled={isLoading}
                        />
                    </InputBlock>
                    <div className="flex_center_center mb_15">
                        <UiButton
                            type='submit'
                            disabled={isLoading || isSent}
                            variant='contained'
                            color='primary'
                        >
                            {t(isLoading ? 'loaders.message' : 'auth.forgottenPassword.title')}
                        </UiButton>
                    </div>
                </form>
            </CardContent>
            <hr/>
            <CardContent className='flex_center_center flex_column'>
                <div>{t('auth.signIn.newUser')}</div>
                <Link to={URLS.auth.signUp()} className="link">
                    {t('auth.signUp.title')}
                </Link>
            </CardContent>
        </UiPaper>
    );
});
