import { IImageGalleryMdl, IImageMdl } from "images/_models/ImageMdl";
import { action, autorun, computed, observable, reaction } from "mobx";
import { fetchUtils } from "common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { userStore } from "users/_stores/userStore";
import { LoadingStateMdl } from "common/loaders/_models/LoadingStateMdl";
import { foldersStore } from "folders/_stores/foldersStore";

export type TImage = {
    imageUrl: string;
    height: number;
    width: number;
    _id: string;
};

class ImagesStore {
    private apiPath = `${sharedConfig.apiUrl}/images`;
    @observable images: IImageMdl[] = [];
    @observable folderImages: IImageMdl[] = [];
    @observable galleryState:
        | {
              onSelect: (image: TImage | TImage[]) => void | -1;
              multiple?: boolean;
          }
        | undefined;
    @observable textFilter = "";
    private readonly loadingState = new LoadingStateMdl<IImageMdl[]>();
    @observable selectedImagesForGallerySection: TImage[] = [];

    constructor() {
        autorun(() => {
            this.folderImages = this.images.filter((image) => image.folderId === foldersStore.selectedFolderId);
        });

        reaction(
            () => foldersStore.selectedFolderId,
            (selectedFolderId) => {
                this.folderImages = this.images.filter((image) => image.folderId === selectedFolderId);
            },
        );
    }

    @computed get getImages() {
        let images = this.folderImages;
        if (this.textFilter !== "") {
            images = images.filter((folderImage) => {
                return folderImage.title?.includes(this.textFilter) || folderImage.url.includes(this.textFilter);
            });
        }
        return images.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    }

    @action setGalleryState(
        galleryState: { onSelect: (image: TImage | TImage[]) => void | -1; multiple?: boolean } | undefined,
    ) {
        this.galleryState = galleryState;
    }

    @action fetchImages(scenarioId: string) {
        if (this.loadingState && this.loadingState.status !== "LOADING" && !this.loadingState.error) {
            this.loadingState.startLoading();
            fetchUtils
                .get<{ items: IImageMdl[] }>(this.apiPath + "/scenario/" + scenarioId)
                .then((res) => {
                    this.images = res.data.items.sort((a, b) =>
                        a.title && b.title && a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1,
                    );
                    this.loadingState.setSuccess(res.data.items);
                })
                .catch((err) => this.loadingState.setError(err));
        }
        return this.loadingState;
    }

    @action delete(imageId: string) {
        return fetchUtils.delete<IImageMdl>(`${this.apiPath}/${imageId}`).then(({ data }) => {
            this.images.splice(
                this.images.findIndex((image) => image._id === data._id),
                1,
            );
        });
    }

    @action update(image: IImageMdl) {
        return fetchUtils.patch<IImageMdl>(`${this.apiPath}/${image._id}`, image).then(({ data: updatedImage }) => {
            this.images.splice(
                this.images.findIndex((image) => image._id === updatedImage._id),
                1,
                updatedImage,
            );
        });
    }

    createFromBlob(blob: File, scenarioId: string, folderId?: string) {
        const body = fetchUtils.createBodyWithFiles(
            {
                url: "",
                user: userStore.user?._id,
                scenario: scenarioId,
                folderId: folderId,
                title: blob.name.split(".")[0],
            },
            [{ path: "url", blob }],
        );

        return fetchUtils.post<IImageMdl>(this.apiPath, body, true).then(({ data }) => {
            const createdImage = data;
            this.images.push(observable(data));
            if (!createdImage) return;
            return createdImage;
        });
    }

    createFromBlobs(blobs: File[], scenarioId: string, folderId?: string) {
        return Promise.all(blobs.map((file) => this.createFromBlob(file, scenarioId, folderId)));
    }

    getRatioFromImage(image: IImageGalleryMdl) {
        return {
            src: image.imageUrl,
            id: image._id,
            ...this.getRatioForImage({
                imageUrl: image.imageUrl,
                height: image.height,
                width: image.width,
                _id: image._id,
            }),
        };
    }

    getSync(imageId: string) {
        return this.images.find((image) => image._id === imageId);
    }

    getRatioForImage(image: TImage) {
        let height, width;
        let sizeMax = 0;
        let sizeMin = 0;
        let smaller;

        if (image.height > image.width) {
            sizeMax = image.height;
            sizeMin = image.width;
            smaller = "width";
        } else {
            sizeMax = image.width;
            sizeMin = image.height;
            smaller = "height";
        }

        const minRatio = 1;
        const maxRatio = sizeMax / sizeMin;
        let i = 1;
        let result = maxRatio;
        while (result < 100) {
            i++;
            result = Number.parseFloat((maxRatio * i).toFixed(2));
            if (result > 100) i--;
        }
        if (smaller === "height") {
            height = minRatio * i;
            width = maxRatio * i;
        } else {
            height = maxRatio * i;
            width = minRatio * i;
        }
        return { height: parseInt(height.toString()), width: parseInt(width.toString()) };
    }

    @action
    addToSelectedImagesForSection(image: TImage) {
        if (this.selectedImagesForGallerySection.find((_image) => _image.imageUrl === image.imageUrl)) {
            const index = this.selectedImagesForGallerySection.findIndex((img) => img.imageUrl === image.imageUrl);
            const array = this.selectedImagesForGallerySection;
            array.splice(index, 1);
            this.selectedImagesForGallerySection = array;
        } else {
            this.selectedImagesForGallerySection.push(image);
        }
    }

    @action
    resetSelectedImagesForSection() {
        this.selectedImagesForGallerySection = [];
    }
}

const imagesStore = new ImagesStore();
export { imagesStore };
