import React from "react";
import styles from "../_css/howWork.module.css";
import { useTranslation } from "react-i18next";
import howWork1 from "../../../../assets/img/6story.png";
import howWork2 from "../../../../assets/img/howWork2.png";
import howWork3 from "../../../../assets/img/howWork3.png";
import howWork4 from "../../../../assets/img/howWork4.png";
import howWork5 from "../../../../assets/img/howWork5.png";
import clsx from "clsx";
import { Container } from "@material-ui/core";

export function HowWork() {
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container)}>
            <h3 className={styles.title}>{t("homePage.howWork.title")}</h3>
            <div className={styles.containerFirstCard}>
                <Container>
                    <div className={clsx("flex_row_center justifyContent_spaceBetween position_relative", styles.card)}>
                        <div className={"mb_15 position_relative"}>
                            <div className={clsx("flex_row_center mb_15")}>
                                <div className={clsx("mr_15", styles.number)}> 1 </div>
                                <div className={styles.subTitle}>{t("homePage.howWork.subTitle1")}</div>
                            </div>
                            <div className={styles.text}>{t("homePage.howWork.text1")}</div>
                            <div className={styles.dashedLine1}></div>
                            <div className={styles.solidLine}></div>
                        </div>
                        <div className={styles.contentSingleImage}>
                            <img
                                className={clsx(styles.images, styles.img1)}
                                src={howWork1}
                                alt={"screen step how work"}
                            />
                        </div>
                    </div>
                </Container>
            </div>
            <div className={styles.bottomCard}>
                <Container>
                    <div className={clsx("flex_row_center justifyContent_spaceBetween position_relative", styles.card)}>
                        <div className={clsx("mb_15 position_relative", styles.contentText)}>
                            <div className={clsx("flex_row_center mb_15")}>
                                <div className={clsx("mr_15", styles.number)}> 2 </div>
                                <div className={styles.subTitle}>{t("homePage.howWork.subTitle2")}</div>
                            </div>
                            <div className={styles.text}>{t("homePage.howWork.text2")}</div>
                            <div className={styles.dashedLine2}></div>
                        </div>
                        <div className={styles.contentSingleImage}>
                            <img
                                className={clsx(styles.images, styles.img2)}
                                src={howWork2}
                                alt={"screen step how work"}
                                width={250}
                            />
                        </div>
                    </div>

                    <div
                        className={clsx(
                            "flex_row justifyContent_spaceBetween position_relative",
                            styles.card,
                            styles.card3,
                        )}
                    >
                        <div className={clsx("mb_15 position_relative", styles.contentText)}>
                            <div className={clsx("flex_row_center mb_15")}>
                                <div className={clsx("mr_15", styles.number)}> 3 </div>
                                <div className={styles.subTitle}>{t("homePage.howWork.subTitle3")}</div>
                            </div>
                            <div className={styles.text}>{t("homePage.howWork.text3")}</div>
                            <div className={styles.dashedLine3}></div>
                        </div>
                        <div className={styles.contentSingleImage}>
                            <img
                                className={clsx(styles.images, styles.bigImage)}
                                src={howWork3}
                                alt={"screen step how work"}
                            />
                        </div>
                    </div>
                    <div className={clsx("flex_row justifyContent_spaceBetween position_relative", styles.card)}>
                        <div className={"mb_15 "}>
                            <div className={clsx("flex_row_center mb_15")}>
                                <div className={clsx("mr_15", styles.numberInvert)}> 4 </div>
                                <div className={styles.subTitleInvert}>{t("homePage.howWork.subTitle4")}</div>
                            </div>
                            <div className={styles.textInvert}>{t("homePage.howWork.text4")}</div>
                        </div>
                        <div className={clsx("position_relative", styles.contentDoubleImage)}>
                            <img
                                className={clsx(styles.image, styles.onTopImage)}
                                src={howWork4}
                                alt={"screen step how work"}
                            />
                            <img
                                className={clsx(styles.image, styles.underImage)}
                                src={howWork5}
                                alt={"screen step how work"}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}
