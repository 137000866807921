import { action, computed, extendObservable, observable } from "mobx";
import { scenariosStore } from "scenarios/_stores/scenariosStore";
import { TScenarioMdl } from "scenarios/_models/ScenarioMdl";
import { TPeriodMdl } from "scenarios/_models/PeriodMdl";
import { TComponent, TComponentTemporaryDeleted, TEventMdl } from "scenarios/_models/EventMdl";
import { LoadingStateMdl } from "common/loaders/_models/LoadingStateMdl";
import { TBookmarkMdl } from "bookmarks/_models/BookmarkMdl";
import _ from "lodash";
import { thoughtsStore } from "bookmarks/_stores/thoughtsStore";
import { TAnswerMdl } from "scenarios/_models/AnswerMdl";
import { guid } from "common/_utils/coreUtils";
import dayjs from "dayjs";
import { userStore } from "users/_stores/userStore";
import { bookmarksStore } from "bookmarks/_stores/bookmarksStore";
import { SECTION_TYPE } from "scenarios/sections/_stores/sectionStore";
import { imagesStore } from "images/_stores/imagesStore";
import { socketStore } from "scenarios/socket/_stores/socketStore";

export class ScenarioStore {
    @observable scenario: TScenarioMdl;
    @observable selectedPeriod: {
        period: TPeriodMdl | undefined;
        position: number | undefined;
    } = {
        period: undefined,
        position: undefined,
    };
    @observable savingState = new LoadingStateMdl();
    @observable isEditingBaseInfo = false;
    @observable sidebarOpened = true;
    @observable eventId: string | undefined = undefined;
    @observable search = "";
    @observable text = "";
    @observable filters: TBookmarkMdl[] = [];
    notPersistentYetDeletedSections: TComponentTemporaryDeleted[] = [];
    notPersistentYetSectionIds: string[] = [];

    readonly viewMode: boolean;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    save = _.debounce(this.noDebounceSave, 250, { trailing: true, leading: false });
    _setSearch = _.debounce((s) => this.setSearch(s), 500);
    private _saveCounter = 0;

    constructor(scenario: TScenarioMdl, viewMode?: boolean) {
        this.scenario = scenario;
        this.viewMode = !!viewMode;
        this.setSidebar(
            localStorage.getItem("sidebarOpened") ? JSON.parse(localStorage.getItem("sidebarOpened") as string) : true,
        );
        socketStore.subscribeScenarioToSocket(scenario, this);
    }

    @computed get scenarioName() {
        return this.scenario.title ?? this.scenario.firstName + " " + this.scenario.lastName;
    }

    @computed get periods() {
        if (this.search) {
            const searchRegex = new RegExp(this.search, "gi");
            return this.scenario.periods.map((period) => {
                const events = period.events.filter((event) => {
                    if (searchRegex.test(event.text) || searchRegex.test(event.title)) {
                        return event;
                    }
                });
                return {
                    ...period,
                    events,
                };
            });
        }
        return this.scenario.periods;
    }

    @computed
    get filteredPeriods(): TPeriodMdl[] {
        let periods: TPeriodMdl[] = JSON.parse(JSON.stringify(this.scenario.periods));
        if (!this.filters.length) return periods;
        periods = periods.filter(
            (period) =>
                period.events.filter(
                    (event) =>
                        event.bookmarks.filter(
                            (bookmark) => this.filters.filter((filter) => filter._id === bookmark).length > 0,
                        ).length > 0,
                ).length > 0,
        );

        if (periods) {
            periods.forEach((period) => {
                period.events = period.events.filter(
                    (event) =>
                        event.bookmarks.filter(
                            (bookmark) => this.filters.filter((filter) => filter._id === bookmark).length > 0,
                        ).length > 0,
                );
            });
        }

        return periods;
    }

    noDebounceSave(event?: TEventMdl) {
        if (!this.scenario._id) return;
        this._saveCounter++;
        const counter = this._saveCounter;
        this.savingState.startLoading();
        if (event && !event.__version) event.__version = 0;
        return scenariosStore.save(this.scenario, event).then(
            action((scenario) => {
                if (!scenario) throw "ERROR";
                this.scenario.updatedAt = scenario.updatedAt;
                if (event) {
                    const { eventInScenario, periodInScenario } = this.getEventInScenario(event.id, scenario);
                    if (eventInScenario && periodInScenario) {
                        if (eventInScenario.__version != 0) {
                            this._changeVersionForEvent(event);
                        }
                    }
                }
                if (counter === this._saveCounter) this.savingState.setSuccess();
            }),
            (err) => {
                counter === this._saveCounter && this.savingState.setError(err);
            },
        );
    }

    @action setEventId(id: string | undefined) {
        this.eventId = id;
    }

    @action toggleSidebar() {
        localStorage.setItem("sidebarOpened", String(!this.sidebarOpened));
        this.sidebarOpened = !this.sidebarOpened;
    }

    @action setSidebar(sidebarOpened: boolean) {
        this.sidebarOpened = sidebarOpened;
    }

    @action setSearch(search: string) {
        this.search = search;
    }

    refresh() {
        return scenariosStore.fetchScenarioUpdates(this.scenario._id).then(
            action((scenario) => {
                if (scenario) {
                    this.scenario = scenario;
                }
            }),
        );
    }

    updateScenario(scenario: Partial<TScenarioMdl>) {
        this.scenario = extendObservable(this.scenario, scenario);
        if (!this.eventId) {
            this.save();
        }
    }

    @action
    updateInfoForScenario(infos: Partial<TScenarioMdl>) {
        this.scenario = {
            ...this.scenario,
            title: infos.title ?? this.scenario.title,
            coverImg: infos.coverImg,
            sumUp: infos.sumUp,
            birthday: dayjs(infos.birthday),
            deathDate: infos.deathDate,
        };
    }

    addPeriod(period: TPeriodMdl, position?: number, persist = true) {
        if (typeof position === "number") {
            const currentPeriod: TPeriodMdl = { ...this.scenario.periods[position] };
            this.scenario.periods.splice(position, 1, ...[currentPeriod, period]);
        } else {
            this.scenario.periods.push(period);
        }
        if (persist) {
            this.save();
        }
    }

    editPeriod(updatedPeriod: TPeriodMdl) {
        const periodIndex = this.getPeriodIndexByPeriodId(updatedPeriod._id);
        this.scenario.periods.splice(periodIndex, 1, updatedPeriod);
        this.save();
    }

    @action
    changeTitleForPeriod(periodId: string, title: string) {
        const periodIndex = this.getPeriodIndexByPeriodId(periodId);
        this.scenario.periods[periodIndex].title = title;
    }

    deletePeriod(periodId: string, persist = true) {
        const periodIndex = this.getPeriodIndexByPeriodId(periodId);
        this.scenario.periods.splice(periodIndex, 1);
        if (persist) {
            this.save();
        }
    }

    getPeriodIndexByPeriodId(periodId: string) {
        return this.scenario.periods.findIndex((period) => period._id === periodId);
    }

    getPeriod(periodId: string) {
        return this.scenario.periods.find((period) => period._id === periodId);
    }

    @action
    refreshToken() {
        this.scenario.token = guid();
        this.save();
    }

    setShareable(isShareable: boolean) {
        this.scenario.isShareable = isShareable;
        this.save();
    }

    addEvent(periodId: string, event: TEventMdl, persistent?: boolean, position?: number) {
        const period = this.getPeriod(periodId);
        if (period) {
            if (position !== undefined && position != period.events.length) {
                const tmpEvent = { ...period.events[position] };
                period.events.splice(position, 1, ...[event, tmpEvent]);
            } else {
                period.events.push(event);
            }
        }
        if (persistent) {
            this.save();
        }
    }

    getEvent(eventId: string) {
        for (const period of this.scenario.periods) {
            const event = period.events.find((event) => event.id === eventId);
            if (event) return { event, period };
        }
        return { event: undefined, period: undefined };
    }

    getEventInScenario(eventId: string, scenario: TScenarioMdl) {
        for (const periodInScenario of scenario.periods) {
            const eventInScenario = periodInScenario.events.find((event) => event.id === eventId);
            if (eventInScenario) return { eventInScenario, periodInScenario };
        }
        return { eventInScenario: undefined, periodInScenario: undefined };
    }

    isCollaborator() {
        return this.scenario.collaborators?.find((collaborator) => collaborator.email === userStore.user?.email);
    }

    isCollaboratorAndNotEventAuthor(event: TEventMdl) {
        const isCollaborator = this.isCollaborator();
        return !!(isCollaborator && event.user?._id && event.user._id !== userStore?.user?._id);
    }

    isCollaboratorEventAuthorOrScenarioOwner(event: TEventMdl) {
        return event.user?._id === userStore?.user?._id || this.scenario.user === userStore?.user?._id;
    }

    isOwner(_event?: TEventMdl) {
        return this.scenario.user === userStore?.user?._id;
    }

    isImageIdUsed(imageId: string) {
        let isUsed = false;
        const image = imagesStore.getSync(imageId);
        this.scenario.periods.forEach((period) => {
            period.events.forEach((event) => {
                event.sections.forEach((section) => {
                    if (
                        (section.id === SECTION_TYPE.IMAGE || section.id === SECTION_TYPE.TEXT_IMAGE) &&
                        section?.props?.imageUrl === image?.url
                    ) {
                        isUsed = true;
                    } else if (section.id === SECTION_TYPE.GALLERY) {
                        if (
                            section.props?.images.find(
                                (_image: { imageUrl: string } | undefined) => _image?.imageUrl === image?.url,
                            )
                        ) {
                            isUsed = true;
                        }
                    }
                });
            });
        });
        return isUsed;
    }

    @action async editEvent(editedEvent: TEventMdl, persistent?: boolean) {
        const { event, period } = this.getEvent(editedEvent.id);
        if (period && event) {
            period.events.splice(period.events.indexOf(event), 1, editedEvent);
        }
        if (persistent) {
            return this.noDebounceSave(editedEvent);
        }
    }

    addSection(props: TComponent, eventId: string) {
        const { event } = this.getEvent(eventId);
        if (props.props?.id) this.notPersistentYetSectionIds.push(props.props.id);
        else return;
        if (event) {
            event.sections.push({ ...props, props: { ...props.props } });
        }
    }

    deleteSection(sectionId: string, persistent?: boolean) {
        const { section, event, period } = this.getSection(sectionId);
        if (section && event) {
            this.notPersistentYetDeletedSections.push({
                id: section.id,
                props: section.props,
                index: event.sections.indexOf(section),
            });
        }
        if (event && period && section) {
            event.sections.splice(event.sections.indexOf(section), 1);
        }
        if (persistent) {
            this.save();
        }
    }

    restoreDeletedSection(eventId: string) {
        const { event } = this.getEvent(eventId);
        this.notPersistentYetDeletedSections.reverse();
        if (event) {
            this.notPersistentYetDeletedSections.forEach(({ id, props, index }) => {
                event?.sections.splice(index, 0, { id, props });
            });
        }
    }

    resetDeletedSection() {
        this.notPersistentYetDeletedSections = [];
    }

    getSection(sectionId: string) {
        for (const period of this.scenario.periods) {
            for (const event of period.events) {
                const section = event.sections.find((section) => section.props?.id === sectionId);
                if (section) return { section, event, period };
            }
        }
        return { section: undefined, event: undefined, period: undefined };
    }

    moveSection(sectionId: string, to: "up" | "down", persistent?: boolean) {
        const { section, event } = this.getSection(sectionId);
        if (event && section) {
            const index = event.sections.indexOf(section);
            const targetIndex = to === "up" ? index - 1 : index + 1;
            const sectionSrc = JSON.parse(JSON.stringify(event.sections[index]));
            const sectionTarget = JSON.parse(JSON.stringify(event.sections[targetIndex]));
            event.sections[index] = sectionTarget;
            event.sections[targetIndex] = sectionSrc;
        }
        if (persistent) {
            this.save();
        }
    }

    @action
    editSection(sectionId: string, editedSection: Partial<TComponent>, persistent?: boolean) {
        const { section, event } = this.getSection(sectionId);
        if (event && section) {
            const index = event.sections.indexOf(section);
            event.sections[index] = { ...section, ...editedSection };
        }
        if (persistent) {
            this.save();
        }
    }

    movePeriod(periodId: string, to: "up" | "down", persistent?: boolean) {
        const period = this.getPeriod(periodId);
        if (period) {
            const indexOfPeriod = this.scenario.periods.indexOf(period);
            const switchableId = to === "up" ? indexOfPeriod - 1 : indexOfPeriod + 1;
            this.scenario.periods[indexOfPeriod] = { ...this.scenario.periods[switchableId] };
            this.scenario.periods[switchableId] = period;
            if (persistent) {
                this.save();
            }
        }
    }

    @action
    deleteEvent(eventId: string, persistent?: boolean) {
        const { event, period } = this.getEvent(eventId);
        if (period && event) {
            period.events.splice(period.events.indexOf(event), 1);
        }
        if (persistent) {
            this.save();
        }
    }

    addBookmark(bookmark: TBookmarkMdl, persistent = false) {
        this.scenario.bookmarks.push(bookmark);
        if (persistent) {
            this.save();
        }
    }

    getThoughts() {
        return thoughtsStore.thoughts.sort((a, b) => a.position - b.position).concat(this.scenario.thoughts.slice());
    }

    addAnswer(answer: TAnswerMdl) {
        const _answer = this.getAnswerByThoughtId(answer.thoughtId);
        if (_answer) _answer.text = answer.text;
        else this.scenario.answers.push(answer);
        this.save();
    }

    getAnswerByThoughtId(thoughtId: string) {
        return this.scenario.answers.find((answer) => answer.thoughtId === thoughtId);
    }

    getAllTag() {
        const allTagForScenario: TBookmarkMdl[] = [];
        const allTag = bookmarksStore.bookmarks;

        this.scenario.periods.map((period) =>
            period.events.map((event) =>
                event.bookmarks.map((bookmark) => {
                    const _bookmark = allTag.find((_bookmark) => _bookmark._id === bookmark);
                    if (!allTagForScenario.find((tag) => tag === _bookmark) && _bookmark) {
                        allTagForScenario.push(_bookmark);
                    }
                }),
            ),
        );
        return allTagForScenario;
    }

    @action
    setFilters(filter: TBookmarkMdl) {
        if (this.filters.find((_filter) => _filter._id === filter._id)) {
            this.filters = this.filters.filter((_filter) => _filter._id != filter._id);
        } else {
            this.filters.push(filter);
        }
    }

    @action private _changeVersionForEvent(event: TEventMdl) {
        const eventWithNewVersion = { ...event, __version: event.__version += 1 };
        this.editEvent(eventWithNewVersion, false);
    }
}
