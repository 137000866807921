import mongoose from "mongoose";
import sharedConfig from "_configs/sharedConfig";

export enum USER_ROLES {
    ADMIN = "ADMIN",
}

export type TLang = keyof typeof sharedConfig.languages;

export interface IUserModel {
    _id: mongoose.Types.ObjectId;
    email: string;
    password?: string;
    token?: string;
    firstName?: string;
    lastName?: string;
    lang: TLang;
    roles: USER_ROLES[];
    sendinBlueId: number;
}

export interface IUserDocument extends Omit<IUserModel, "_id">, mongoose.Document {}

const userSchema = new mongoose.Schema(
    {
        email: { type: String, required: true, unique: true, index: true },
        password: { type: String, minlength: 6, select: false },

        token: { type: String },

        firstName: { type: String, trim: true },
        lastName: { type: String, trim: true },

        lang: { type: String, default: "fr" },

        roles: [{ type: String, enum: Object.values(USER_ROLES) }],

        sendinBlueId: Number,
    },
    { timestamps: true },
);

export const UserModel = mongoose.model<IUserDocument>("user", userSchema);
