import React, { PropsWithChildren } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { FilePlaceholder } from "common/ui/forms/images/FilePlaceholder";
import { FilePreview } from "common/ui/forms/images/FilePreview";
import styles from "./_css/filePicker.module.css";
import { storeFile } from "common/_utils/fileUtils";
import _ from "lodash";
import clsx from "clsx";
import { imagesStore } from "images/_stores/imagesStore";

export type FilePickerProps = PropsWithChildren<{
    value?: string[];
    onChange?: (value: string[]) => void;
    onlyImage?: boolean;
    maxNbFiles?: number;
    options?: DropzoneOptions;
    placeholder?: string;
    disabled?: boolean;
    scenarioId?: string;
}>;

export function FilePicker(props: FilePickerProps) {
    const { getRootProps, getInputProps } = useDropzone({
        accept: props.onlyImage ? "image/*" : undefined,
        ...props.options,
        disabled: props.disabled,
        onDropAccepted: (files) => {
            let newFiles = [...(props.value ?? []), ...files.map((file) => storeFile(file))];
            if (props.maxNbFiles) newFiles = _.takeRight(newFiles, props.maxNbFiles);
            if (props.scenarioId) imagesStore.createFromBlobs(files, props.scenarioId);
            props.onChange?.(newFiles);
        },
    });

    return (
        <div
            {...getRootProps({ className: clsx(styles.container, { [styles.disabled]: props.disabled }) })}
            onClick={props.disabled ? undefined : getRootProps().onClick}
        >
            {props.children}
            {!props.value ? (
                <FilePlaceholder
                    onlyImage={props.onlyImage}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                />
            ) : (
                <div className="flex_row">
                    {props.value.map((url, index) => (
                        <FilePreview
                            key={index}
                            url={url}
                            isImage={props.onlyImage}
                            onDelete={
                                props.disabled
                                    ? undefined
                                    : () => {
                                          const newValue = [...(props.value ?? [])];
                                          newValue.splice(index, 1);
                                          props.onChange?.(newValue);
                                      }
                            }
                        />
                    ))}
                </div>
            )}
            <input {...getInputProps()} />
        </div>
    );
}
