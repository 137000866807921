import React, { useState } from "react";
import { Dialog } from "common/ui/dialogs/Dialog";
import styles from "users/auth/_css/signUp.module.css";
import { DialogContent, IconButton } from "@material-ui/core";
import logoWithoutBrand from "../../../assets/img/logoWithoutBrand.svg";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { ErrorBlock } from "common/errors/ErrorBlock";
import { InputBlock } from "common/ui/forms/InputBlock";
import { Input } from "common/ui/forms/Input";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useLoadingFromPromise } from "common/loaders/useLoadingFromPromise";
import { useHistory } from "react-router";
import { authStore } from "users/auth/_stores/authStore";
import { URLS } from "_configs/URLS";
import { toast } from "react-toastify";
import { userStore } from "users/_stores/userStore";
import { GoogleSignUp } from "users/auth/GoogleSignUp";

type TFormData = {
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
};

type Props = {
    open: boolean;
    setOpen: (bool: boolean) => void;
    notClose?: boolean;
};

export function SignUpDialog(props: Props) {
    const { t } = useTranslation();
    const { handleSubmit, register, watch, errors } = useForm<TFormData>({ mode: "onBlur" });
    const { loading, setPromise } = useLoadingFromPromise();
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const onSubmit = (values: TFormData) => {
        const promise = userStore.isGuest
            ? authStore.signUp(values.email, values.password, values.firstName, values.lastName, userStore.user?._id)
            : authStore.signUp(values.email, values.password, values.firstName, values.lastName);
        setPromise(promise);
        promise
            .then(() => {
                history.push(URLS.scenarios.base());
                if (userStore.isGuest) {
                    authStore.setStatusInvitationSignUp(false);
                    authStore.stopCron();
                }
            })
            .catch((err) => {
                const error = t(err.key);
                toast.error(error, { position: "bottom-center" });
            });
    };

    const isLoading = loading?.status === "LOADING";
    const [isAgree, setIsAgree] = useState(false);
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.notClose ? () => history.push(URLS.home()) : () => props.setOpen(false)}
                className={styles.dialog}
                padding={30}
            >
                <DialogContent className={"pt_0 pb_0"}>
                    <div className={"flex_column justifyContent_center "}>
                        <img className={styles.image} src={logoWithoutBrand} alt={"logo It's ur life"} />
                        <h4 className={clsx("mb_5", styles.title)}>{t("signUp.title")}</h4>
                        <div className={styles.text}>{t("signUp.text")}</div>
                    </div>
                    <ErrorBlock error={loading?.error} />
                    <form className={"mt_30"} onSubmit={handleSubmit(onSubmit)}>
                        <div className={clsx("flex_row justifyContent_spaceBetween", styles.firstRowForm)}>
                            <InputBlock
                                labelStyle={styles.label}
                                label={t("signUp.form.labelFirstname")}
                                className={clsx("mb_25", styles.inputBlock)}
                            >
                                <Input
                                    className={styles.input}
                                    name="firstName"
                                    ref={register({
                                        required: t("errors.forms.required"),
                                    })}
                                    disabled={isLoading}
                                    error={errors.firstName}
                                    placeholder={t("signUp.form.placeholderFirstname")}
                                />
                            </InputBlock>
                            <InputBlock
                                labelStyle={styles.label}
                                label={t("signUp.form.labelLastname")}
                                className={clsx("mb_25", styles.inputBlock)}
                            >
                                <Input
                                    className={styles.input}
                                    placeholder={t("signUp.form.placeholderLastname")}
                                    name="lastName"
                                    ref={register({
                                        required: t("errors.forms.required"),
                                    })}
                                    disabled={isLoading}
                                    error={errors.lastName}
                                />
                            </InputBlock>
                        </div>
                        <InputBlock
                            labelStyle={styles.label}
                            label={t("signUp.form.labelEmail")}
                            className={clsx("mb_25", styles.inputBlock)}
                        >
                            <Input
                                placeholder={t("signUp.form.placeholderEmail")}
                                className={styles.input}
                                name="email"
                                ref={register({
                                    required: t("errors.forms.required"),
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: t("errors.forms.emailInvalid"),
                                    },
                                })}
                                disabled={isLoading}
                                error={errors.email}
                            />
                        </InputBlock>
                        <InputBlock
                            labelStyle={styles.label}
                            label={t("signUp.form.labelPassword")}
                            text={t("auth.signUp.passwordHint")}
                            className={clsx("mb_25", styles.inputBlock)}
                        >
                            <Input
                                placeholder={t("signUp.form.placeholderPassword")}
                                className={styles.input}
                                name="password"
                                type={showPassword ? "text" : "password"}
                                ref={register({
                                    required: t("errors.forms.required"),
                                    minLength: { value: 6, message: t("auth.signUp.passwordsLength") },
                                })}
                                disabled={isLoading}
                                error={errors.password}
                                endIcon={
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        size="small"
                                        className={styles.icons}
                                    >
                                        {showPassword ? (
                                            <VisibilityOff fontSize="large" />
                                        ) : (
                                            <Visibility fontSize="large" />
                                        )}
                                    </IconButton>
                                }
                            />
                        </InputBlock>
                        <InputBlock
                            labelStyle={styles.label}
                            label={t("auth.signUp.passwordConfirmation")}
                            className={clsx(styles.inputBlock)}
                        >
                            <Input
                                className={clsx(styles.input)}
                                name="confirmPassword"
                                type={showPassword ? "text" : "password"}
                                ref={register({
                                    required: t("errors.forms.required"),
                                    minLength: { value: 8, message: t("auth.signUp.passwordsLength") },
                                    validate: (value) =>
                                        value !== watch("password") ? t("auth.signUp.passwordsMismatch") : undefined,
                                })}
                                disabled={isLoading}
                                error={errors.password}
                                endIcon={
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        size="small"
                                        className={styles.icons}
                                    >
                                        {showPassword ? (
                                            <VisibilityOff fontSize="large" />
                                        ) : (
                                            <Visibility fontSize="large" />
                                        )}
                                    </IconButton>
                                }
                            />
                        </InputBlock>
                        <div className={"mb_15 flex_column"}>
                            <span className={clsx("mt_10 mr_5 ml_5", styles.subText)}> {t("signUp.form.subText")}</span>
                            <div className={"flex_row mt_20"}>
                                <input type={"checkbox"} onChange={(e) => setIsAgree(e.target.value)} />
                                <p
                                    className={clsx("ml_5", styles.agree)}
                                    dangerouslySetInnerHTML={{ __html: t("signUp.form.agree") }}
                                ></p>
                            </div>
                        </div>
                        <div className="flex_column mb_15 mt_50">
                            <UiButton
                                type="submit"
                                disabled={isLoading || !isAgree}
                                variant="contained"
                                color="primary"
                                className={"mb_15"}
                            >
                                {t(isLoading ? "loaders.message" : "signUp.form.create")}
                            </UiButton>
                            <GoogleSignUp />
                            <UiButton
                                type="button"
                                variant="outlined"
                                color="primary"
                                className={"mb_15 mt_15"}
                                onClick={() => props.setOpen(false)}
                            >
                                {t(isLoading ? "loaders.message" : "deleteAccount.cancel")}
                            </UiButton>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}
