import React, { ReactNode } from "react";
import styles from "./_css/toggle.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

type Props = {
    value: boolean;
    label?: string;
    preLabel?: string | ReactNode;
    onChange: (value: boolean) => void;
    hasNotText?: boolean;
};

export const Toggle = React.forwardRef((props: Props, ref) => {
    const { t } = useTranslation();
    return (
        <div
            ref={ref}
            className={clsx("flex_row_center unselectable", styles.toggleBlock, {
                [styles.toggleBlock_active]: props.value,
            })}
            onClick={() => props.onChange(!props.value)}
        >
            {props.preLabel && <span className={clsx("mr_10", styles.label)}>{props.preLabel}</span>}
            <div className={styles.toggle}>
                <div className={styles["toggle-handle"]} />
                {!props.hasNotText && (
                    <div className={styles["toggle-label"]}>{props.value ? t("words.yes") : t("words.no")}</div>
                )}
            </div>
            {props.label && <span className={styles.label}>{props.label}</span>}
        </div>
    );
});
