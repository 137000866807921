import React, { useState } from "react";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { defaultEvent, TEventMdl } from "scenarios/_models/EventMdl";
import { TPeriodMdl } from "scenarios/_models/PeriodMdl";
import { userStore } from "users/_stores/userStore";
import { PeriodTitle } from "scenarios/periods/PeriodTitle";
import { EventPage } from "scenarios/events/EventPage";
import { Dialog } from "common/ui/dialogs/Dialog";
import { EventCard } from "scenarios/events/EventCard";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styles from "./_css/scenarioPeriod.module.css";
import addEventIcon from "../../assets/icons/addEventIcon.svg";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { sectionStore } from "scenarios/sections/_stores/sectionStore";
import { eventsStore } from "scenarios/_stores/eventsStore";
import { socketStore } from "scenarios/socket/_stores/socketStore";
import useScenarioPeriod from "scenarios/periods/hooks/useScenarioPeriod";

type PropDraggableCard = {
    period: TPeriodMdl;
    onEdit: (id: string) => void;
    onAddEvent: (id: string) => void;
    event: TEventMdl;
    index: number;
};

function EventDraggableCard(props: PropDraggableCard) {
    const scenarioStore = useScenarioStore();
    const { t } = useTranslation();
    if (props.event.user === null) {
        props.event.user = {
            firstName: undefined,
            lastName: undefined,
        };
    }

    return (
        <Draggable draggableId={props.event.id} index={props.index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={"cursor_pointer mb_10 position_relative"}
                    onClick={() => {
                        if (
                            eventsStore.editingEvents &&
                            eventsStore.editingEvents.find((item) => item.eventId === props.event.id) &&
                            eventsStore.editingEvents.find((item) => item.eventId === props.event.id)?.userId !=
                                userStore.user?._id
                        ) {
                            props.onEdit(undefined);
                        } else {
                            props.onEdit(props.event.id);
                        }
                    }}
                >
                    {(props.event.private && scenarioStore.scenario.user != userStore.user?._id) ||
                    (!props.event.published && !scenarioStore.isCollaboratorEventAuthorOrScenarioOwner(props.event)) ? (
                        <></>
                    ) : (
                        <div className={clsx("position_absolute text_small text_italic", styles.userName)}>{`${
                            props.event.user.firstName ?? undefined
                        } ${props.event.user.lastName ?? undefined}`}</div>
                    )}
                    {(!(props.event.private && scenarioStore.scenario.user != userStore.user?._id) ||
                        (!props.event.published &&
                            !scenarioStore.isCollaboratorEventAuthorOrScenarioOwner(props.event))) && (
                        <>
                            <EventCard event={props.event} />
                            {!userStore.isAdmin() && (
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        const event = defaultEvent();
                                        props.onAddEvent(event.id);
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                        // @ts-ignore
                                        scenarioStore.addEvent(props.period._id, event, true, props.index + 1);
                                        socketStore.newEvent(
                                            scenarioStore.scenario._id,
                                            props.period._id,
                                            event,
                                            props.index + 1,
                                        );
                                    }}
                                    className={clsx("mt_10 pv_5 flex_center_center", styles.addEventZone)}
                                >
                                    <span className={styles.addEventText}>{t("event.addAnEvent")}</span>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </Draggable>
    );
}

const EventsList = (props: {
    events: TEventMdl[];
    onEdit: (id: string) => void;
    period: TPeriodMdl;
    onAddEvent: (id: string) => void;
}) => {
    return (
        <>
            {props.events.map((event, index) => {
                return (
                    <EventDraggableCard
                        onAddEvent={props.onAddEvent}
                        event={event}
                        period={props.period}
                        key={event.id}
                        index={index}
                        onEdit={props.onEdit}
                    />
                );
            })}
        </>
    );
};

type Props = {
    period: TPeriodMdl;
    position: number;
};

export function ScenarioPeriod({ period, position }: Props) {
    const [eventId, setEventId] = useState<string>();
    const { events, onDragEnd, setSelectedPeriod, unsetSelectedPeriod, addPeriod } = useScenarioPeriod(
        period,
        position,
    );
    const scenarioStore = useScenarioStore();
    const { t } = useTranslation();

    return (
        <>
            {position === 0 && <div className={clsx("pv_20 ml_40", styles.line)} />}
            <div className={"flex_row"}>
                <div className={clsx("flex-1 mr_30 ml_40 position_relative", styles.line)}>
                    <div
                        className={clsx("position_absolute flex_column hidden", styles.addChapterHoverBtn)}
                        onMouseEnter={setSelectedPeriod}
                        onMouseLeave={unsetSelectedPeriod}
                        onClick={addPeriod}
                    ></div>
                    <div className={"flex_row_center position_relative"}>
                        <div className={styles.dot} />
                        <PeriodTitle position={position} isEditable={userStore.isLogged} period={period} />
                    </div>
                    <div className={"flex_row pv_30"}>
                        <div className={"ml_20 flex-1"}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="list">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            <EventsList
                                                onAddEvent={setEventId}
                                                period={period}
                                                events={events}
                                                onEdit={(id) => setEventId(id)}
                                            />
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {!userStore.isAdmin() && (
                                <div
                                    onClick={() => {
                                        const event = defaultEvent();
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                        // @ts-ignore
                                        socketStore.newEvent(scenarioStore.scenario._id, period._id, event);
                                        socketStore.subscribeSocketForNewEvent(event as TEventMdl, scenarioStore);
                                        scenarioStore.addEvent(period._id, event as TEventMdl);
                                        setEventId(event.id);
                                    }}
                                    className={clsx("flex_row_center cursor_pointer", styles.addEventIcon)}
                                >
                                    <img src={addEventIcon} alt={"Add event icon"} />
                                    <div className={clsx(styles.addEvent, "ml_5")}>{t("event.addAnEvent")}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Dialog
                    borderRadius={0}
                    backgroundColor={"white"}
                    padding={0}
                    fullScreen
                    open={!!eventId}
                    disableEscapeKeyDown
                    onClose={() => setEventId(undefined)}
                >
                    {eventId && (
                        <EventPage
                            onClose={() => {
                                setEventId(undefined);
                                sectionStore.setEditingSection(undefined);
                                scenarioStore.setEventId(undefined);
                            }}
                            eventId={eventId}
                            periodId={period._id}
                        />
                    )}
                </Dialog>
            </div>
        </>
    );
}
