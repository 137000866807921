import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
    typography: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            main: "#e66752",
        },
        secondary: {
            main: "#435780",
        },
        error: {
            main: "#FA6F4F",
        },
        background: {
            default: "#fff",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1440,
            xl: 1920,
        },
    },
});
