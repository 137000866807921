import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import styles from "./_css/confirmationDialog.module.css";

type Props = Omit<DialogProps, "children"> & {
    title: string;
    message: string;
    agreeLabel: string;
    onAgree: () => void;
    disagreeLabel: string;
    onDisagree: () => void;
    isDeleteButton?: boolean;
};

export function ConfirmationDialog(props: Props) {
    const { title, message, agreeLabel, onAgree, disagreeLabel, onDisagree, isDeleteButton, ...dialogProps } = props;
    return (
        <Dialog {...dialogProps}>
            <DialogTitle>
                <span className={styles.title}>{title}</span>
            </DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <UiButton onClick={onDisagree}>{disagreeLabel}</UiButton>
                <UiButton onClick={onAgree} variant="contained" color="primary" isDeleteButton={isDeleteButton}>
                    {agreeLabel}
                </UiButton>
            </DialogActions>
        </Dialog>
    );
}
