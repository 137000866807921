import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../_css/yourMemories.module.css";
import circleIcon from "../../../../assets/icons/circleOrange.svg";
import { Container } from "@material-ui/core";
import clsx from "clsx";

export function YourMemories() {
    const { t } = useTranslation();
    return (
        <Container className={styles.container}>
            <div className={styles.title}>{t("homePage.yourMemories.title")}</div>
            <div className={clsx("flex_row mt_80", styles.content)}>
                <h4 className={clsx(styles.subTitle, styles.textFirst)}> {t("homePage.yourMemories.print")}</h4>
                <div className={styles.contentImage}>
                    <img src={circleIcon} alt={"circle icon"} className={styles.image} />
                </div>
                <h4 className={clsx(styles.subTitle, styles.textThrid)}> {t("homePage.textTitle2")} </h4>
                <div className={styles.contentImage}>
                    <img src={circleIcon} alt={"circle icon"} className={styles.image} />
                </div>
                <h4 className={clsx(styles.subTitle, styles.textSecond)}> {t("homePage.yourMemories.collaborate")}</h4>
            </div>
        </Container>
    );
}
